import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box, Card, CardContent, Button, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ToastSnackbar } from '../Components';

const LoginCard = ( {xs, sm, lg, smOffset, lgOffset, hSize, hMargin, purpose, setLogin, apiUrl, isAuthenticated } ) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginErr, setLoginErr] = useState(false);
    const [loginErrMsg, setLoginErrMsg] = useState('');

    const loginUser = async (credentials) => {
        return fetch(`${apiUrl}/Auth/Login`, {
        method: 'POST',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(credentials)
        }).then(data => data.json())
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = await loginUser({"UserName": username, "Password": password});
        if (token.Status === 'Winning') {
            window.location.pathname === '/checkout' ? (
                isAuthenticated()
            ) : (
                window.location.reload(false)
            )
        } else {
            setLoginErr(true);
            token.Message !== 'Login failed.' && setLoginErrMsg(token.Message);
        }
    }

    return (

    <Grid xs={xs} sm={sm} lg={lg} smOffset={smOffset} lgOffset={lgOffset} display={'flex'} flexDirection={'column'} justifyContent={'end'} >
            <Card sx={{ pb: {lg: '16px'} }} >
                <CardContent>
                    <Typography variant={hSize} color={'secondary'} margin={{ xs: `${hMargin[0]}rem 0`, lg: `${hMargin[1]}rem 0` }} >LOGIN</Typography>
                    <Box component={'form'} onSubmit={(e) => handleSubmit(e)} >
                        <Grid container columns={{ xs: 1, lg: 8 }} columnSpacing={{lg: 3}} >
                            <Grid xs={1} lg={3} lgOffset={1} >
                            <TextField variant='filled' label='Username' value={username} onChange={(e) => setUsername(e.target.value)} color='secondary' margin='normal' type='text' sx={{ width: '100%' }} autoFocus={true} required />
                            </Grid>
                            <Grid xs={1} lg={3} >
                            <TextField variant='filled' label='Password' value={password} onChange={(e) => setPassword(e.target.value)} color='secondary' margin='normal' type='password' sx={{ width: '100%' }} required />
                            </Grid>
                            <Grid xs={1} lg={6} lgOffset={1} >
                            <Button variant='contained' disableElevation={true} fullWidth={true} type='submit' sx={{ marginTop: '16px', maxWidth: 'none', mb: { lg: '16px' } }} >Log in</Button>
                            </Grid>
                            <Grid xs={1} lg={3} lgOffset={1} display={'flex'} alignItems={'center'} >
                            <Typography variant='body1' sx={{ margin: {xs: '1rem', lg: 'initial'}, textAlign: {lg: 'left'}, width: '100%' }} >Don't have an account?</Typography>
                            </Grid>
                            <Grid xs={1} lg={3} >
                                { purpose === 'link' ? (
                                    <Button component={Link} to='/signup' variant='outlined' fullWidth={true} sx={{ borderWidth: '2px', maxWidth: 'none' }}>Sign up</Button>
                                ) : (
                                    <Button onClick={() => setLogin(false)} variant='outlined' fullWidth={true} sx={{ borderWidth: '2px', maxWidth: 'none' }}>Sign up</Button>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
            <ToastSnackbar alertSeverity='error' alertTitle='Log In Failed' openSnackbar={loginErr} setOpenSnackbar={setLoginErr} snackbarDescription={`Please confirm you have entered the correct username and password. ${loginErrMsg}`} />
        </Grid>

    )
}

export default LoginCard;