import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Typography, Box, Card, CardContent, Button, TextField, useTheme, ThemeProvider, useMediaQuery, Divider, List, ListItem, ListItemIcon, ListItemText, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { PurchaseServerDialog, ToastSnackbar, PurchasedProductCards, MoneroTransactionDialog, ProLicenseCustomerInfo, PurchaseCompletedDialog } from '../Components';

import DnsTwoToneIcon from '@mui/icons-material/DnsTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import ContactSupportTwoToneIcon from '@mui/icons-material/ContactSupportTwoTone';

const Dashboard = ({ subs, getSubs, card ,apiUrl, provideSubscriptions, provideCardInfo }) => {

    const theme = useTheme();
    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    const [isPurchaseCompleteOpen, setIsPurchaseCompleteOpen] = useState(false);

    const humanReadableStatus = (status) => {
        switch (status) {
            case 'Active':
            case 'New':
            case 'Cancelled':
                return status;
            case 'OnHold':
                return 'On hold';
            case 'PendingCancel':
                return 'Cancel pending';
            default:
                break;
        }
    }

    /* #region CANCEL CONFIRMATION DIALOG STATES AND FUNCTIONS */

    const [open, setOpen] = useState(false);
    const [deleteText, setDeleteText] = useState('');
    const [confirmHelpText, setConfirmHelpText] = useState('');
    const [pendingDeleteId, setPendingDeleteId] = useState(null);
    const [serverCanceled, setServerCanceled] = useState(false);

    const handleClickOpen = (id) => {
        setPendingDeleteId(id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const cancelSubscription = async () => {
        return fetch(`${apiUrl}/Subscriptions/CancelSubscription`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "SubscriptionID": pendingDeleteId })
        }).then(data => data.json())
    }

    const handleCancelation = async () => {
        if (deleteText === 'Cancel') {
            const cancelationSubmitted = await cancelSubscription();
            if (cancelationSubmitted.Status === 'Winning') {
                setOpen(false);
                getSubs();
                setServerCanceled(true);
            }
        } else {
            setConfirmHelpText('You must type "Cancel" ')
        }
    }

    /* #endregion */

    /* PROFESSIONAL LICENSE STATES & FUNCTIONS */

	const [isProCustInfoOpen, setIsProCustInfoOpen] = useState(false);
    const [customerType, setCustomerType] = useState('');
	const [customerTypeErr, setCustomerTypeErr] = useState(false);
	const [proName, setProName] = useState('');
	const [proEmail, setProEmail] = useState('');
	const [proPhone, setProPhone] = useState('');
	const [proCompany, setProCompany] = useState('');
	const [proAddress1, setProAddress1] = useState('');
	const [proAddress2, setProAddress2] = useState('');
	const [proWebsite, setProWebsite] = useState('');
	const [customerInfoErr, setCustomerInfoErr] = useState(false);

    const handleProCustInfoContinue = () => {
        const proCustInfo = {Name: proName, Email: proEmail, Phone: proPhone, CompanyName: proCompany, AddressLine1: proAddress1, AddressLine2: proAddress2, Website: proWebsite};
        const companyValueCheck = Object.values(proCustInfo).find((element, index) => index !== 5 ? element.length < 2 : element.length === 1);
        if ((proCustInfo.Name.length < 2 || proCustInfo.Email.length < 2) || (customerType === 'company' && companyValueCheck !== undefined)) {
            setCustomerInfoErr(true);
        } else {
            setIsProCustInfoOpen(false);
            setOpenDialog(true);
        }
    }

    /* PURCHASE SERVER DIALOG STATES AND FUNCTIONS */

    const [openDialog, setOpenDialog] = useState(false);
    const [totalProductPrice, setTotalProductPrice] = useState(null);
    const [paymentInfoUpdated, setPaymentInfoUpdated] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const { state } = useLocation();

    /* #region WORK ITEMS STATES AND FUNCTIONS */

    const [workItems, setWorkItems] = useState([]);
    const [workItemsErr, setWorkItemsErr] = useState(false);
    const [workItemsErrMsg, setWorkItemsErrMsg] = useState('');

    const getWorkItems = async () => {
        return fetch(`${apiUrl}/Support/ListUsersSupportItems`, {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        }).then(data => data.json())
    }

    const provideWorkItems = async () => {
        const itemsReceived = await getWorkItems();
        if (itemsReceived.Status === 'Winning') {
            setWorkItems(itemsReceived.SupportItemList);
        } else {
            setWorkItems('failed');
            setWorkItemsErr(true);
            setWorkItemsErrMsg(itemsReceived.Message);
        }
    }

    const provideStatus = (status) => {
        let correctedStatus;
        switch (status) {
            case 'Completed':
                correctedStatus = status;
                break;
            case 'InProgress':
                correctedStatus = 'In Progress';
                break;
            case 'PendingStart':
                correctedStatus = 'Pending Start';
                break;
            default:
                correctedStatus = 'Not Applicable';
                break;
        }
        return correctedStatus;
    }

    /* #endregion */

    /* #region MONERO PAYMENT STATES AND FUNCTIONS */

	const [isMoneroDialogOpen, setIsMoneroDialogOpen] = useState(false);
	const [moneroTransProgress, setMoneroTransProgress] = useState(0);
	const [moneroFailStatus, setMoneroFailStatus] = useState(null);
	const [moneroAddress, setMoneroAddress] = useState('')
	const [moneroPicoAmount, setMoneroPicoAmount] = useState('');
	const [moneroQRCode, setMoneroQRCode] = useState(null);
	const [moneroQRCodeUrl, setMoneroQRCodeUrl] = useState('');

	/* CHECK TO SEE IF MONERO IS BEING ACCEPTED */

	const [moneroActive, setMoneroActive] = useState(false);
	const [isMoneroOff, setIsMoneroOff] = useState(false);

	const getMoneroInfo = async () => {
		return fetch(`${apiUrl}/Capitalism/GetXMRInfo`, {
			method: 'GET',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' }
		}).then(data => data.json())
	}

	const isMoneroActive = async () => {
		const moneroInfo = await getMoneroInfo();
		if (moneroInfo.Status === 'Winning') {
			const milDiff = ((Date.now() - (new Date(moneroInfo.XMRInfo.UpdatedOn).getTime())) / 60000);
			if (moneroInfo.XMRInfo.Status === 'OK' && milDiff < 30 ) {
				setMoneroActive(true);
			} else {
				setMoneroActive(false);
			}
		}
	}

	const sendMoneroTransDetails = async () => {
        const payload = selectedProduct[2].Name === 'Professional' ? {...selectedProduct[0], PaymentType: "XMR", CodeSirenPII: {Name: proName, Email: proEmail, Phone: proPhone, CompanyName: proCompany, AddressLine1: proAddress1, AddressLine2: proAddress2, Website: proWebsite}} : {...selectedProduct[0], PaymentType: "XMR"}
		return fetch(`${apiUrl}/Capitalism/AddNewSubscription`, {
			method: 'POST',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(payload)
		}).then(data => data.json())
	}

	const getMoneroTransStatus = async (id) => {
		return fetch(`${apiUrl}/Capitalism/GetXMRTransStatus`, {
			method: 'POST',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(id)
		}).then(data => data.json())
	}

	const handleMoneroTransaction = async () => {
		let transStatusCall;
		let transStatus = 'New';
		const transId = {TransactionID: null};
		/* CHECK TO SEE IF MONERO IS STILL ACCEPTED */
		const moneroInfo = await getMoneroInfo();
		if (moneroInfo.Status === 'Winning') {
			const milDiff = ((Date.now() - (new Date(moneroInfo.XMRInfo.UpdatedOn).getTime())) / 60000);
			if (moneroInfo.XMRInfo.Status !== 'OK' || milDiff >= 30 ) {
				setMoneroActive(false);
				setIsMoneroOff(true);
				return;
			} else {
                setOpenDialog(false);
            }
		}
		/* GET TRANSACTION ID */
		const transDetails = await sendMoneroTransDetails();
		if (transDetails.Status === 'Winning') {
			transId.TransactionID = transDetails.TransactionID;
			transStatusCall = await getMoneroTransStatus(transId);
			if (transStatusCall.Status === 'Winning') {
				transStatus = transStatusCall.TransactionInfo.Status;
			} else {
				return;
			}
			setIsMoneroDialogOpen(true);
			/* GET TRANSACTION STATUS UPDATE UNTIL THE STATUS IS PENDING PAYMENT */
			let paymentStatusIntervalId = setInterval(async () => {
				if (transStatus === 'PendingPayment') {
					setMoneroTransProgress(33);
					setMoneroAddress(transStatusCall.TransactionInfo.XMRAddress);
					setMoneroPicoAmount(transStatusCall.TransactionInfo.XMRPicoAmount);
					setMoneroQRCode(transStatusCall.TransactionInfo.XMRQRCode);
					setMoneroQRCodeUrl(transStatusCall.TransactionInfo.XMRURL);
					clearInterval(paymentStatusIntervalId);
				} else if (transStatus === 'AgedOut') {
					setMoneroTransProgress(0);
					setMoneroFailStatus('AgedOut');
					clearInterval(paymentStatusIntervalId);
				} else if (transStatus === 'Error') {
					setMoneroTransProgress(0);
					setMoneroFailStatus('Error');
					clearInterval(paymentStatusIntervalId);
				} else {
					transStatusCall = await getMoneroTransStatus(transId);
					if (transStatusCall.Status === 'Winning') {
						transStatus = transStatusCall.TransactionInfo.Status;
					} else {
						clearInterval(paymentStatusIntervalId);
					}
				}
			}, 2000)
			/* GET TRANSACTION STATUS UPDATE UNTIL THE STATUS IS PENDING CONFIRMATIONS */
			let confirmationStatusIntervalId = setInterval(async () => {
				if (transStatus === 'PendingConfirmations' || transStatus === 'Paid') {
					setMoneroTransProgress(67);
					clearInterval(confirmationStatusIntervalId);
				} else if (transStatus === 'AgedOut') {
					setMoneroTransProgress(0);
					setMoneroFailStatus('AgedOut');
					clearInterval(confirmationStatusIntervalId);
				} else if (transStatus === 'Error') {
					setMoneroTransProgress(0);
					setMoneroFailStatus('Error');
					clearInterval(confirmationStatusIntervalId);
				} else {
					transStatusCall = await getMoneroTransStatus(transId);
					if (transStatusCall.Status === 'Winning') {
						transStatus = transStatusCall.TransactionInfo.Status;
					} else {
						clearInterval(confirmationStatusIntervalId);
					}
				}
			}, 2000)
			/* GET TRANSACTION STATUS UPDATE UNTIL THE STATUS IS PAID */
			let paidStatusIntervalId = setInterval(async () => {
				if (transStatus === 'Paid') {
					setMoneroTransProgress(100);
					clearInterval(paidStatusIntervalId);
				} else if (transStatus === 'AgedOut') {
					setMoneroTransProgress(0);
					setMoneroFailStatus('AgedOut');
					clearInterval(paidStatusIntervalId);
				} else if (transStatus === 'Error') {
					setMoneroTransProgress(0);
					setMoneroFailStatus('Error');
					clearInterval(paidStatusIntervalId);
				} else {
					transStatusCall = await getMoneroTransStatus(transId);
					if (transStatusCall.Status === 'Winning') {
						transStatus = transStatusCall.TransactionInfo.Status;
					} else {
						clearInterval(paidStatusIntervalId);
					}
				}
			}, 2000)
		}
	}

	/* #endregion */


    useEffect(() => {
        if (state !== null) {
            if (state.newPurchase) {
                provideCardInfo();
                setIsPurchaseCompleteOpen(true);
            } else {
                isMoneroActive();
                state[2].Name === 'Professional' ? setIsProCustInfoOpen(true) : setOpenDialog(true);
                setSelectedProduct(state);
                setTotalProductPrice(
                    ((
                        (state[0].SelectedPrice !== null && state[0].SelectedPrice.Price) + state[0].SelectedLicensePrice.Price + (((state[0].BackupsPowerUp && state[1].BackupPrice) + (state[0].TORPowerUp && state[1].TORPrice) + (state[0].StoragePowerUp !== null && state[0].StoragePowerUp.Price)) * state[0].SelectedPrice.Months)
                    ) / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}));
            }
        };
        provideSubscriptions();
        provideWorkItems();
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ flexGrow: 1, padding: { xs: '0px 16px', md: '0px 8.25%' }, width: '100%' }}>
                <Grid container columns={{ xs: 4, sm: 8, lg: 12 }} alignContent={'flex-start'} sx={{ minHeight: { xs: 'calc(100vh - 56px)', md: 'calc(100vh - 64px)', lg: 'calc(100vh - 112px)' }, marginLeft: '0' }} columnSpacing={{lg: 3}} >
                    <Grid xs={4} sm={6} lg={12} smOffset={1} lgOffset={0} textAlign={'center'} sx={{ height: '100%' }} >
                        <Typography variant='h1' color={'secondary'} margin={{ xs: '1.5rem 0 1rem 0', lg: '2rem 0 3rem 0', height: '100%' }} >DASHBOARD</Typography>
                    </Grid>
                    { subs.length > 0 ? (
                        <Grid xs={4} sm={8} lg={9} sx={{mb: {xs: '16px', lg: '32px'}}} >
                            <Box display={'flex'} justifyContent={{xs: 'center', lg: 'space-between'}} alignItems={'center'} sx={{mb: {xs: '1rem', lg: '1.25rem'}}} >
                                <Typography variant='h4' color={'secondary'} >Purchased Products</Typography>
                                { isDesktop && <Button variant='text' component={Link} to='/products/server-plans' >Purchase new plan</Button> }
                            </Box>
                            {subs === 'unavailable' ? (
                                <Stack flexDirection='column' alignItems='center' justifyContent='center' spacing={{xs: 2, lg: 3}} textAlign='center' height={{lg: '100%'}} >
                                    <ErrorTwoToneIcon color='error' sx={{ fontSize: '10rem', opacity: '.28' }} />
                                    <Typography variant='h5' >Failed to retrieve your subscriptions. Check back soon.</Typography>
                                </Stack>
                            ) : (
                                <PurchasedProductCards subs={subs} handleClickOpen={handleClickOpen} humanReadableStatus={humanReadableStatus} />
                            )}
                            
                        </Grid>
                    ) : (
                        <Grid xs={4} sm={8} lg={9} sx={{mb: {xs: '16px', lg: '32px'}}} >
                            <Grid container columns={4} >
                                <Grid xs={4}>
                                    <Box display={'flex'} justifyContent={{xs: 'center', lg: 'space-between'}} alignItems={'center'} sx={{mb: {xs: '1rem', lg: '1.25rem'}}} >
                                        <Typography variant='h4' color={'secondary'} >Purchased Products</Typography>
                                        { isDesktop && <Button component={Link} to='/products/server-plans' variant='contained' disableElevation={true} sx={{ px: '16px' }} >Purchase new plan</Button> }
                                    </Box>
                                </Grid>
                                <Grid xs={4} lg={2} lgOffset={1} textAlign={'center'} >
                                    <DnsTwoToneIcon color='secondary' sx={{ fontSize: '10rem', opacity: '.28', mt: {lg: '2rem'} }} />
                                </Grid>
                                <Grid xs={4}  textAlign={'center'} >
                                    <Typography variant='body1' >A product has not been purchased yet.</Typography>
                                </Grid>
                                { !isDesktop && (
                                    <Grid xs={4} >
                                        <Button component={Link} to='/products/server-plans' variant='contained' disableElevation={true} fullWidth={true} sx={{ mt: '8px' }} >Purchase new plan</Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    )}

                    { !isDesktop && (
                        <Grid xs={4} sm={4} smOffset={2} >
                            <Button component={Link} to='/products/server-plans' variant='outlined' disableElevation={true} fullWidth={true} sx={{ borderWidth: '2px', maxWidth: 'none', mb: '16px' }} >Purchase new plan</Button>
                        </Grid>
                    )}
                    { !isDesktop && (
                        <Grid xs={4} sm={8} >
                            <Divider sx={{mb: '16px'}} />
                        </Grid>
                    )}

                    <Grid xs={4} lg={3} smOffset={2} lgOffset={0} sx={{mb: {xs: '16px', lg: '32px'}}} >
                        <Card>
                            <CardContent sx={{pb: '16px'}} >
                                <Grid container columns={{ xs: 2 }} alignItems={'center'} >
                                    <Grid xs={1} lg={2} xl={1} textAlign={'left'} >
                                        <Typography variant={'h5'} >Work Items</Typography>
                                    </Grid>
                                    <Grid xs={1} display={{ lg: 'none', xl: 'block' }} textAlign={'right'} >
                                        <Typography variant={'body2'} >Date Created</Typography>
                                    </Grid>
                                    <Grid xs={2} >
                                        <List>
                                            { workItems !== 'failed' ? (
                                                workItems.length > 0 ? (workItems.map((item) => (
                                                    <ListItem sx={{ alignItems: 'baseline' }} key={item.id.id.String} disableGutters >
                                                        <ListItemText primary={item.Title} secondary={provideStatus(item.Status)} />
                                                        <Typography variant='body1' >{item.CreatedOn.substring(5, 7)}-{item.CreatedOn.substring(8, 10)}-{item.CreatedOn.substring(0, 4)}</Typography>
                                                    </ListItem>
                                                ))) : (
                                                    <ListItem sx={{ alignItems: 'baseline' }} disableGutters >
                                                        <ListItemText primary="You don't have any work items." />
                                                    </ListItem>
                                                )
                                            ) : (
                                                <ListItem sx={{p: '8px 24px'}}  >
                                                    <ListItemIcon>
                                                        <ErrorTwoToneIcon color='error' />
                                                    </ListItemIcon>
                                                    <ListItemText primary={'Failed to retrieve work items.'} />
                                                </ListItem>
                                            )}
                                        </List>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Box sx={{textAlign: 'center'}} >
                            <Typography variant='h6' sx={{my: '.8rem'}} >Have questions?</Typography>
                            <Button component={Link} to='/contact-us' variant='outlined' startIcon={<ContactSupportTwoToneIcon />} fullWidth >Contact us</Button>
                        </Box>
                    </Grid>
                </Grid>

                <ProLicenseCustomerInfo displayType='dialog' selectedProduct={state} xs={4} sm={6} smOffset={1} customerType={customerType} setCustomerType={setCustomerType} customerTypeErr={customerTypeErr} setCustomerTypeErr={setCustomerTypeErr} proName={proName} setProName={setProName} proEmail={proEmail} setProEmail={setProEmail} proPhone={proPhone} setProPhone={setProPhone} proCompany={proCompany} setProCompany={setProCompany} proAddress1={proAddress1} setProAddress1={setProAddress1} proAddress2={proAddress2} setProAddress2={setProAddress2} proWebsite={proWebsite} setProWebsite={setProWebsite} open={isProCustInfoOpen} setOpen={setIsProCustInfoOpen} handleContinue={handleProCustInfoContinue} />

                <ToastSnackbar alertSeverity='error' alertTitle='Customer Info Is Required for Pro Licenses' openSnackbar={customerInfoErr} setOpenSnackbar={setCustomerInfoErr} snackbarDescription='Each field requires more than one character.' />

                <PurchaseServerDialog card={card} openDialog={openDialog} setOpenDialog={setOpenDialog} selectedProduct={selectedProduct} getSubs={getSubs} provideWorkItems={provideWorkItems} totalProductPrice={totalProductPrice} apiUrl={apiUrl} moneroActive={moneroActive} handleMoneroTransaction={handleMoneroTransaction} proCustInfo={{Name: proName, Email: proEmail, Phone: proPhone, CompanyName: proCompany, AddressLine1: proAddress1, AddressLine2: proAddress2, Website: proWebsite}} setIsPurchaseCompleteOpen={setIsPurchaseCompleteOpen} />

                <MoneroTransactionDialog isMoneroDialogOpen={isMoneroDialogOpen} setIsMoneroDialogOpen={setIsMoneroDialogOpen} moneroAddress={moneroAddress} moneroPicoAmount={moneroPicoAmount} moneroQRCode={moneroQRCode} moneroQRCodeUrl={moneroQRCodeUrl} moneroTransProgress={moneroTransProgress} moneroFailStatus={moneroFailStatus} setMoneroFailStatus={setMoneroFailStatus} handleMoneroTransaction={handleMoneroTransaction} setIsPurchaseCompleteOpen={setIsPurchaseCompleteOpen} />

                <ToastSnackbar alertSeverity='error' alertTitle='Monero payments are currently unavailable.' openSnackbar={isMoneroOff} setOpenSnackbar={setIsMoneroOff} />

                <PurchaseCompletedDialog isPurchaseCompleteOpen={isPurchaseCompleteOpen} setIsPurchaseCompleteOpen={setIsPurchaseCompleteOpen} />

                <ToastSnackbar alertSeverity='success' alertTitle='Card Successfully Added' openSnackbar={paymentInfoUpdated} setOpenSnackbar={setPaymentInfoUpdated} snackbarButton={false} />

                <Dialog open={open} onClose={handleClose} >
                    <DialogTitle>Confirm Subscription Cancelation</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Enter the word "Cancel" in the following text field and click the Confirm button to cancel your subscription.</DialogContentText>
                        <TextField variant='filled' label='Cancel Confirmation' placeholder='Type "Cancel"' value={deleteText} onChange={(e) => setDeleteText(e.target.value)} error={confirmHelpText !== ''} helperText={confirmHelpText} color='secondary' margin='normal' type='text' sx={{ width: '100%' }} required></TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                        <Button color='error' onClick={handleCancelation} >Confirm</Button>
                    </DialogActions>
                </Dialog>
                <ToastSnackbar alertSeverity='success' alertTitle='Subscription Canceled Successfully' openSnackbar={serverCanceled} setOpenSnackbar={setServerCanceled} snackbarButton={false} />

                <ToastSnackbar alertSeverity='error' alertTitle='Failed to Retrieve Work Items' openSnackbar={workItemsErr} setOpenSnackbar={setWorkItemsErr} snackbarDescription={workItemsErrMsg} />

            </Box>
        </ThemeProvider>


    )
}

export default Dashboard;