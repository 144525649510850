export const themeOptions = {
	components: {
		MuiCard: {
			styleOverrides: {
				root: {
					backgroundColor: 'rgba(224, 224, 224, .05)',
					backgroundImage: 'none',
					boxShadow: 'none',
					textAlign: 'center'
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontSize: '1rem',
					textTransform: 'none',
					height: '48px',
					fontWeight: '500'
				}
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: '.88rem',
					fontWeight: '400',
					lineHeight: '1.25rem'
				}
			}
		}
	},
	palette: {
		mode: 'dark',
		primary: {
			main: '#a2d4fa',
			dark: '#2b587a',
			contrastText: '#000000',
		},
		secondary: {
			main: '#fae696',
			contrastText: '#000000',
			dark: '#7a7149',
		},
		error: {
			main: '#fabdbb',
			dark: '#7a3937',
			contrastText: '#000000',
		},
		success: {
			main: '#b3fa96',
			contrastText: '#000000',
			dark: '#577a49',
		},
		warning: {
			main: '#ffb74d'
		},
		divider: '#faf3c0',
	},
	typography: {
		fontWeightBold: 800,
		fontWeightMedium: 600,
		fontWeightLight: 200,
		h1: {
			fontFamily: "sweet-square-pro, 'Chakra Petch', sans-serif",
			fontSize: '4rem',
			fontWeight: 800,
			color: "#ffffff"
		},
		h2: {
			fontFamily: "sweet-square-pro, 'Chakra Petch', sans-serif",
			fontSize: '3rem',
			fontWeight: 800,
			color: "#ffffff"
		},
		h3: {
			fontFamily: "sweet-square-pro, 'Chakra Petch', sans-serif",
			fontWeight: 800,
			color: "#ffffff"
		},
		h4: {
			fontFamily: "'Roboto', sans-serif",
			fontWeight: 200,
			color: "#ffffff"
		},
		h5: {
			fontFamily: "'Roboto', sans-serif",
			fontWeight: 200,
			color: "#ffffff"
		},
		h6: {
			fontFamily: "'Roboto', sans-serif",
			fontWeight: 200,
			color: "#ffffff"
		},
		body1: {
			letterSpacing: '.05rem',
			fontWeight: 300,
		},
		body2: {
			fontSize: '.8rem',
		},
		subtitle1: {
			fontWeight: 400,
			color: "#ffffff",
			letterSpacing: '.05rem',
		},
		subtitle2: {
			fontFamily: "sweet-square-pro, 'Chakra Petch', sans-serif",
			fontWeight: 400,
			fontSize: '.88rem',
			color: "#ffffff",
			letterSpacing: '.12rem'
		},
		caption: {
			fontSize: '.88rem'
		}
	},
	props: {
		MuiAppBar: {
			color: 'transparent',
		},
		MuiTooltip: {
			arrow: true,
		},
	},
};