import React from 'react';
import { Snackbar, Alert, AlertTitle, Button } from '@mui/material';

const ToastSnackbar = ({ alertSeverity, alertTitle, openSnackbar, setOpenSnackbar, snackbarDescription, snackbarButton, snackbarButtonText, snackbarButtonFunc }) => {

	const handleSnackbarClose = (event, reason) => {
		if(reason === 'clickaway') {
			return;
		}
		setOpenSnackbar(false);
	}		

  return (
    <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleSnackbarClose} >
		{ snackbarButton ? (
			<Alert onClose={handleSnackbarClose} variant='filled' severity={alertSeverity} action={<Button onClick={snackbarButtonFunc} color='inherit' size='small' >{snackbarButtonText}</Button>} sx={alertSeverity === 'error' ? {color: '#fff', alignItems: 'center', fontSize: {xs: '.8rem', sm: '1rem'}}: {alignItems: 'center', fontSize: {xs: '.8rem', sm: '1rem'}}} >
				{alertTitle}
			</Alert>
		) : snackbarDescription ? (
			<Alert onClose={handleSnackbarClose} variant='filled' severity={alertSeverity} sx={alertSeverity === 'error' ? {color: '#fff', fontWeight: 300} : {fontWeight: 300}} >
				<AlertTitle>{alertTitle}</AlertTitle>
				{snackbarDescription}
			</Alert>
		) : (
			<Alert onClose={handleSnackbarClose} variant='filled' severity={alertSeverity} sx={alertSeverity === 'error' && {color: '#fff'}} >
				{alertTitle}
			</Alert>
		)}
    </Snackbar>
  )
}

export default ToastSnackbar