import React from 'react';
import { Box, SvgIcon, Typography, TextField, MenuItem, Tooltip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';

const PowerUpBlock = ({ powerUpIcon, powerUpName, powerUpPrice, powerUpDesc, powerUpValueSelection, powerUpValues, selectedDiskSpace, active, handlePowerupClick, disabled, paymentFreqSelection, powerUpInfo, powerUpInfoText }) => {

	/* #region POWER UPS ICON SVG CODE */

	const powerUpsSVGIcon = <SvgIcon><svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g id="c"><polygon points="10.2 4 7.81 6.39 9.14 6.39 9.14 13.94 8.9 14.19 7.42 14.19 7.42 16 9.96 16 11.25 14.69 11.25 6.39 12.58 6.39 10.2 4" style={{fill: '#a2d4fa', strokeWidth: '0px'}}/><path d="m18,1c.55,0,1,.45,1,1v16c0,.55-.45,1-1,1H2c-.55,0-1-.45-1-1V2c0-.55.45-1,1-1h16m0-1H2C.9,0,0,.9,0,2v16c0,1.1.9,2,2,2h16c1.1,0,2-.9,2-2V2c0-1.1-.9-2-2-2h0Z" style={{fill: '#fae696', strokeWidth: '0px'}}/></g></svg></SvgIcon>;


	/* #endregion */

	return (
		<Box textAlign={'left'} className={!active ? (disabled ? 'power-up-block disabled': 'power-up-block') : 'power-up-block active'} onClick={(e) => !disabled && (!powerUpValueSelection && handlePowerupClick(e, powerUpName))} >
			{powerUpValueSelection ? (
				<Grid container columns={4} rowGap={1} >
					<Grid xs={4} display={'flex'} sx={{alignItems: 'center'}} >
						{powerUpIcon} <Typography variant='subtitle1' sx={{ml: '.5rem'}} >{powerUpName}</Typography>
					</Grid>
					<Grid xs={4} sx={{minHeight: {lg: '72px'}}} >
						<Typography variant='body1' >{powerUpDesc}</Typography>
					</Grid>
					<Grid xs={4} sx={{pb: '4px', display: 'flex', justifyContent: 'space-between', alignItems: 'end'}} >
					<Box sx={{minHeight: '60px', display: 'flex', alignItems: 'end'}} >
							{ !disabled && (
								<Box>
									<Typography variant='h3' display={'inline'} >
										{powerUpPrice}
									</Typography>
									<Typography variant='subtitle1' display={'inline'} >
										{ paymentFreqSelection === 'monthly' ? '/mo' : '/yr' }
									</Typography>
								</Box>
							)}
						</Box>
						<TextField select value={selectedDiskSpace} onChange={(e) => handlePowerupClick(e, powerUpName)} sx={{minWidth: '30%'}} disabled={disabled} >
							<MenuItem key='default' value='Select amount' >
								Select amount
							</MenuItem>
							{ 
								powerUpValues.map((option) => (
									<MenuItem key={option.ExtraStorageGiB} value={option.ExtraStorageGiB} >
										{option.ExtraStorageGiB} GiB
									</MenuItem>
								))
							}
						</TextField>
					</Grid>
				</Grid>
			) : powerUpInfo ? (
				<Grid container columns={4} rowGap={1} >
					<Grid xs={4} display={'flex'} sx={{alignItems: 'center', justifyContent: 'space-between'}} >
						<Box display='flex' >{powerUpIcon} <Typography variant='subtitle1' sx={{ml: '.5rem'}} >{powerUpName}</Typography></Box>
						<Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{powerUpInfoText}</div>} arrow >
							<InfoTwoToneIcon color='secondary' />
						</Tooltip>
					</Grid>
					<Grid xs={4} sx={{minHeight: {lg: '72px'}}} >
						<Typography variant='body1' >{powerUpDesc}</Typography>
					</Grid>
					<Grid xs={4} sx={{pb: '4px', display: 'flex', justifyContent: 'space-between', alignItems: 'end'}} >
						<Box sx={{minHeight: '60px', display: 'flex', alignItems: 'end'}} >
							{ !disabled && (
								<Box>
									<Typography variant='h3' display={'inline'} >
										{powerUpPrice}
									</Typography>
									<Typography variant='subtitle1' display={'inline'} >
										{ paymentFreqSelection === 'monthly' ? '/mo' : '/yr' }
									</Typography>
								</Box>
							)}
						</Box>
						<Typography variant='body1' className='power-up-cta-text' sx={{fontWeight: '500'}} ></Typography>
					</Grid>
				</Grid>
			) : (
				<Grid container columns={4} rowGap={1} >
					<Grid xs={4} display={'flex'} sx={{alignItems: 'center'}} >
						{powerUpIcon} <Typography variant='subtitle1' sx={{ml: '.5rem'}} >{powerUpName}</Typography>
					</Grid>
					<Grid xs={4} sx={{minHeight: {lg: '72px'}}} >
						<Typography variant='body1' >{powerUpDesc}</Typography>
					</Grid>
					<Grid xs={4} sx={{pb: '4px', display: 'flex', justifyContent: 'space-between', alignItems: 'end'}} >
						<Box sx={{minHeight: '60px', display: 'flex', alignItems: 'end'}} >
							{ !disabled && (
								<Box>
									<Typography variant='h3' display={'inline'} >
										{powerUpPrice}
									</Typography>
									<Typography variant='subtitle1' display={'inline'} >
										{ paymentFreqSelection === 'monthly' ? '/mo' : '/yr' }
									</Typography>
								</Box>
							)}
						</Box>
						<Typography variant='body1' className='power-up-cta-text' sx={{fontWeight: '500'}} ></Typography>
					</Grid>
				</Grid>
			)}
		</Box>
  	)
}

export default PowerUpBlock;