import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ListItemIcon, ListItemText, Avatar, useMediaQuery, useTheme, ThemeProvider, Button, Stack, Toolbar, Typography, IconButton, Box, AppBar, MenuItem, Menu, Drawer, List, ListItem, ListItemButton, Collapse } from '@mui/material';
import { ProfileInfoDialog, AddCardDialog, DeleteCardDialog, ToastSnackbar, HouseRulesDialog } from '../Components';
import ZEHorizWM from '../assets/zero-eyes-horizontal-wordmark.png';

/* ICON IMPORTS */

import MenuIcon from '@mui/icons-material/MenuTwoTone';
import AccountCircle from '@mui/icons-material/AccountCircleTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import AccountBalanceWalletTwoToneIcon from '@mui/icons-material/AccountBalanceWalletTwoTone';
import Dashboard from '@mui/icons-material/DashboardTwoTone';
import Logout from '@mui/icons-material/LogoutTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import ArrowDropDownTwoToneIcon from '@mui/icons-material/ArrowDropDownTwoTone';
import ArrowDropUpTwoToneIcon from '@mui/icons-material/ArrowDropUpTwoTone';
import DnsTwoToneIcon from '@mui/icons-material/DnsTwoTone';
import LicenseTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';

export default function Navbar({ auth, getAuth, logoutUser, provideCardInfo, card, setCardErr, houseRulesList, setRulesErr, checked, setChecked, agreedToHouseRules, squareEnv, squareAppId, squareLocationId, apiUrl }) {

	const location = useLocation();
	const theme = useTheme();
	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));

	/* APPBAR STATE MNGMT & FUNCTIONS */

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	/* APPBAR AVATAR FUNCTIONS */

	function stringToColor(string) {
		let hash = 0;
		let i;

		/* eslint-disable no-bitwise */
		for (i = 0; i < string.length; i += 1) {
			hash = string.charCodeAt(i) + ((hash << 5) - hash);
		}

		let color = '#';

		for (i = 0; i < 3; i += 1) {
			const value = (hash >> (i * 8)) & 0xff;
			color += `00${value.toString(16)}`.slice(-2);
		}
		/* eslint-enable no-bitwise */

		return color;
	}

	function stringAvatar(name) {
		return {
			sx: {
				bgcolor: stringToColor(name),
			},
			children: `${name[0].toUpperCase()}`,
		};
	}

	/* NAVIGATION DRAWER STATE MNGMT & FUNCTIONS */

	const [state, setState] = React.useState({
		left: false
	});

	const toggleDrawer = (anchor, open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};

	/* DIALOG OPEN/CLOSE STATES AND FUNCTIONS */

	const [openEditProfile, setOpenEditProfile] = useState(false);
	const [profileInfoUpdated, setProfileInfoUpdated] = useState(false);
	const [openPaymentInfo, setOpenPaymentInfo] = useState(false);
	const [paymentInfoUpdated, setPaymentInfoUpdated] = useState(false);
	const [openDeleteCard, setOpenDeleteCard] = useState(false);
	const [cardDeleted, setCardDeleted] = useState(false);

	const handleDialogOpen = (use) => {
		handleClose();
		if (use === 'profile') {
			setOpenEditProfile(true);
		} else if (card !== null && card !== 'failed') {
			setOpenDeleteCard(true);
		} else if (card === 'failed') {
			setCardErr(true);
		} else {
			setOpenPaymentInfo(true);
		}
	};

	const handleDialogClose = (use) => {
		use === 'profile' && setOpenEditProfile(false);
		use === 'delete' && setOpenDeleteCard(false);
		use === 'payment' && setOpenPaymentInfo(false);
	};

	/* HOUSE RULES DIALOG STATES AND FUNCTIONS */

	const [openHouseRules, setOpenHouseRules] = useState(false);
	const [buttonDisable, setButtonDisable] = useState(true);
	const [agreed, setAgreed] = useState(false);

	const handleOpenHouseRules = () => {
		if (houseRulesList !== 'failed') {
			handleDialogClose('payment');
			setOpenHouseRules(true);
		} else {
			setRulesErr(true);
		}
		
	}

	const handleHouseRulesClose = () => {
		setOpenHouseRules(false);
		setButtonDisable(true);
		const tempChecked = {};
		houseRulesList.map((rule, index) => {
			tempChecked[index] = false;
		})
		tempChecked[houseRulesList.length] = false;
		setChecked(tempChecked);
		handleDialogOpen();
	}
	
	/* PRODCTS NAV MENU STATES & FUNCTIONS */

	const [productsAnchor, setProductsAnchor] = useState(null);
	const isProductsOpen = Boolean(productsAnchor);
	const [isAppDrawerProductsOpen, setIsAppDrawerProductsOpen] = useState(false);

	return (
		<ThemeProvider theme={theme}>
			{
				isDesktop ? (
					<Box sx={{ flexGrow: 1 }}>
						<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ flexGrow: 1, height: '112px', p: '0px 8.25%' }}>
							<Button variant='text' endIcon={isProductsOpen ? <ArrowDropUpTwoToneIcon /> : <ArrowDropDownTwoToneIcon />} onClick={(e) => setProductsAnchor(e.currentTarget)} sx={{ color: 'white', padding: '6px 12px', width: '12%' }} aria-controls={isProductsOpen ? 'demo-customized-menu' : undefined} aria-haspopup="true" aria-expanded={isProductsOpen ? 'true' : undefined} >Products</Button>
							<Menu anchorEl={productsAnchor} open={isProductsOpen} onClose={() => setProductsAnchor(null)} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} transformOrigin={{vertical: 'top', horizontal: 'right',}} sx={{minWidth: '12%'}} className='nav-item-products' >
								<MenuItem component={Link} to='/products/server-plans' onClick={() => setProductsAnchor(null)} >
									<ListItemIcon>
										<DnsTwoToneIcon fontSize='small' color='secondary' />
									</ListItemIcon>
									<ListItemText>Server plans</ListItemText>
								</MenuItem>
								<MenuItem component={Link} to='/products/licenses' onClick={() => setProductsAnchor(null)} disabled >
									<ListItemIcon>
										<LicenseTwoToneIcon fontSize='small' color='secondary' />
									</ListItemIcon>
									<ListItemText secondary='(Coming Soon)' >Licenses</ListItemText>
								</MenuItem>
							</Menu>

							<Button component={Link} to='/house-rules' variant='text' sx={{ color: 'white', padding: '6px 12px', width: '12%' }}>House rules</Button>
							<Box sx={{ height: '56px', padding: 0 }}>
								<Link to='/'>
									<img src={ZEHorizWM} alt={'Zero Eyes logo'} style={{ height: '100%', display: 'block', margin: 'auto' }} />
								</Link>
							</Box>
							<Button component={Link} to='/about-us' variant='text' sx={{ color: 'white', padding: '6px 12px', width: '12%' }}>About us</Button>
							{auth ? (
								<div style={{ display: 'flex', justifyContent: 'end', width: '12%' }}>
									<IconButton
										size="large"
										aria-label="account of current user"
										aria-controls="menu-appbar"
										aria-haspopup="true"
										onClick={handleMenu}
										sx={{ p: 0 }}
									>
										<Avatar {...stringAvatar(localStorage.getItem('username'))} />
									</IconButton>
									<Menu
										id="menu-appbar"
										anchorEl={anchorEl}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'right',
										}}
										keepMounted
										transformOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										open={Boolean(anchorEl)}
										onClose={handleClose}
									>
										<MenuItem onClick={handleClose} sx={{ opacity: '1 !important', pb: '12px' }} divider disabled >
											<ListItemIcon>
												<PersonOutlineTwoToneIcon fontSize="small" />
											</ListItemIcon>
											<ListItemText>{localStorage.getItem('username')}</ListItemText>
										</MenuItem>
										<MenuItem component={Link} to='/dashboard' onClick={handleClose} sx={{ mt: '8px' }} >
											<ListItemIcon>
												<Dashboard fontSize="small" color={'secondary'} />
											</ListItemIcon>
											<ListItemText>Dashboard</ListItemText>
										</MenuItem>
										<MenuItem onClick={() => handleDialogOpen('profile')}>
											<ListItemIcon>
												<EditTwoToneIcon fontSize="small" color={'secondary'} />
											</ListItemIcon>
											<ListItemText>Edit profile</ListItemText>
										</MenuItem>
										{location.pathname !== '/checkout' && (
											<MenuItem onClick={handleDialogOpen}>
												<ListItemIcon>
													<AccountBalanceWalletTwoToneIcon fontSize="small" color={'secondary'} />
												</ListItemIcon>
												<ListItemText>Update card on file</ListItemText>
											</MenuItem>
										)}
										<MenuItem onClick={() => logoutUser()}>
											<ListItemIcon >
												<Logout fontSize="small" color={'secondary'} />
											</ListItemIcon>
											<ListItemText>Log out</ListItemText>
										</MenuItem>
									</Menu>

									<ProfileInfoDialog openEditProfile={openEditProfile} handleDialogClose={handleDialogClose} getAuth={getAuth} setProfileInfoUpdated={setProfileInfoUpdated}  apiUrl={apiUrl} />
									<ToastSnackbar alertSeverity='success' alertTitle='Profile Information Updated Successfully' openSnackbar={profileInfoUpdated} setOpenSnackbar={setProfileInfoUpdated} snackbarButton={false} />

									<AddCardDialog openPaymentInfo={openPaymentInfo} handleDialogOpen={handleDialogOpen} handleDialogClose={handleDialogClose} setPaymentInfoUpdated={setPaymentInfoUpdated} provideCardInfo={provideCardInfo} isAuthenticated={getAuth} handleOpenHouseRules={handleOpenHouseRules} agreedToHouseRules={agreedToHouseRules} cardDialogText={''} squareEnv={squareEnv} squareAppId={squareAppId} squareLocationId={squareLocationId} apiUrl={apiUrl} />

									<ToastSnackbar alertSeverity='success' alertTitle='Card Successfully Added' openSnackbar={paymentInfoUpdated} setOpenSnackbar={setPaymentInfoUpdated} snackbarButton={false} />

									<HouseRulesDialog open={openHouseRules} handleHouseRulesClose={handleHouseRulesClose} buttonDisable={buttonDisable} setButtonDisable={setButtonDisable} checked={checked} setChecked={setChecked} isAuthenticated={getAuth} from='nav' handleDialogOpen={handleDialogOpen} setAgreed={setAgreed} houseRulesList={houseRulesList} apiUrl={apiUrl} />

									<ToastSnackbar alertSeverity='success' alertTitle='House Rules Agreed To' openSnackbar={agreed} setOpenSnackbar={setAgreed} snackbarButton={false} />

									<DeleteCardDialog openDeleteCard={openDeleteCard} handleDialogClose={handleDialogClose} setCardDeleted={setCardDeleted} provideCardInfo={provideCardInfo} setOpenPaymentInfo={setOpenPaymentInfo} card={card} apiUrl={apiUrl} />
									<ToastSnackbar alertSeverity='success' alertTitle='Card Successfully Deleted' openSnackbar={cardDeleted} setOpenSnackbar={setCardDeleted} snackbarButton={false} />

								</div>
							) : (
								<Button component={Link} to='/login' variant='text' startIcon={<AccountCircle />} sx={{ width: '12%', padding: '6px 12px' }} >Log in</Button>
							)}

						</Stack>
					</Box>

				) : (
					<Box sx={{ flexGrow: 1 }}>
						<AppBar position="static" color={'transparent'} elevation={0} >
							<Toolbar>
								<IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={toggleDrawer('left', true)} >
									<MenuIcon color={'primary'} />
								</IconButton>

								<Box component={Link} to='/' sx={{ flexGrow: 1, textAlign: 'center' }}>
									<Typography variant="h3" component="div" color={'secondary'} sx={{ letterSpacing: '4px', fontSize: '1.75rem' }}>ZERO EYES</Typography>
								</Box>


								{auth ? (
									<div>
										<IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} sx={{ p: 0 }} >
											<Avatar size='small' {...stringAvatar(localStorage.getItem('username'))} />
										</IconButton>
										<Menu id="menu-appbar" anchorEl={anchorEl}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'right',
											}}
											keepMounted
											transformOrigin={{
												vertical: 'top',
												horizontal: 'right',
											}}
											open={Boolean(anchorEl)} onClose={handleClose} >
											<MenuItem onClick={handleClose} sx={{ opacity: '1 !important', pb: '12px' }} divider disabled >
												<ListItemIcon>
													<PersonOutlineTwoToneIcon fontSize="small" />
												</ListItemIcon>
												<ListItemText>{localStorage.getItem('username')}</ListItemText>
											</MenuItem>
											<MenuItem component={Link} to='/dashboard' onClick={handleClose} sx={{ mt: '8px' }} >
												<ListItemIcon>
													<Dashboard fontSize="small" color={'secondary'} />
												</ListItemIcon>
												<ListItemText>Dashboard</ListItemText>
											</MenuItem>
											<MenuItem onClick={() => handleDialogOpen('profile')}>
												<ListItemIcon>
													<EditTwoToneIcon fontSize="small" color={'secondary'} />
												</ListItemIcon>
												<ListItemText>Edit profile</ListItemText>
											</MenuItem>
											{location.pathname !== '/checkout' && (
												<MenuItem onClick={handleDialogOpen}>
													<ListItemIcon>
														<AccountBalanceWalletTwoToneIcon fontSize="small" color={'secondary'} />
													</ListItemIcon>
													<ListItemText>Update card on file</ListItemText>
												</MenuItem>
											)}
											<MenuItem onClick={() => logoutUser()}>
												<ListItemIcon>
													<Logout fontSize="small" color={'secondary'} />
												</ListItemIcon>
												<ListItemText>Log out</ListItemText>
											</MenuItem>
										</Menu>

										<ProfileInfoDialog openEditProfile={openEditProfile} handleDialogClose={handleDialogClose} getAuth={getAuth} setProfileInfoUpdated={setProfileInfoUpdated}  apiUrl={apiUrl} />
										<ToastSnackbar alertSeverity='success' alertTitle='Profile Information Updated Successfully' openSnackbar={profileInfoUpdated} setOpenSnackbar={setProfileInfoUpdated} snackbarButton={false} />

										<AddCardDialog openPaymentInfo={openPaymentInfo} handleDialogOpen={handleDialogOpen} handleDialogClose={handleDialogClose} setPaymentInfoUpdated={setPaymentInfoUpdated} provideCardInfo={provideCardInfo} isAuthenticated={getAuth} handleOpenHouseRules={handleOpenHouseRules} agreedToHouseRules={agreedToHouseRules} squareEnv={squareEnv} squareAppId={squareAppId} squareLocationId={squareLocationId} apiUrl={apiUrl} />

										<ToastSnackbar alertSeverity='success' alertTitle='Card Successfully Added' openSnackbar={paymentInfoUpdated} setOpenSnackbar={setPaymentInfoUpdated} snackbarButton={false} />

										<HouseRulesDialog open={openHouseRules} handleHouseRulesClose={handleHouseRulesClose} buttonDisable={buttonDisable} setButtonDisable={setButtonDisable} checked={checked} setChecked={setChecked} isAuthenticated={getAuth} from='nav' handleDialogOpen={handleDialogOpen} setAgreed={setAgreed} houseRulesList={houseRulesList} apiUrl={apiUrl} />

										<ToastSnackbar alertSeverity='success' alertTitle='House Rules Agreed To' openSnackbar={agreed} setOpenSnackbar={setAgreed} snackbarButton={false} />

										<DeleteCardDialog openDeleteCard={openDeleteCard} handleDialogClose={handleDialogClose} setCardDeleted={setCardDeleted} provideCardInfo={provideCardInfo} setOpenPaymentInfo={setOpenPaymentInfo} card={card} apiUrl={apiUrl} />

										<ToastSnackbar alertSeverity='success' alertTitle='Card Successfully Deleted' openSnackbar={cardDeleted} setOpenSnackbar={setCardDeleted} snackbarButton={false} />

									</div>
								) :
									(
										<Link to='/login'>
											<IconButton
												size="large"
												aria-label="account of current user"
												aria-controls="menu-appbar"
												aria-haspopup="true"
												onClick={handleMenu}
												color="inherit"
											>
												<AccountCircle color={'primary'} />
											</IconButton>
										</Link>

									)
								}
							</Toolbar>
						</AppBar>

						{/* App Drawer Navigation */}

						<Drawer anchor={'left'} open={state['left']} onClose={toggleDrawer('left', false)} >
							<Box sx={{ width: 250, height: '100vh' }} role="presentation"  onKeyDown={toggleDrawer('left', false)} >
								<List>
									<ListItem disablePadding sx={{ color: 'white' }} >
										<ListItemButton onClick={() => setIsAppDrawerProductsOpen(!isAppDrawerProductsOpen	)} alignItems='center' >
											<ListItemText>Products</ListItemText>
											{isAppDrawerProductsOpen ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />}
										</ListItemButton>
									</ListItem>
									<Collapse in={isAppDrawerProductsOpen} timeout="auto" unmountOnExit>
										<List component="div" disablePadding>
											<ListItemButton component={Link} to='/products/server-plans' onClick={toggleDrawer('left', false)} sx={{ pl: 4 }}>
												<ListItemIcon>
													<DnsTwoToneIcon color='secondary' />
												</ListItemIcon>
												<ListItemText primary="Server plans" />
											</ListItemButton>
											<ListItemButton component={Link} to='/products/licenses' onClick={toggleDrawer('left', false)} disabled sx={{ pl: 4 }}>
												<ListItemIcon>
													<LicenseTwoToneIcon color='secondary' />
												</ListItemIcon>
												<ListItemText primary="Licenses" secondary='(Coming Soon)' />
											</ListItemButton>
										</List>
									</Collapse>
									<ListItem component={Link} to='/house-rules' onClick={toggleDrawer('left', false)} disablePadding sx={{ color: 'white' }} >
										<ListItemButton>
											<ListItemText>House rules</ListItemText>
										</ListItemButton>
									</ListItem>
									<ListItem component={Link} to='/about-us' onClick={toggleDrawer('left', false)} disablePadding sx={{ color: 'white' }} >
										<ListItemButton>
											<ListItemText>About us</ListItemText>
										</ListItemButton>
									</ListItem>
									<ListItem component={Link} to='/contact-us' onClick={toggleDrawer('left', false)} disablePadding sx={{ color: 'white' }} >
										<ListItemButton>
											<ListItemText>Contact us</ListItemText>
										</ListItemButton>
									</ListItem>
								</List>
							</Box>
						</Drawer>

					</Box>
				)
			}
		</ThemeProvider>
	);
}