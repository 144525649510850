import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, MenuItem, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import { countryCodes } from '../utils/constants';
import { ToastSnackbar } from '../Components';

const AddCardDialog = ({ openPaymentInfo, handleDialogClose, setPaymentInfoUpdated, provideCardInfo, cardDialogText, handleOpenHouseRules, agreedToHouseRules, squareEnv, squareAppId, squareLocationId, apiUrl }) => {

    const [nameOnCard, setNameOnCard] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [cardCity, setCardCity] = useState('');
    const [cardState, setCardState] = useState('');
    const [cardCountry, setCardCountry] = useState('US');
    const [cardPostalCode, setCardPostalCode] = useState('');
    const [transactionErr, setTransactionErr] = useState(false);
	const [transErrMsg, setTransErrMsg] = useState('');

    return (

        <Dialog open={openPaymentInfo} onClose={() => handleDialogClose('payment')} >
            <DialogTitle>Add New Card on File</DialogTitle>
            <DialogContent>
                <DialogContentText>{cardDialogText}</DialogContentText>
                <Grid container columns={2} columnSpacing={{ lg: 3 }} >
                    <Grid xs={2} lg={1}>
                        <TextField variant='filled' label='Name on card' value={nameOnCard} onChange={(e) => setNameOnCard(e.target.value)} color='secondary' margin='normal' type='text' sx={{ width: '100%' }} required></TextField>
                    </Grid>
                    <Grid xs={2} lg={1}>
                        <TextField variant='filled' label='Address line 1' value={address1} onChange={(e) => setAddress1(e.target.value)} color='secondary' margin='normal' type='text' sx={{ width: '100%' }} required></TextField>
                    </Grid>
                    <Grid xs={2} lg={1}>
                        <TextField variant='filled' label='Address line 2 (optional)' value={address2} onChange={(e) => setAddress2(e.target.value)} color='secondary' margin='normal' type='text' sx={{ width: '100%' }} required></TextField>
                    </Grid>
                    <Grid xs={1} sx={{ pr: { xs: '8px', lg: '12px' } }} >
                        <TextField variant='filled' label='City' value={cardCity} onChange={(e) => setCardCity(e.target.value)} color='secondary' margin='normal' type='text' sx={{ width: '100%' }} required></TextField>
                    </Grid>
                    <Grid xs={1} sx={{ pl: { xs: '8px', lg: '12px' } }} >
                        <TextField variant='filled' label='State' value={cardState} onChange={(e) => setCardState(e.target.value)} color='secondary' margin='normal' type='text' sx={{ width: '100%' }} required></TextField>
                    </Grid>
                    <Grid xs={1} sx={{ pr: { xs: '8px', lg: '12px' } }} >
                        <TextField variant='filled' label='Postal Code' value={cardPostalCode} onChange={(e) => setCardPostalCode(e.target.value)} color='secondary' margin='normal' type='text' sx={{ width: '100%' }} required></TextField>
                    </Grid>
                    <Grid xs={1} sx={{ pl: { xs: '8px', lg: '12px' }, mb: '1rem' }} >
                        <TextField variant='filled' label='Country' value={cardCountry} onChange={(e) => setCardCountry(e.target.value)} color='secondary' margin='normal' type='text' sx={{ width: '100%' }} select required>
                            {countryCodes.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    { squareEnv === 'dev' && (
                        <Grid xs={2} sx={{textAlign: 'center', mb: '1rem'}} >
                            <Typography variant='h4' color='error' >SQUARE IS IN DEV MODE</Typography>
                        </Grid>
                    )}
                    <Grid xs={2} >
                        {agreedToHouseRules === true ? (
                            <PaymentForm
                                applicationId={squareAppId}
                                cardTokenizeResponseReceived={async (token, verifiedBuyer) => {
                                    if (nameOnCard.length > 1 && address1.length > 1 && (address2.length > 1 || address2 === '') && cardCity.length > 1 && cardState.length > 1 && cardCountry.length > 1 && cardPostalCode.length > 1) {
                                        const response = await fetch(`${apiUrl}/Capitalism/UpdateSquareCardInfo`, {
                                            method: 'POST',
                                            credentials: 'include',
                                            headers: { 'Content-Type': 'application/json' },
                                            body: JSON.stringify({
                                                "CardToken": token.token,
                                                "CardName": nameOnCard,
                                                "CardAddress1": address1,
                                                "CardAddress2": address2,
                                                "CardCity": cardCity,
                                                "CardState": cardState,
                                                "CardCountry": cardCountry,
                                                "CardPostalCode": cardPostalCode
                                            })
                                        }).then(data => data.json());
                                        if (response.Status === 'Winning') {
                                            handleDialogClose('payment')
                                            setPaymentInfoUpdated(true);
                                            provideCardInfo();
                                            setNameOnCard('');
                                            setAddress1('');
                                            setAddress2('');
                                            setCardCity('');
                                            setCardState('');
                                            setCardCountry('US');
                                            setCardPostalCode('');
                                        } else {
                                            setTransactionErr(true);
                                            setTransErrMsg(response.Message);
                                        }
                                    }
                                }}
                                locationId={squareLocationId}
                            >
                                <CreditCard
                                buttonProps={{ css: { 
                                    backgroundColor: '#a2d4fa',
                                    color: '#000000',
                                    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                                    fontWeight: '500',
                                    borderRadius: '4px',
                                    "&:hover": {
                                        backgroundColor: '#2b587a',
                                    },
                                    "&:active": {
                                        backgroundColor: '#a2d4fa',
                                    }
                                } }}
                                style={{
                                    '.input-container': {
                                        borderRadius: '4px',
                                        borderWidth: '1px'
                                    },
                                    '.input-container.is-error': {
                                        borderColor: '#FABDBB'
                                    },
                                    '.input-container.is-focus': {
                                        borderColor: '#FAE696'
                                    },
                                    '.message-icon': {
                                        color: 'white'
                                    },
                                    '.message-icon.is-error': {
                                        color: '#FABDBB'
                                    },
                                    '.message-text': {
                                        color: 'white'
                                    },
                                    '.message-text.is-error': {
                                        color: '#FABDBB'
                                    },
                                    input: {
                                        backgroundColor: 'rgba(255, 255, 255, 0.09)',
                                        color: 'white',
                                        fontFamily: 'Helvetica Neue'
                                    },
                                    'input::placeholder': {
                                        color: 'rgba(255, 255, 255, .7)'
                                    },
                                    'input.is-error': {
                                        color: '#FABDBB'
                                    },
                                    'input.is-error::placeholder': {
                                        color: 'rgba(255, 255, 255, .7)'
                                    }
                                }} focus='' >Add card</CreditCard>
                            </PaymentForm>
                        ) : (
                            <Grid container columns={2}>
                                <Grid xs={2} >
                                    <Typography variant='subtitle1'>House Rules</Typography>
                                </Grid>
                                <Grid xs={2} >
                                    <Typography variant='body1'>Before you add you card information and complete your purchase, you must agree to the house rules.</Typography>
                                </Grid>
                                <Grid xs={2} pt='.5rem' >
                                    <Button variant='contained' color='primary' fullWidth={true} disableElevation={true} onClick={handleOpenHouseRules} >View house rules</Button>
                            </Grid>
                        </Grid>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <ToastSnackbar alertSeverity='error' alertTitle='Card Update Failed' openSnackbar={transactionErr} setOpenSnackbar={setTransactionErr} snackbarDescription={transErrMsg} />
        </Dialog>

    )
}

export default AddCardDialog