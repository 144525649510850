import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardActions, Typography, Button, Stack, List, ListItem, ListItemIcon, ListItemText, Box } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2';
import StorageTwoToneIcon from '@mui/icons-material/StorageTwoTone';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import RecordVoiceOverTwoToneIcon from '@mui/icons-material/RecordVoiceOverTwoTone';
import SpeedTwoToneIcon from '@mui/icons-material/SpeedTwoTone';

const PlanCards = ({ plans, isPlanActive, paymentFreqSelection, handlePlanCardClick }) => {

    const navigate = useNavigate();

    return (

        <Grid container columns={{xs: 4, sm: 8, lg: 12}} spacing={{xs: 2, lg: 3}} justifyContent={{sm: 'center'}} >
            {plans.map((plan, index) => (
                <Grid xs={4} lg={3} key={plan.id.id.String}  >
                    <Card className={isPlanActive[index] ? 'plan-card active' : 'plan-card'} onClick={() => handlePlanCardClick(plan, index)} >
                        <CardContent sx={{p: '32px 32px 16px 32px'}} >
                            <Grid container columns={{ xs: 2, lg: 8 }} rowSpacing={2} >
                                <Grid xs={2} lg={8} textAlign='left' sx={{height: '8.25rem'}} >
                                    <Typography variant='h4' sx={{mb: '.5rem'}} >{plan.Name}</Typography>
                                    <Typography variant='subtitle1' >{plan.Description}</Typography>
                                </Grid>
                                <Grid xs={2} lg={8}>
                                    <List disablePadding >
                                        <Grid container columns={{ xs: 2, lg: 8 }} columnSpacing={{xs: 2, lg: 3}} rowSpacing={2} >
                                            <Grid xs={2} lg={8}>
                                                <ListItem disablePadding >
                                                    <ListItemIcon>
                                                        <GroupTwoToneIcon color={'secondary'} />
                                                    </ListItemIcon>
                                                    <ListItemText disableTypography={true}>
                                                        <Typography variant='body1' >{plan.EstUsers}</Typography>
                                                        <Typography variant='body1' sx={{opacity: '.7'}}>Community size</Typography>
                                                    </ListItemText>
                                                </ListItem>
                                            </Grid>
                                            <Grid xs={2} lg={8}>
                                                <ListItem disablePadding >
                                                    <ListItemIcon>
                                                        <RecordVoiceOverTwoToneIcon color={'secondary'} />
                                                    </ListItemIcon>
                                                    <ListItemText disableTypography={true}>
                                                        <Typography variant='body1' >{plan.EstVoiceUsers}</Typography>
                                                        <Typography variant='body1' sx={{opacity: '.7'}}>Users in voice chat</Typography>
                                                    </ListItemText>
                                                </ListItem>
                                            </Grid>
                                            <Grid xs={2} lg={8}>
                                                <ListItem disablePadding >
                                                    <ListItemIcon>
                                                        <SpeedTwoToneIcon color={'secondary'} />
                                                    </ListItemIcon>
                                                    <ListItemText disableTypography={true}>
                                                        <Typography variant='body1' >{plan.TransferLimitGiB} GiB per month</Typography>
                                                        <Typography variant='body1' sx={{opacity: '.7'}}>Data transfer limit</Typography>
                                                    </ListItemText>
                                                </ListItem>
                                            </Grid>
                                            <Grid xs={2} lg={8} sx={{mb: '.5rem'}} >
                                                <ListItem disablePadding >
                                                    <ListItemIcon>
                                                        <StorageTwoToneIcon color={'secondary'} />
                                                    </ListItemIcon>
                                                    <ListItemText disableTypography={true}>
                                                        <Typography variant='body1' >{plan.BaseDiskSpaceGiB} GiB</Typography>
                                                        <Typography variant='body1' sx={{opacity: '.7'}}>Server disk space</Typography>
                                                    </ListItemText>
                                                </ListItem>
                                            </Grid>
                                            
                                                { paymentFreqSelection === 'monthly' ? (
                                                    <Grid xs={2} lg={8} textAlign={'left'} >
                                                        <Box sx={{ fontFamily: "'sweet-square-pro', 'Chakra Petch', sans-serif", fontSize: '3rem', color: '#FAE696', display: 'inline-block', lineHeight: '48px', mb: '.25rem'}}>
                                                            {(plan.PriceOptions.filter(obj => obj.Name === 'Monthly')[0].Price / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                                                        </Box>
                                                        <Typography variant='subtitle1' >
                                                            per month
                                                        </Typography>
                                                    </Grid>
                                                ) : (
                                                    <Grid xs={2} lg={8} textAlign={'left'} >
                                                        <Box sx={{ fontFamily: "'sweet-square-pro', 'Chakra Petch', sans-serif", fontSize: '3rem', color: '#FAE696', display: 'block', lineHeight: '48px', mb: '.25rem'}}>
                                                            {(plan.PriceOptions.filter(obj => obj.Name === 'Annual')[0].Price / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                                                        </Box>
                                                        <Typography variant='subtitle1' >
                                                            per year
                                                        </Typography>
                                                    </Grid>
                                                )}
                                                
                                        </Grid>
                                    </List>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions sx={{display: 'block', p: '8px 32px 32px 32px'}} >
                            <Grid container columns={{ xs: 1, lg: 8 }} columnSpacing={{lg: 3}} >
                                <Grid xs={1} lg={8} >
                                        <Button className='plan-card-cta' variant='outlined' color={isPlanActive[index] ? 'secondary' : 'primary'} fullWidth={true} sx={{ borderWidth: '2px', maxWidth: 'none' }}>{plan.Name}</Button>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
            { plans.length > 0 && (
                <Grid xs={4} lg={3} >
                    <Card className='plan-card' onClick={() => navigate('/contact-us')} >
                        <CardContent sx={{p: '32px 32px 16px 32px'}} >
                            <Grid container columns={{ xs: 2, lg: 8 }} rowSpacing={2} >
                                <Grid xs={2} lg={8} textAlign='left' sx={{height: '8.25rem'}} >
                                    <Typography variant='h4' sx={{mb: '.5rem'}} >Enterprise</Typography>
                                    <Typography variant='subtitle1'>A custom plan that fits your company's needs.</Typography>
                                </Grid>
                                <Grid xs={2} lg={8}>
                                    <List disablePadding >
                                        <Grid container columns={{ xs: 2, lg: 8 }} columnSpacing={{xs: 2, lg: 3}} rowSpacing={2} >
                                            <Grid xs={2} lg={8}>
                                                <ListItem disablePadding >
                                                    <ListItemIcon>
                                                        <GroupTwoToneIcon color={'secondary'} />
                                                    </ListItemIcon>
                                                    <ListItemText disableTypography={true}>
                                                        <Typography variant='body1' >More than {plans[plans.length - 1].EstUsers.split(' ').pop()}</Typography>
                                                        <Typography variant='body1' sx={{opacity: '.7'}}>Community size</Typography>
                                                    </ListItemText>
                                                </ListItem>
                                            </Grid>
                                            <Grid xs={2} lg={8}>
                                                <ListItem disablePadding >
                                                    <ListItemIcon>
                                                        <RecordVoiceOverTwoToneIcon color={'secondary'} />
                                                    </ListItemIcon>
                                                    <ListItemText disableTypography={true}>
                                                        <Typography variant='body1' >More than {plans[plans.length - 1].EstVoiceUsers.split(' ').pop()}</Typography>
                                                        <Typography variant='body1' sx={{opacity: '.7'}}>Users in voice chat</Typography>
                                                    </ListItemText>
                                                </ListItem>
                                            </Grid>
                                            <Grid xs={2} lg={8}>
                                                <ListItem disablePadding >
                                                    <ListItemIcon>
                                                        <SpeedTwoToneIcon color={'secondary'} />
                                                    </ListItemIcon>
                                                    <ListItemText disableTypography={true}>
                                                        <Typography variant='body1' >Starting at {plans[plans.length - 1].TransferLimitGiB} GiB/mo</Typography>
                                                        <Typography variant='body1' sx={{opacity: '.7'}}>Data transfer limit</Typography>
                                                    </ListItemText>
                                                </ListItem>
                                            </Grid>
                                            <Grid xs={2} lg={8} sx={{mb: '.5rem'}} >
                                                <ListItem disablePadding >
                                                    <ListItemIcon>
                                                        <StorageTwoToneIcon color={'secondary'} />
                                                    </ListItemIcon>
                                                    <ListItemText disableTypography={true}>
                                                        <Typography variant='body1' >Starting at {plans[plans.length - 1].BaseDiskSpaceGiB} GiB</Typography>
                                                        <Typography variant='body1' sx={{opacity: '.7'}}>Server disk space</Typography>
                                                    </ListItemText>
                                                </ListItem>
                                            </Grid>
                                            <Grid xs={2} lg={8} textAlign={'left'} >
                                                <Box sx={{ fontFamily: "'sweet-square-pro', 'Chakra Petch', sans-serif", fontSize: '3rem', color: '#FFFFFF', display: 'block', lineHeight: '48px', mb: '.25rem'}}>
                                                    Let's Talk
                                                </Box>
                                                <Typography variant='subtitle1' sx={{opacity: '0'}} >
                                                    -
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </List>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions sx={{display: 'block', p: '8px 32px 32px 32px'}} >
                            <Grid container columns={{ xs: 1, lg: 8 }} columnSpacing={{lg: 3}} >
                                <Grid xs={1} lg={8} >
                                    <Button variant='outlined' fullWidth={true} sx={{ borderWidth: '2px', maxWidth: 'none' }}>Contact us</Button>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>
            )}
            </Grid>
        


    )
}

export default PlanCards;