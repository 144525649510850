import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Box, Card, CardContent, ToggleButtonGroup, ToggleButton, SvgIcon, Stepper, Step, StepLabel, StepContent, Button, ClickAwayListener, Tooltip, IconButton, useTheme, useMediaQuery, ThemeProvider, MobileStepper, Slide, TextField, MenuItem, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { PlanCards, PowerUpBlock, ToastSnackbar, LicenseCards } from '../Components';

import ZEPowerUpsWordmark from '../assets/ZE-Power-Ups-Wordmark.png';
import FolderCopyTwoToneIcon from '@mui/icons-material/FolderCopyTwoTone';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import NavigateNextTwoToneIcon from '@mui/icons-material/NavigateNextTwoTone';
import NavigateBeforeTwoToneIcon from '@mui/icons-material/NavigateBeforeTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';


const Plans = ({ plans, isPlanActive, setIsPlanActive,  licenses, isLicenseActive, setIsLicenseActive, card, auth }) => {

	const theme = useTheme();
	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
	const isLargeScreen= useMediaQuery((theme) => theme.breakpoints.up('xl'));
	const { state } = useLocation();

	const [selectedProduct, setSelectedProduct] = useState({
		"SelectedPlan": {selected: 'none'},
		"SelectedPrice": {plan: 'none'},
		"StoragePowerUp": null,
		"SelectedLicense": {selected: 'none'},
		"SelectedLicenseLevel": '',
		"SelectedLicensePrice": {license: 'none'},
		"BackupsPowerUp": false,
		"TORPowerUp": false,
	});

	/* #region PAYMENT FREQUENCY STATES AND FUNCTIONS */

	const [paymentFreqSelection, setPaymentFreqSelection] = useState('monthly');

	const handleFreqChange = (e) => {
		setPaymentFreqSelection(e.target.value)
		const tempProduct = {...selectedProduct};
		const tempPlanActive = {...isPlanActive};
		/* SET SELECTED PRICE & POWERUP PRICES IF A PLAN IS SELECTED */
		if (selectedProduct.SelectedPlan.selected !== 'none') {	
			if (e.target.value === 'monthly') {
				tempProduct.SelectedPrice = plans[Object.values(isPlanActive).indexOf(true)].PriceOptions.filter(obj => obj.Name === 'Monthly')[0];
				selectedDiskSpace !== 'Select amount' && setAddDiskSpacePUPrice((diskSpaceOptions.find(obj => obj.ExtraStorageGiB === selectedDiskSpace).Price / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}));
				setTorPUPrice(plans[Object.values(tempPlanActive).indexOf(true)].TORPrice);
				setBackupPUPrice(plans[Object.values(tempPlanActive).indexOf(true)].BackupPrice);
			} else {
				tempProduct.SelectedPrice = plans[Object.values(isPlanActive).indexOf(true)].PriceOptions.filter(obj => obj.Name === 'Annual')[0];
				selectedDiskSpace !== 'Select amount' && setAddDiskSpacePUPrice(((diskSpaceOptions.find(obj => obj.ExtraStorageGiB === selectedDiskSpace).Price  * 12) / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}));
				setTorPUPrice(plans[Object.values(tempPlanActive).indexOf(true)].TORPrice * 12);
				setBackupPUPrice(plans[Object.values(tempPlanActive).indexOf(true)].BackupPrice * 12);
			}
		}
		/* SET SELECTED LICENSE PRICE IF A LICENSE IS SELECTED */
		const tempLicenseActive = {...isLicenseActive};
		if (selectedProduct.SelectedLicense.selected !== 'none') {
			if (licenses[Object.values(tempLicenseActive).indexOf(true)].Name === 'Community' || licenses[Object.values(tempLicenseActive).indexOf(true)].Name === 'Creator') {
				e.target.value === 'monthly' ? tempProduct.SelectedLicensePrice = licenses[Object.values(tempLicenseActive).indexOf(true)].LicenseLevels[0].PriceOptions.filter(obj => obj.Name === 'Annual')[0] : tempProduct.SelectedLicensePrice = licenses[Object.values(tempLicenseActive).indexOf(true)].LicenseLevels[0].PriceOptions.filter(obj => obj.Name === 'Annual')[0];
			} else if (e.target.value === 'monthly') {
				tempProduct.SelectedLicensePrice = licenses[Object.values(tempLicenseActive).indexOf(true)].LicenseLevels.filter(obj => obj.Name.split(' ')[2] === deviceAmtSelection)[0].PriceOptions.filter(obj => obj.Name === 'Annual')[0];
			} else {
				tempProduct.SelectedLicensePrice = licenses[Object.values(tempLicenseActive).indexOf(true)].LicenseLevels.filter(obj => obj.Name.split(' ')[2] === deviceAmtSelection)[0].PriceOptions.filter(obj => obj.Name === 'Annual')[0];
			}
		}
		setSelectedProduct(tempProduct);
	}

	/* #endregion */

	/* STEPPER STATES AND FUNCTIONS */

	const [activeStep, setActiveStep] = useState(0);
	const [mobileNextDisabled, setMobileNextDisabled] = useState(true);

	const handleMobileStepperNav = (direction) => {

		if (direction === 'next') {
			setActiveStep(activeStep + 1);
			if (activeStep === 0 && Object.values(isPlanActive).indexOf(true) !== -1) {
				setMobileNextDisabled(false);
			} else {
				setMobileNextDisabled(true);
			}
		 } else {
			setActiveStep(activeStep - 1);
			setMobileNextDisabled(false);
		 }
		window.scrollTo(0, 0);
	}

	/* #region POLYNOM LICENSE STATES AND FUNCTIONS */

    const [deviceAmtSelection, setDeviceAmtSelection] = useState('10');
	const [concurrentDeviceInfo, setConcurrentDeviceInfo] = useState(false);
	const [licenseNextDisabled, setLicenseNextDisabled] = useState(true);
	const [creatorCode, setCreatorCode] = useState('');
	const [validCreatorCode, setValidCreatorCode] = useState('zeroclout');
	const [creatorCodeErr, setCreatorCodeErr] = useState(false);

	const handleDeviceAmountChange = (e) => {
		setDeviceAmtSelection(e.target.value);
		/* SET NEW DEVICE AMOUNT & PRICE IF THERE IS AN ACTIVE LICENSE */
		const tempActive = {...isLicenseActive};
		const tempProduct = {...selectedProduct};
		if (Object.values(tempActive).indexOf(true) !== -1) {
			if (licenses[Object.values(tempActive).indexOf(true)].Name === 'Community' || licenses[Object.values(tempActive).indexOf(true)].Name === 'Creator') {
				tempProduct.SelectedLicenseLevel = licenses[Object.values(tempActive).indexOf(true)].LicenseLevels[0].Name;
				paymentFreqSelection === 'monthly' ? tempProduct.SelectedLicensePrice = licenses[Object.values(tempActive).indexOf(true)].LicenseLevels[0].PriceOptions.filter(obj => obj.Name === 'Annual')[0] : tempProduct.SelectedLicensePrice = licenses[Object.values(tempActive).indexOf(true)].LicenseLevels[0].PriceOptions.filter(obj => obj.Name === 'Annual')[0];
			} else if (paymentFreqSelection === 'monthly') {
				tempProduct.SelectedLicenseLevel = licenses[Object.values(tempActive).indexOf(true)].LicenseLevels.filter(obj => obj.Name.split(' ')[2] === e.target.value)[0].Name;
				tempProduct.SelectedLicensePrice = licenses[Object.values(tempActive).indexOf(true)].LicenseLevels.filter(obj => obj.Name.split(' ')[2] === e.target.value)[0].PriceOptions.filter(obj => obj.Name === 'Annual')[0];
			} else {
				tempProduct.SelectedLicenseLevel = licenses[Object.values(tempActive).indexOf(true)].LicenseLevels.filter(obj => obj.Name.split(' ')[2] === e.target.value)[0].Name;
				tempProduct.SelectedLicensePrice = licenses[Object.values(tempActive).indexOf(true)].LicenseLevels.filter(obj => obj.Name.split(' ')[2] === e.target.value)[0].PriceOptions.filter(obj => obj.Name === 'Annual')[0];
			}
		}
		setSelectedProduct(tempProduct);
	}

	const handleCreatorCodeCheck = (license, index) => {
		if (creatorCode === validCreatorCode) {
			handleLicenseCardClick(license, index);
		} else if (creatorCode !== '') {
			setCreatorCodeErr(true);
		} else {
			setCreatorCodeErr(false);
		}
	}

	const handleLicenseCardClick = (license, index) => {
		/* ADD OR REMOVE ACTIVE CLASS */
		const tempActive = {...isLicenseActive};
		if (tempActive[index] === true) {
			tempActive[index] = false;
		} else {
			for (const key in tempActive) {
				tempActive[key] = false;
				tempActive[index] = true;
			}
		 }
		setIsLicenseActive(tempActive);

		/* ADD OR REMOVE LICENSE INFO TO SELECTED PRODUCT OBJ */
		const tempProduct = {...selectedProduct};
		if (Object.values(tempActive).indexOf(true) !== -1) {
			/* SET SELECTED LICENSE */
			tempProduct.SelectedLicense = licenses[Object.values(tempActive).indexOf(true)].id;
			/* SET LICENSE PRICE AND DEVICE COUNT */
			if (licenses[Object.values(tempActive).indexOf(true)].Name === 'Community' || licenses[Object.values(tempActive).indexOf(true)].Name === 'Creator') {
				tempProduct.SelectedLicenseLevel = licenses[Object.values(tempActive).indexOf(true)].LicenseLevels[0].Name;
				paymentFreqSelection === 'monthly' ? tempProduct.SelectedLicensePrice = licenses[Object.values(tempActive).indexOf(true)].LicenseLevels[0].PriceOptions.filter(obj => obj.Name === 'Annual')[0] : tempProduct.SelectedLicensePrice = licenses[Object.values(tempActive).indexOf(true)].LicenseLevels[0].PriceOptions.filter(obj => obj.Name === 'Annual')[0];
			} else if (paymentFreqSelection === 'monthly') {
				tempProduct.SelectedLicenseLevel = licenses[Object.values(tempActive).indexOf(true)].LicenseLevels.filter(obj => obj.Name.split(' ')[2] === deviceAmtSelection)[0].Name;
				tempProduct.SelectedLicensePrice = licenses[Object.values(tempActive).indexOf(true)].LicenseLevels.filter(obj => obj.Name.split(' ')[2] === deviceAmtSelection)[0].PriceOptions.filter(obj => obj.Name === 'Annual')[0];
			} else {
				tempProduct.SelectedLicenseLevel = licenses[Object.values(tempActive).indexOf(true)].LicenseLevels.filter(obj => obj.Name.split(' ')[2] === deviceAmtSelection)[0].Name;
				tempProduct.SelectedLicensePrice = licenses[Object.values(tempActive).indexOf(true)].LicenseLevels.filter(obj => obj.Name.split(' ')[2] === deviceAmtSelection)[0].PriceOptions.filter(obj => obj.Name === 'Annual')[0];
			}
			setLicenseNextDisabled(false);
			setMobileNextDisabled(false);
		} else {
			tempProduct.SelectedLicense = {selected: 'none'};
			tempProduct.SelectedLicenseLevel = '';
			tempProduct.SelectedLicensePrice = {license: 'none'};
			setLicenseNextDisabled(true);
			setMobileNextDisabled(true);
		}
		setSelectedProduct(tempProduct);
	}

	/* #endregion */

	/* #region TOR PROJECT ICON SVG CODE */

	const torSVGIcon = <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	viewBox="0 0 150.5 90.7" enableBackground="new 0 0 150.5 90.7">
<g>
   <g>
	   <g>
		   <path fill="#FFFFFF" d="M85.7,71.3c0.1-1.8-0.7-4.6-1.5-7.3c-0.5-2-2.4-4-2.9-5.5c-0.9-2.5-0.5-4.3-0.6-7.8
			   c0.4,3.2,0.4,6,1.2,7.3c0.7,1.3,1.9,3.1,2.6,4.9c1.3,3.3,2.1,6.9,2.2,8.6c0.1,2.9-0.3,7.5-1.3,11c-0.4,1.3-0.9,2.5-1.5,3.6
			   c1.3-1.9,2.2-4,2.6-5.7c1.3-6.1,2.4-12.6,1.5-16.4c-0.2-0.9-0.5-3.8-1.7-7.1c-1.8-4.6-4.2-8.5-4.5-9.4c-0.9-2.6-1.2-9.1-1.3-12.6
			   c0.1,4.2,0.9,10.3,1.5,11.7c0.3,0.9,3,5.2,4.9,9.7c1.3,3,1.6,5.7,1.8,6.5c1,8.4,0.1,11.9-2,19.4c-0.3,1.1-0.8,2.1-1.3,3.1
			   c1.5-1.8,2.6-3.7,3-5.3c2.2-9.4,2.9-21.4-0.1-30.5c-1.5-4.8-5.2-9-6.1-11.9c-1-3.4-0.3-8.9-0.3-9l-8.1-3.6
			   c2.1,5.4,2.5,9.6,0.3,11.4c-8.5,7-22.7,14.9-22.7,26.7c0,12.5,7.4,26,26.5,27c-2.3-0.6-4.5-1.3-6.4-2.1c-1.9-0.8-3.6-1.9-4.9-3.4
			   c0-0.1-0.5-0.5-0.5-0.5c-2.8-3.2-6.3-8.6-7.5-13.7c-0.5-2-0.9-3.6-0.3-5.7c2.3-8.2,7.3-11.5,12.3-14.9c1.1-0.7,2.5-1.4,3.6-2.3
			   c2.1-1.5,3.2-6.3,4.4-10.1c-0.6,3.1-1.3,8.7-4,10.8c-1.1,0.8-2.3,1.6-3.5,2.4c-4.8,3.3-9.7,6.4-11.9,14.3
			   c-0.5,1.7-0.1,3.5,0.3,5.2c1.2,4.9,4.6,10.1,7.3,13.2c0,0.1,0.5,0.5,0.5,0.6c1.8,2.1,2.6,3,7.3,4.3c-1.1-0.6-2-1.3-2.8-1.8
			   c-4.8-3.3-7.2-7.4-7.6-13.5c-0.3-4.7,2.6-9.7,8.3-12.7c3.9-2,5-5.2,6.3-9c-0.9,4.3-1.8,7-5.9,9.4c-4.8,2.8-8.2,8.2-7.9,11.9
			   c0.4,4.5,3.6,9.7,7.1,12.8c1.5,1.3,3.8,2.1,6,2.7c-0.6-0.5-1.2-1.1-1.8-1.9c-0.7-1-1.2-2.4-1.7-3.7c-0.5-1.3-1-2.6-1-3.8
			   c0-2.9,0.3-6.5,2.4-8.9c1.9-2.2,2.5-2.5,3.3-5.1c-0.6,3-1,3.2-2.8,5.7c-2.3,3.2-2.4,6.4-2.1,8.4c0.2,1.3,0.7,2.5,1.1,3.5
			   c0.5,1.3,1.1,2.5,1.9,3.6c0.7,0.8,1,1.4,2.1,1.8c1.1-2,1.8-6.2,1.9-8.6c0.1-2,0.2-4.3-0.1-6.6c-0.4-3.1-1.4-6.1-1.4-8.5
			   c0.5,2.2,1.6,5.2,2.3,8.3c0.5,2.4,0.6,4.8,0.4,6.5c-0.2,2.1-0.3,3.8-0.6,5.6c-0.2,1.2-0.8,2.4-1.7,3.5c2.2-1.7,3.6-4.3,4.2-7.4
			   C85.6,77,85.7,73.3,85.7,71.3z"/>
	   </g>
	   <g>
		   <path fill="#FFFFFF" d="M106,53.4c-3.1-5.7-11-11.3-19.3-16.5c-1.9-1.1-2.3-5.3-2-7.1l-1.9-0.9c0,0-0.7,5,0.6,9
			   c0.9,2.8,3.9,6,5.7,10.5c1.3,3,2.6,10.2,2.7,11c1,8.4,0.5,16.3-2,23c-1.1,3.1-3.5,5.9-5.4,7.2c-0.3,0.2-0.6,0.4-0.9,0.6
			   C99.3,90.7,116.7,72.8,106,53.4z"/>
	   </g>
   </g>
   <g>
	   <path fill="#FFFFFF" d="M102.7,4.3C96.2,6.5,90.4,9.9,86.1,16c2.6-5.3,6.7-10.6,11.3-16c-6.3,4.5-11.7,9.6-15.2,16.4l2.4-9.6
		   c-3.7,6.6-6.2,13.4-7,20.1l4.2,1.8C87.2,20.4,94.1,12.1,102.7,4.3z"/>
   </g>
   <g>
	   <polygon fill="#FFFFFF" points="80.1,64.5 80.1,64.5 80.1,64.5 		"/>
   </g>
   <g>
	   <path fill="#FFFFFF" d="M61.6,28.6v-16c0-2.1-1.8-3.8-3.8-3.8H3.8C1.7,8.8,0,10.5,0,12.6v16c0,2.1,1.7,3.8,3.8,3.8H16
		   c1.1,0,2.6,0.9,2.6,2.6v52.7c0,1.8,1.3,3.1,3,3.1h18.4c1.7,0,3.1-1.3,3.1-3.1V35.4c0-1.6,1.1-3,3.6-3h11.1
		   C59.9,32.3,61.6,30.6,61.6,28.6z"/>
   </g>
   <g>
	   <path fill="#FFFFFF" d="M150.5,50.2V33.1c0-1.7-1.4-3.1-3.2-3.1h-4.8c-0.6,0-2.1,0.1-2.5,0.1c-12.1,1.2-21.7,11-22.6,23.2
		   c-0.1,0.5-0.1,1.3-0.1,1.9v32.9c0,1.3,1.3,2.5,2.8,2.5h17.2c1.5,0,2.8-1.1,2.8-2.5V59.2c0-3.1,2.4-5.9,7.7-5.9
		   C150.6,53.3,150.5,52.4,150.5,50.2z"/>
   </g>
</g>
</svg>;

/* #endregion */

	/* #region SERVER PLANS STATES AND FUNCTIONS */

	const [plansNextDisabled, setPlansNextDisabled] = useState(true);

	const handlePlanCardClick = (plan, index) => {
		/* ADD OR REMOVE ACTIVE CLASS */
		const tempActive = {...isPlanActive};
		if (tempActive[index] === true) {
			tempActive[index] = false;
		 } else {
			for (const key in tempActive) {
				tempActive[key] = false;
				tempActive[index] = true;
			}
		 }
		setIsPlanActive(tempActive);

		/* ADD OR REMOVE PLAN INFO TO SELECTED PRODUCT OBJ */
		const tempProduct = {...selectedProduct};
		if (Object.values(tempActive).indexOf(true) !== -1) {
			tempProduct.SelectedPlan = plans[Object.values(tempActive).indexOf(true)].id;
			if (paymentFreqSelection === 'monthly') {
				tempProduct.SelectedPrice = plans[Object.values(tempActive).indexOf(true)].PriceOptions.filter(obj => obj.Name === 'Monthly')[0];
			} else {
				tempProduct.SelectedPrice = plans[Object.values(tempActive).indexOf(true)].PriceOptions.filter(obj => obj.Name === 'Annual')[0];
			}
			setTorPUPrice(paymentFreqSelection === 'monthly' ? plans[Object.values(tempActive).indexOf(true)].TORPrice : plans[Object.values(tempActive).indexOf(true)].TORPrice * 12);
			setBackupPUPrice(paymentFreqSelection === 'monthly' ? plans[Object.values(tempActive).indexOf(true)].BackupPrice : plans[Object.values(tempActive).indexOf(true)].BackupPrice * 12);
			setDiskSpaceOptions(plans[Object.values(tempActive).indexOf(true)].StorageOptions);
			setPlansNextDisabled(false);
			setMobileNextDisabled(false);
			setCheckoutDisabled(false);
		} else {
			tempProduct.SelectedPlan = {selected: 'none'};
			tempProduct.SelectedPrice = {plan: 'none'};
			setTorPU(false);
			setTorPUPrice(0);
			setBackupPU(false);
			setBackupPUPrice(0);
			setSelectedDiskSpace('Select amount');
			setAddDiskSpacePU(false);
			setDiskSpaceOptions([]);
			setAddDiskSpacePUPrice('$0');
			setPlansNextDisabled(true);
			setMobileNextDisabled(true);
			setCheckoutDisabled(true);
		}
		setSelectedProduct(tempProduct);
	}

	/* #endregion */

	/* #region POWERUP STATES AND FUNCTIONS */

	const [torPU, setTorPU] = useState(false);
	const [torPUPrice, setTorPUPrice] = useState(0);
	const [backupPU, setBackupPU] = useState(false);
	const [backupPUPrice, setBackupPUPrice] = useState(0);
	const [addDiskSpacePU, setAddDiskSpacePU] = useState(false);
	const [addDiskSpacePUPrice, setAddDiskSpacePUPrice] = useState('$0');
	const [selectedDiskSpace, setSelectedDiskSpace] = useState('Select amount');
	const [diskSpaceOptions, setDiskSpaceOptions] = useState([]);

	const BUPUItems = ['- Only the database is backed up, not media and attachments.', '- The powerup allows for one backup restore per month.', '- Each additional restore in the month will cost $25.00.']
	
	const handlePowerupClick = (e, name) => {
		const tempProduct = {...selectedProduct};
		switch (name) {
			case 'Additional Disk Space':
				if (e.target.value === 'Select amount') {
					setSelectedDiskSpace('Select amount');
					setAddDiskSpacePU(false);
					setAddDiskSpacePUPrice('$0');
					tempProduct.StoragePowerUp = null;
				} else {
					setSelectedDiskSpace(e.target.value);
					setAddDiskSpacePU(true);
					setAddDiskSpacePUPrice(paymentFreqSelection === 'monthly' ? ((diskSpaceOptions.find(obj => obj.ExtraStorageGiB === e.target.value).Price / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})) : (((diskSpaceOptions.find(obj => obj.ExtraStorageGiB === e.target.value).Price  * 12) / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})));
					tempProduct.StoragePowerUp = diskSpaceOptions.find(obj => obj.ExtraStorageGiB === e.target.value);
				}
				break;
			case 'Server Backup':
				setBackupPU(!backupPU);
				tempProduct.BackupsPowerUp = !tempProduct.BackupsPowerUp;
				break;
			case 'Tor Hidden Service':
				setTorPU(!torPU);
				tempProduct.TORPowerUp = !tempProduct.TORPowerUp;
				break;
			default:
				break;
		}
		setSelectedProduct(tempProduct);
	}

	/* #endregion */

	/* CHECKOUT STATES AND FUNCTIONS */

	const navigate = useNavigate();

	const [checkoutDisabled, setCheckoutDisabled] = useState(true);
	const [checkoutErr, setCheckoutErr] = useState(false);
	const [checkoutErrMsg, setCheckoutErrMsg] = useState('');

	const handleCheckout = () => {
		if (selectedProduct.SelectedPlan.selected !== 'none' && selectedProduct.SelectedPrice.plan !== 'none' && selectedProduct.SelectedLicense.selected !== 'none' && selectedProduct.SelectedLicenseLevel !== '' && selectedProduct.SelectedLicensePrice.license !== 'none') {
			const tempPlanActive = {...isPlanActive};
			const tempLicenseActive = {...isLicenseActive};
			for (const key in tempPlanActive) {
				tempPlanActive[key] = false;
			}
			for (const key in tempLicenseActive) {
				tempLicenseActive[key] = false;
			}
			setIsLicenseActive(tempLicenseActive);
			setIsPlanActive(tempPlanActive);
			if (card === null || !auth) {
				navigate('/checkout', {state: [selectedProduct, plans.find(obj => obj.id.id.String === selectedProduct.SelectedPlan.id.String), licenses.find(obj => obj.id.id.String === selectedProduct.SelectedLicense.id.String)]});
			} else {
				navigate('/dashboard', {state: [selectedProduct, plans.find(obj => obj.id.id.String === selectedProduct.SelectedPlan.id.String), licenses.find(obj => obj.id.id.String === selectedProduct.SelectedLicense.id.String)]});
			}
		} else {
			setCheckoutErr(true);
			setCheckoutErrMsg('Please select a server plan and payment frequency before proceeding.');
		}
	};

	/* #region SET STATE OF PAGE WHEN RETURNING FROM CHECKOUT */

	useEffect(() => {
		if(state !== null) {
			setSelectedProduct(state[0]);
			/* SET PAYMENT FREQUENCY */
			setPaymentFreqSelection(state[0].SelectedPrice.Name === 'Monthly' ? 'monthly' : 'yearly');
			/* SET DEVICE AMOUNT SELECTION */
			(state[2].Name !== 'Community' && state[2].Name !== 'Creator') && setDeviceAmtSelection(state[0].SelectedLicenseLevel.split(' ')[2]);
			/* SET ACTIVE LICENSE */
			const tempLicenseActive = {...isLicenseActive};
			tempLicenseActive[licenses.findIndex(obj => obj.id.id.String === state[2].id.id.String)] = true;
			setIsLicenseActive(tempLicenseActive);
			setLicenseNextDisabled(false);
			/* SET ACTIVE SERVER PLAN */
			const tempPlanActive = {...isPlanActive};
			tempPlanActive[plans.findIndex(obj => obj.id.id.String === state[1].id.id.String)] = true;
			setIsPlanActive(tempPlanActive);
			setPlansNextDisabled(false);
			/* SET SELECTED PLAN, PRICE, & POWERUPS */
			setTorPUPrice(state[0].SelectedPrice.Name === 'Monthly' ? state[1].TORPrice : state[1].TORPrice * 12);
			setBackupPUPrice(state[0].SelectedPrice.Name === 'Monthly' ? state[1].BackupPrice : state[1].BackupPrice * 12);
			setDiskSpaceOptions(state[1].StorageOptions);
			/* SET POWERUP STATES */
			if (state[0].StoragePowerUp !== null) {
				setSelectedDiskSpace(state[0].StoragePowerUp.ExtraStorageGiB);
				setAddDiskSpacePU(true);
				setAddDiskSpacePUPrice(state[0].SelectedPrice.Name === 'Monthly' ? ((state[0].StoragePowerUp.Price / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})) : (((state[0].StoragePowerUp.Price  * 12) / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})))
			}
			setBackupPU(state[0].BackupsPowerUp);
			setTorPU(state[0].TORPowerUp);
			setCheckoutDisabled(false);
		}
	}, [])

	/* #endregion */

	return (
		<ThemeProvider theme={theme}>
			<Box sx={{ flexGrow: 1, padding: { xs: '0px 16px', md: '0px 8.25%' }, width: '100%' }}>
				<Grid container columns={{ xs: 4, sm: 8, lg: 12 }} sx={{ minHeight: { xs: 'calc(100vh - 56px)', md: 'calc(100vh - 64px)', lg: 'calc(100vh - 112px)' }, marginLeft: '0' }} >
					<Grid xs={4} sm={6} lg={6} smOffset={1} lgOffset={3} textAlign={'center'}>
						<Typography variant='h1' color={'secondary'} sx={{my: { xs: '1.5rem', lg: '2rem'}}} >SERVER PLANS</Typography>
					</Grid>					
				{ licenses === 'unavailable' || plans === 'unavailable' ? (
					<Grid xs={4} sm={8} lg={12} >
						<Stack flexDirection='column' alignItems='center' justifyContent='center' spacing={{xs: 2, lg: 3}} textAlign='center' sx={{pb: {xs: '24px', lg: '40px'}}} >
							<ErrorTwoToneIcon color='error' sx={{ fontSize: '10rem', opacity: '.28' }} />
							{
								plans === 'unavailable' && licenses === 'unavailable' ? (	
									<Typography variant='h5' >Failed to retrieve our server plans and the licenses. Check back soon.</Typography>
								) : (
									plans !== 'unavailable' ? (
										<Typography variant='h5' >Failed to retrieve the licenses. Check back soon.</Typography>
									) : (
										<Typography variant='h5' >Failed to retrieve our server plans. Check back soon.</Typography>
									)
								)
							}
						</Stack>
					</Grid>
				) : (
					isDesktop ? (
						<Grid xs={4} sm={8} lg={12} sx={{mt: '1.5rem'}} >
							<Stepper activeStep={activeStep} orientation='vertical' sx={{mb: '32px'}} >
								<Step>
									<StepLabel>
									<Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} >
										<Typography variant='subtitle1' >Select a Polynom license</Typography>
										{ activeStep > 0 && <Typography variant='h6' color='secondary' >{licenses[Object.values(isLicenseActive).indexOf(true)].Name} license selected</Typography> }
									</Box>
									</StepLabel>
									<StepContent>
										<Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: {xs: 2, lg: 3}}}  >
											<Box sx={{display: 'flex', alignItems: 'center'}} >
												<Typography variant='h6' >Select the amount of concurrent devices</Typography>
												<ClickAwayListener onClickAway={() => setConcurrentDeviceInfo(false)} >
													<div>
														<Tooltip title='The amount of devices that can be connected to your sever at the same time.' arrow PopperProps={{disablePortal: true,}} onClose={() => setConcurrentDeviceInfo(false)} open={concurrentDeviceInfo} disableFocusListener disableHoverListener disableTouchListener >
															<IconButton tabIndex={-1} onClick={() => setConcurrentDeviceInfo(!concurrentDeviceInfo)} >
																<InfoTwoToneIcon color='secondary' />
															</IconButton>
														</Tooltip>
													</div>
												</ClickAwayListener>
											</Box>
											{ !isLargeScreen ? (
												<TextField variant='filled' label='Select amount' value={deviceAmtSelection} onChange={(e) => handleDeviceAmountChange(e)} defaultValue='10' color='secondary' margin='normal' type='text' sx={{ minWidth: '204px' }} select required>
													<MenuItem value='10'>
														Up to 10 devices
													</MenuItem>
													<MenuItem value='25'>
														Up to 25 devices
													</MenuItem>
													<MenuItem value='50'>
														Up to 50 devices
													</MenuItem>
													<MenuItem value='100'>
														Up to 100 devices
													</MenuItem>
													<MenuItem value='500'>
														Up to 500 devices
													</MenuItem>
											</TextField>
											) : (
												<Box sx={{display: 'flex', alignItems: 'center'}} gap={3} >
													<Typography variant='h6' >Up to</Typography>
													<ToggleButtonGroup color='primary' exclusive value={deviceAmtSelection} onChange={(e) => handleDeviceAmountChange(e)} sx={{width: {xs: '100%', lg: 'initial'}}} >
														<ToggleButton value='10' sx={{textTransform: 'all-caps', fontSize: '1rem', letterSpacing: '.05rem', minWidth: '160px'}} >10 devices</ToggleButton>
														<ToggleButton value='25' sx={{textTransform: 'all-caps', fontSize: '1rem', letterSpacing: '.05rem', minWidth: '160px'}} >25 devices</ToggleButton>
														<ToggleButton value='50' sx={{textTransform: 'all-caps', fontSize: '1rem', letterSpacing: '.05rem', minWidth: '160px'}} >50 devices</ToggleButton>
														<ToggleButton value='100' sx={{textTransform: 'all-caps', fontSize: '1rem', letterSpacing: '.05rem', minWidth: '160px'}} >100 devices</ToggleButton>
														<ToggleButton value='500' sx={{textTransform: 'all-caps', fontSize: '1rem', letterSpacing: '.05rem', minWidth: '160px'}} >500 devices</ToggleButton>
													</ToggleButtonGroup>
												</Box>
											)}
										</Box>
										<LicenseCards  licenses={licenses} isLicenseActive={isLicenseActive} paymentFreqSelection='monthly' deviceAmtSelection={deviceAmtSelection} handleLicenseCardClick={handleLicenseCardClick} creatorCode={creatorCode} setCreatorCode={setCreatorCode} creatorCodeErr={creatorCodeErr} handleCreatorCodeCheck={handleCreatorCodeCheck} validCreatorCode={validCreatorCode} state={state} page='server-plans' />

										<Button variant='contained' onClick={() => setActiveStep(activeStep + 1)} endIcon={<NavigateNextTwoToneIcon />} disabled={licenseNextDisabled} disableElevation={true} sx={{ mt: {xs: 2, lg: 3}, maxWidth: 'none' }} >Next step</Button>
									</StepContent>
								</Step>
								<Step>
									<StepLabel>
									<Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} >
										<Typography variant='subtitle1' >Select a server plan</Typography>
										{ activeStep > 1 && <Typography variant='h6' color='secondary' >{plans[Object.values(isPlanActive).indexOf(true)].Name} server plan selected</Typography> }
									</Box>
									</StepLabel>
									<StepContent>
										<Box sx={{display: 'flex', flexDirection: {xs: 'column', lg: 'row'}, justifyContent: {xs: 'start', lg: 'space-between'}, alignItems: 'center', mb: {xs: 2, lg: 3}}}  >
											<Typography variant='h6' >Select your payment frequency</Typography>
											<ToggleButtonGroup color='primary' exclusive value={paymentFreqSelection} onChange={(e) => handleFreqChange(e)} sx={{width: {xs: '100%', lg: 'initial'}}} >
												<ToggleButton value='monthly' sx={{textTransform: 'capitalize', fontSize: '1rem', letterSpacing: '.05rem', minWidth: '160px', width: '100%' }} >Monthly</ToggleButton>
												<ToggleButton value='yearly' sx={{textTransform: 'capitalize', fontSize: '1rem', letterSpacing: '.05rem', minWidth: '160px', width: '100%'}} >Yearly</ToggleButton>
											</ToggleButtonGroup>
										</Box>
										<PlanCards plans={plans} isPlanActive={isPlanActive} setIsPlanActive={setIsPlanActive}  paymentFreqSelection={paymentFreqSelection} handlePlanCardClick={handlePlanCardClick} />
										<Button variant='outlined' onClick={() => setActiveStep(activeStep - 1)} startIcon={<NavigateBeforeTwoToneIcon />} disableElevation={true} sx={{ mt: {xs: 2, lg: 3}, maxWidth: 'none' }} >Previous step</Button>
										<Button variant='contained' onClick={() => setActiveStep(activeStep + 1)} disabled={plansNextDisabled} endIcon={<NavigateNextTwoToneIcon />} disableElevation={true} sx={{ mt: {xs: 2, lg: 3}, maxWidth: 'none', ml: '16px' }} >Next step</Button>
									</StepContent>
								</Step>
								<Step>
									<StepLabel><Typography variant='subtitle1' >Choose your PowerUps</Typography></StepLabel>
									<StepContent>
										<Card sx={{p: '16px 16px 8px 16px'}} >
											<CardContent>
												<Grid container columns={{ xs: 4, sm: 8, lg: 12 }} >
													<Grid xs={2} >
														<img src={ZEPowerUpsWordmark} alt='Zero Eyes Power-ups Wordmark' className='power-ups-wm' ></img>
													</Grid>
													<Grid xs={4} sm={8} lg={12} display={{lg: selectedProduct.SelectedPlan.selected === 'none' && 'flex'}} justifyContent={'space-between'} textAlign={'left'} >
														<Typography variant='subtitle1' sx={{mt: '.5rem', display: 'inline-block'}} >Enhance your server plan with addtional security and disk space.</Typography>
														{ selectedProduct.SelectedPlan.selected === 'none' && (
															<Typography variant='subtitle1' color='secondary' sx={{mt: '.5rem', display: 'inline-block'}} >
																You must select a server plan before choosing your PowerUps.
															</Typography>
														)}
													</Grid>
													<Grid xs={4} sm={8} lg={12} sx={{mt: '1rem'}} >
														<Grid container columns={{xs: 1, sm: 2, lg: 3}} spacing={2} justifyContent={{sm: 'center', lg: 'none'}} >
															<Grid xs={1} >
																<PowerUpBlock powerUpIcon={<AddBoxTwoToneIcon />} powerUpName='Additional Disk Space' powerUpDesc='Flexible pricing based on your needs we allow you to choose how much extra disk space you would like.' powerUpPrice={addDiskSpacePUPrice} paymentFreqSelection={paymentFreqSelection} active={addDiskSpacePU} selectedDiskSpace={selectedDiskSpace} powerUpValueSelection={true} powerUpValues={diskSpaceOptions} handlePowerupClick={handlePowerupClick} disabled={selectedProduct.SelectedPlan.selected !== 'none' ? false : true} />
															</Grid>
															<Grid xs={1} >
																<PowerUpBlock powerUpIcon={<FolderCopyTwoToneIcon />} powerUpName='Server Backup' powerUpDesc="We will take a daily snapshot backup of your server's database." powerUpPrice={(backupPUPrice / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})} paymentFreqSelection={paymentFreqSelection} active={backupPU} handlePowerupClick={handlePowerupClick} disabled={selectedProduct.SelectedPlan.selected !== 'none' ? false : true} powerUpInfo={true} powerUpInfoText={BUPUItems.join('\n')} />
															</Grid>
															<Grid xs={1} >
																<PowerUpBlock powerUpIcon={<SvgIcon sx={{mt: '-2px'}}>{torSVGIcon}</SvgIcon>} powerUpName='Tor Hidden Service' powerUpDesc='We will create and maintain a TOR Hidden service for your server so that your community may access it more securely.' powerUpPrice={(torPUPrice / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})} paymentFreqSelection={paymentFreqSelection} active={torPU} handlePowerupClick={handlePowerupClick} disabled={selectedProduct.SelectedPlan.selected !== 'none' ? false : true} />
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</CardContent>
										</Card>
										<Button variant='outlined' onClick={() => setActiveStep(activeStep - 1)} startIcon={<NavigateBeforeTwoToneIcon />} disableElevation={true} sx={{ mt: {xs: 2, lg: 3}, maxWidth: 'none' }} >Previous step</Button>
										<Button variant='contained' startIcon={<ShoppingCartTwoToneIcon />} disableElevation={true} onClick={handleCheckout} disabled={checkoutDisabled} sx={{ mt: {xs: 2, lg: 3}, maxWidth: 'none', ml: '16px' }} >Checkout</Button>
									</StepContent>
								</Step>
							</Stepper>
						</Grid>
					) : (
						<Grid xs={4} sm={8} lg={12} >
							<Slide direction='right' in={activeStep === 0} mountOnEnter unmountOnExit >
								<Box sx={{mb: '160px'}} >
									<Box sx={{textAlign: 'center'}} >
										<Typography variant='h4' color='secondary' sx={{mb: '.75rem'}} >
											Select a Polynom License
										</Typography>
									</Box>
									<Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
										<Typography variant='subtitle1' >Amount of concurrent devices</Typography>
										<ClickAwayListener onClickAway={() => setConcurrentDeviceInfo(false)} >
											<div>
												<Tooltip title='The amount of devices that can be connected to your sever at the same time.' arrow PopperProps={{disablePortal: true,}} onClose={() => setConcurrentDeviceInfo(false)} open={concurrentDeviceInfo} disableFocusListener disableHoverListener disableTouchListener >
													<IconButton tabIndex={-1} onClick={() => setConcurrentDeviceInfo(!concurrentDeviceInfo)} >
														<InfoTwoToneIcon color='secondary' />
													</IconButton>
												</Tooltip>
											</div>
										</ClickAwayListener>
									</Box>
									<TextField variant='filled' label='Select amount' value={deviceAmtSelection} onChange={(e) => handleDeviceAmountChange(e)} defaultValue='10' color='secondary' margin='normal' type='text' sx={{ width: '100%', m: '4px 0 16px 0' }} select required>
											<MenuItem value='10'>
												Up to 10 devices
											</MenuItem>
											<MenuItem value='25'>
												Up to 25 devices
											</MenuItem>
											<MenuItem value='50'>
												Up to 50 devices
											</MenuItem>
											<MenuItem value='100'>
												Up to 100 devices
											</MenuItem>
											<MenuItem value='500'>
												Up to 500 devices
											</MenuItem>
									</TextField>
									<LicenseCards  licenses={licenses} isLicenseActive={isLicenseActive} paymentFreqSelection='monthly' deviceAmtSelection={deviceAmtSelection} handleLicenseCardClick={handleLicenseCardClick} creatorCode={creatorCode} setCreatorCode={setCreatorCode} creatorCodeErr={creatorCodeErr} handleCreatorCodeCheck={handleCreatorCodeCheck} validCreatorCode={validCreatorCode} state={state} page='server-plans' />
								</Box>
							</Slide>
							<Slide direction='right' in={activeStep === 1} mountOnEnter unmountOnExit >
								<Box sx={{mb: '160px'}} >
								<Box sx={{textAlign: 'center'}} >
										<Typography variant='h4' color='secondary' sx={{mb: '.75rem'}} >
											Select a Server Plan
										</Typography>
									</Box>
									<Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center', mb: 2}}  >
										<Typography variant='h6' sx={{mb: '1rem'}} >Select your payment frequency</Typography>
										<ToggleButtonGroup color='primary' exclusive value={paymentFreqSelection} onChange={(e) => handleFreqChange(e)} sx={{width: '100%', maxWidth: '400px'}} >
											<ToggleButton value='monthly' sx={{textTransform: 'capitalize', fontSize: '1rem', letterSpacing: '.05rem', minWidth: '160px', width: '100%' }} >Monthly</ToggleButton>
											<ToggleButton value='yearly' sx={{textTransform: 'capitalize', fontSize: '1rem', letterSpacing: '.05rem', minWidth: '160px', width: '100%'}} >Yearly</ToggleButton>
										</ToggleButtonGroup>
									</Box>
									<PlanCards plans={plans} isPlanActive={isPlanActive} setIsPlanActive={setIsPlanActive}  paymentFreqSelection={paymentFreqSelection} handlePlanCardClick={handlePlanCardClick} />
								</Box>
							</Slide>
							<Slide direction='right' in={activeStep === 2} mountOnEnter unmountOnExit >
								<Box sx={{mb: '160px'}} >
									<Box sx={{textAlign: 'center'}} >
										<Typography variant='h4' color='secondary' sx={{mb: '1rem'}} >
											Choose your PowerUps
										</Typography>
									</Box>
									<Card sx={{p: '16px 16px 8px 16px'}} >
										<CardContent>
											<Grid container columns={{ xs: 4, sm: 8, lg: 12 }} >
												<Grid xs={2} >
													<img src={ZEPowerUpsWordmark} alt='Zero Eyes Power-ups Wordmark' className='power-ups-wm' ></img>
												</Grid>
												<Grid xs={4} sm={8} lg={12} display={{lg: selectedProduct.SelectedPlan.selected === 'none' && 'flex'}} justifyContent={'space-between'} textAlign={'left'} >
													<Typography variant='subtitle1' sx={{mt: '.5rem', display: 'inline-block'}} >Enhance your server plan with addtional security and disk space.</Typography>
													{ selectedProduct.SelectedPlan.selected === 'none' && (
														<Typography variant='subtitle1' color='secondary' sx={{mt: '.5rem', display: 'inline-block'}} >
															You must select a server plan before choosing your PowerUps.
														</Typography>
													)}
												</Grid>
												<Grid xs={4} sm={8} lg={12} sx={{mt: '1rem'}} >
													<Grid container columns={{xs: 1, sm: 2, lg: 3}} spacing={2} justifyContent={{sm: 'center', lg: 'none'}} >
														<Grid xs={1} >
															<PowerUpBlock powerUpIcon={<AddBoxTwoToneIcon />} powerUpName='Additional Disk Space' powerUpDesc='Flexible pricing based on your needs we allow you to choose how much extra disk space you would like.' powerUpPrice={addDiskSpacePUPrice} paymentFreqSelection={paymentFreqSelection} active={addDiskSpacePU} selectedDiskSpace={selectedDiskSpace} powerUpValueSelection={true} powerUpValues={diskSpaceOptions} handlePowerupClick={handlePowerupClick} disabled={selectedProduct.SelectedPlan.selected !== 'none' ? false : true} />
														</Grid>
														<Grid xs={1} >
															<PowerUpBlock powerUpIcon={<FolderCopyTwoToneIcon />} powerUpName='Server Backup' powerUpDesc="We will take a daily snapshot backup of your server's database." powerUpPrice={(backupPUPrice / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})} paymentFreqSelection={paymentFreqSelection} active={backupPU} handlePowerupClick={handlePowerupClick} disabled={selectedProduct.SelectedPlan.selected !== 'none' ? false : true} powerUpInfo={true} powerUpInfoText={BUPUItems.join('\n')} />
														</Grid>
														<Grid xs={1} >
															<PowerUpBlock powerUpIcon={<SvgIcon sx={{mt: '-2px'}}>{torSVGIcon}</SvgIcon>} powerUpName='Tor Hidden Service' powerUpDesc='We will create and maintain a TOR Hidden service for your server so that your community may access it more securely.' powerUpPrice={(torPUPrice / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})} paymentFreqSelection={paymentFreqSelection} active={torPU} handlePowerupClick={handlePowerupClick} disabled={selectedProduct.SelectedPlan.selected !== 'none' ? false : true} />
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</CardContent>
									</Card>
								</Box>
							</Slide>
							<MobileStepper variant='dots' steps={3} position='bottom' activeStep={activeStep} nextButton={ activeStep === 2 ?
								<Button size='small' onClick={handleCheckout} startIcon={<ShoppingCartTwoToneIcon />} disabled={checkoutDisabled} >Checkout</Button> : 
								<Button size='small' onClick={() => handleMobileStepperNav('next')} disabled={state !== null ? false : mobileNextDisabled} >Next<NavigateNextTwoToneIcon /></Button>
							}
							backButton={
								<Button size='small' onClick={() => handleMobileStepperNav('back')} disabled={activeStep === 0} ><NavigateBeforeTwoToneIcon />Back</Button>
							}

							/>
						</Grid>
				))}
				</Grid>
				<ToastSnackbar alertSeverity='error' alertTitle='Missing Product' openSnackbar={checkoutErr} setOpenSnackbar={setCheckoutErr} snackbarDescription={checkoutErrMsg} />
			</Box>
			
		</ThemeProvider>
	)
}

export default Plans;