import React, { useEffect, useState } from 'react'
import { Link, Navigate } from 'react-router-dom';
import { Dialog, DialogTitle, DialogActions, List, ListItem, ListItemIcon, ListItemText, Button, Typography, DialogContent, useMediaQuery, Box, SvgIcon, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import ZEPowerUpsWordmark from '../assets/ZE-Power-Ups-Wordmark.png';
import StorageTwoToneIcon from '@mui/icons-material/StorageTwoTone';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import RecordVoiceOverTwoToneIcon from '@mui/icons-material/RecordVoiceOverTwoTone';
import SpeedTwoToneIcon from '@mui/icons-material/SpeedTwoTone';
import FolderCopyTwoToneIcon from '@mui/icons-material/FolderCopyTwoTone';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import DevicesTwoToneIcon from '@mui/icons-material/DevicesTwoTone';

/* #region TOR PROJECT ICON SVG CODE */

const torSVGIcon = <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
viewBox="0 0 150.5 90.7" enableBackground="new 0 0 150.5 90.7">
<g>
   <g>
	   <g>
		   <path fill="#FFFFFF" d="M85.7,71.3c0.1-1.8-0.7-4.6-1.5-7.3c-0.5-2-2.4-4-2.9-5.5c-0.9-2.5-0.5-4.3-0.6-7.8
			   c0.4,3.2,0.4,6,1.2,7.3c0.7,1.3,1.9,3.1,2.6,4.9c1.3,3.3,2.1,6.9,2.2,8.6c0.1,2.9-0.3,7.5-1.3,11c-0.4,1.3-0.9,2.5-1.5,3.6
			   c1.3-1.9,2.2-4,2.6-5.7c1.3-6.1,2.4-12.6,1.5-16.4c-0.2-0.9-0.5-3.8-1.7-7.1c-1.8-4.6-4.2-8.5-4.5-9.4c-0.9-2.6-1.2-9.1-1.3-12.6
			   c0.1,4.2,0.9,10.3,1.5,11.7c0.3,0.9,3,5.2,4.9,9.7c1.3,3,1.6,5.7,1.8,6.5c1,8.4,0.1,11.9-2,19.4c-0.3,1.1-0.8,2.1-1.3,3.1
			   c1.5-1.8,2.6-3.7,3-5.3c2.2-9.4,2.9-21.4-0.1-30.5c-1.5-4.8-5.2-9-6.1-11.9c-1-3.4-0.3-8.9-0.3-9l-8.1-3.6
			   c2.1,5.4,2.5,9.6,0.3,11.4c-8.5,7-22.7,14.9-22.7,26.7c0,12.5,7.4,26,26.5,27c-2.3-0.6-4.5-1.3-6.4-2.1c-1.9-0.8-3.6-1.9-4.9-3.4
			   c0-0.1-0.5-0.5-0.5-0.5c-2.8-3.2-6.3-8.6-7.5-13.7c-0.5-2-0.9-3.6-0.3-5.7c2.3-8.2,7.3-11.5,12.3-14.9c1.1-0.7,2.5-1.4,3.6-2.3
			   c2.1-1.5,3.2-6.3,4.4-10.1c-0.6,3.1-1.3,8.7-4,10.8c-1.1,0.8-2.3,1.6-3.5,2.4c-4.8,3.3-9.7,6.4-11.9,14.3
			   c-0.5,1.7-0.1,3.5,0.3,5.2c1.2,4.9,4.6,10.1,7.3,13.2c0,0.1,0.5,0.5,0.5,0.6c1.8,2.1,2.6,3,7.3,4.3c-1.1-0.6-2-1.3-2.8-1.8
			   c-4.8-3.3-7.2-7.4-7.6-13.5c-0.3-4.7,2.6-9.7,8.3-12.7c3.9-2,5-5.2,6.3-9c-0.9,4.3-1.8,7-5.9,9.4c-4.8,2.8-8.2,8.2-7.9,11.9
			   c0.4,4.5,3.6,9.7,7.1,12.8c1.5,1.3,3.8,2.1,6,2.7c-0.6-0.5-1.2-1.1-1.8-1.9c-0.7-1-1.2-2.4-1.7-3.7c-0.5-1.3-1-2.6-1-3.8
			   c0-2.9,0.3-6.5,2.4-8.9c1.9-2.2,2.5-2.5,3.3-5.1c-0.6,3-1,3.2-2.8,5.7c-2.3,3.2-2.4,6.4-2.1,8.4c0.2,1.3,0.7,2.5,1.1,3.5
			   c0.5,1.3,1.1,2.5,1.9,3.6c0.7,0.8,1,1.4,2.1,1.8c1.1-2,1.8-6.2,1.9-8.6c0.1-2,0.2-4.3-0.1-6.6c-0.4-3.1-1.4-6.1-1.4-8.5
			   c0.5,2.2,1.6,5.2,2.3,8.3c0.5,2.4,0.6,4.8,0.4,6.5c-0.2,2.1-0.3,3.8-0.6,5.6c-0.2,1.2-0.8,2.4-1.7,3.5c2.2-1.7,3.6-4.3,4.2-7.4
			   C85.6,77,85.7,73.3,85.7,71.3z"/>
	   </g>
	   <g>
		   <path fill="#FFFFFF" d="M106,53.4c-3.1-5.7-11-11.3-19.3-16.5c-1.9-1.1-2.3-5.3-2-7.1l-1.9-0.9c0,0-0.7,5,0.6,9
			   c0.9,2.8,3.9,6,5.7,10.5c1.3,3,2.6,10.2,2.7,11c1,8.4,0.5,16.3-2,23c-1.1,3.1-3.5,5.9-5.4,7.2c-0.3,0.2-0.6,0.4-0.9,0.6
			   C99.3,90.7,116.7,72.8,106,53.4z"/>
	   </g>
   </g>
   <g>
	   <path fill="#FFFFFF" d="M102.7,4.3C96.2,6.5,90.4,9.9,86.1,16c2.6-5.3,6.7-10.6,11.3-16c-6.3,4.5-11.7,9.6-15.2,16.4l2.4-9.6
		   c-3.7,6.6-6.2,13.4-7,20.1l4.2,1.8C87.2,20.4,94.1,12.1,102.7,4.3z"/>
   </g>
   <g>
	   <polygon fill="#FFFFFF" points="80.1,64.5 80.1,64.5 80.1,64.5 		"/>
   </g>
   <g>
	   <path fill="#FFFFFF" d="M61.6,28.6v-16c0-2.1-1.8-3.8-3.8-3.8H3.8C1.7,8.8,0,10.5,0,12.6v16c0,2.1,1.7,3.8,3.8,3.8H16
		   c1.1,0,2.6,0.9,2.6,2.6v52.7c0,1.8,1.3,3.1,3,3.1h18.4c1.7,0,3.1-1.3,3.1-3.1V35.4c0-1.6,1.1-3,3.6-3h11.1
		   C59.9,32.3,61.6,30.6,61.6,28.6z"/>
   </g>
   <g>
	   <path fill="#FFFFFF" d="M150.5,50.2V33.1c0-1.7-1.4-3.1-3.2-3.1h-4.8c-0.6,0-2.1,0.1-2.5,0.1c-12.1,1.2-21.7,11-22.6,23.2
		   c-0.1,0.5-0.1,1.3-0.1,1.9v32.9c0,1.3,1.3,2.5,2.8,2.5h17.2c1.5,0,2.8-1.1,2.8-2.5V59.2c0-3.1,2.4-5.9,7.7-5.9
		   C150.6,53.3,150.5,52.4,150.5,50.2z"/>
   </g>
</g>
</svg>;

/* #endregion */

const PurchaseServerDialog = ({ card, openDialog, setOpenDialog, selectedProduct, getSubs, setPurchaseComplete, provideWorkItems, totalProductPrice, apiUrl, moneroActive, handleMoneroTransaction, proCustInfo, setIsPurchaseCompleteOpen }) => {

	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
        
    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    /* SEVER PLAN DIALOG STATES AND FUNCTIONS */
    
    const requestPurchase = async () => {
        const payload = selectedProduct[2].Name === 'Professional' ?  {...selectedProduct[0], PaymentType: "CC", CodeSirenPII: {...proCustInfo}} : {...selectedProduct[0], PaymentType: "CC"}
        return fetch(`${apiUrl}/Capitalism/AddNewSubscription`, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(payload)
        }).then(data => data.json())
    }

    const purchasePlan = async () => {
        const purchased = await requestPurchase();
        if(purchased.Status === 'Winning') {
            handleDialogClose();
            setIsPurchaseCompleteOpen(true);
            getSubs();
            provideWorkItems();
        }
    }


    return (
        <div>
            { card !== null && selectedProduct !== null ? (
                <Dialog open={openDialog} onClose={handleDialogClose} fullScreen={isDesktop ? false : true} maxWidth={'lg'} fullWidth >
                    <DialogTitle>
                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} >
                            Confirm Your Purchase
                            <Button component={Link} to='/products/server-plans' state={selectedProduct} variant='text' size='small' >Change product</Button>
                        </Box>
                    </DialogTitle>
                    <DialogContent sx={{pb: '0'}}>
                        <Grid container columns={3} columnSpacing={{xs: 0, lg: 4}} rowSpacing={{xs: 3, lg: 0}} >
                            <Grid xs={3} lg={1} sx={{borderRight: {xs: 'none', lg: '1px solid #fae696'}}} >
                                <Grid container columns={2} spacing={2} >
                                    <Grid xs={2} display={'flex'} justifyContent={'space-between'} alignItems={'baseline'} sx={{height: '2.25rem'}} >
                                        <Typography variant='subtitle1' >Polynom License</Typography>
                                        <Typography variant='subtitle1' color='secondary' >{selectedProduct[2].Name}</Typography>
                                    </Grid>
                                    <Grid xs={2} sm={1} lg={2} >
                                        <ListItem disablePadding >
                                            <ListItemIcon>
                                                <DevicesTwoToneIcon color={'secondary'} />
                                            </ListItemIcon>
                                            <ListItemText disableTypography={true}>
                                                <Typography variant='body1' >{selectedProduct[0].SelectedLicenseLevel}</Typography>
                                                <Typography variant='body1' sx={{opacity: '.7'}}>Concurrent devices</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </Grid>                                    
                                    <Grid xs={2} sm={1} lg={2} >
                                        <ListItem disablePadding >
                                            <ListItemIcon>
                                                <MonetizationOnTwoToneIcon color={'secondary'} />
                                            </ListItemIcon>
                                            <ListItemText disableTypography={true}>
                                                <Typography variant='body1' >{(selectedProduct[0].SelectedLicensePrice.Price / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})}{selectedProduct[0].SelectedLicensePrice.Name === 'Annual' ? '/year' : '/month'}</Typography>
                                                <Typography variant='body1' sx={{opacity: '.7'}}>License price</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </Grid>                                    
                                </Grid>
                            </Grid>
                            <Grid xs={3} display={{lg: 'none'}} >
                                <Divider />
                            </Grid>
                            <Grid xs={3} lg={1} sx={{borderRight: {xs: 'none', lg: '1px solid #fae696'}}} >
                                <Grid container columns={2} spacing={2} >
                                    <Grid xs={2} display={'flex'} justifyContent={'space-between'} alignItems={'baseline'} sx={{height: '2.25rem'}} >
                                        <Typography variant='subtitle1' >Server Plan</Typography>
                                        <Typography variant='subtitle1' color='secondary' >{selectedProduct[1].Name}</Typography>
                                    </Grid>
                                    <Grid xs={2} sm={1} lg={2} >
                                        <ListItem disablePadding >
                                            <ListItemIcon>
                                                <CalendarMonthTwoToneIcon color={'secondary'} />
                                            </ListItemIcon>
                                            <ListItemText disableTypography={true}>
                                                <Typography variant='body1' >{selectedProduct[0].SelectedPrice.Name === 'Monthly' ? 'Monthly' : 'Yearly'}</Typography>
                                                <Typography variant='body1' sx={{opacity: '.7'}}>Payment frequency</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </Grid>
                                    <Grid xs={2} sm={1} lg={2} >
                                        <ListItem disablePadding >
                                            <ListItemIcon>
                                                <MonetizationOnTwoToneIcon color={'secondary'} />
                                            </ListItemIcon>
                                            <ListItemText disableTypography={true}>
                                                <Typography variant='body1' >{(selectedProduct[0].SelectedPrice.Price / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})}</Typography>
                                                <Typography variant='body1' sx={{opacity: '.7'}}>Server plan price</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </Grid>
                                    <Grid xs={2} sm={1} lg={2} >
                                        <ListItem disablePadding >
                                            <ListItemIcon>
                                                <GroupTwoToneIcon color={'secondary'} />
                                            </ListItemIcon>
                                            <ListItemText disableTypography={true}>
                                                <Typography variant='body1' >{selectedProduct[1].EstUsers}</Typography>
                                                <Typography variant='body1' sx={{opacity: '.7'}}>Community size</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </Grid>
                                    <Grid xs={2} sm={1} lg={2} >
                                        <ListItem disablePadding >
                                            <ListItemIcon>
                                                <RecordVoiceOverTwoToneIcon color={'secondary'} />
                                            </ListItemIcon>
                                            <ListItemText disableTypography={true}>
                                                <Typography variant='body1' >{selectedProduct[1].EstVoiceUsers}</Typography>
                                                <Typography variant='body1' sx={{opacity: '.7'}}>Users in voice chat</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </Grid>
                                    <Grid xs={2} sm={1} lg={2} >
                                        <ListItem disablePadding >
                                            <ListItemIcon>
                                                <SpeedTwoToneIcon color={'secondary'} />
                                            </ListItemIcon>
                                            <ListItemText disableTypography={true}>
                                                <Typography variant='body1' >{selectedProduct[1].TransferLimitGiB} GiB per month</Typography>
                                                <Typography variant='body1' sx={{opacity: '.7'}}>Data transfer limit</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </Grid>
                                    <Grid xs={2} sm={1} lg={2} >
                                        <ListItem disablePadding >
                                            <ListItemIcon>
                                                <StorageTwoToneIcon color={'secondary'} />
                                            </ListItemIcon>
                                            <ListItemText disableTypography={true}>
                                                <Typography variant='body1' >{selectedProduct[1].BaseDiskSpaceGiB} GiB</Typography>
                                                <Typography variant='body1' sx={{opacity: '.7'}}>Server disk space</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={3} display={{lg: 'none'}} >
                                <Divider />
                            </Grid>
                            <Grid xs={3} lg={1} >
                                <Grid container columns={2} spacing={2} >
                                    <Grid xs={2} sx={{height: '2.25rem'}} >
                                        <img src={ZEPowerUpsWordmark} alt='Zero Eyes Power-ups Wordmark' className='power-ups-wm' style={{height: '1.5rem', width: 'auto', marginBottom: '1rem'}} ></img>
                                    </Grid>
                                    <Grid xs={2} sm={1} lg={2} >
                                        <ListItem disablePadding >
                                            <ListItemIcon>
                                                <AddBoxTwoToneIcon />
                                            </ListItemIcon>
                                            <ListItemText disableTypography={true}>
                                                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}} >
                                                    <Typography variant='body1' color={'secondary'} >
                                                        { selectedProduct[0].StoragePowerUp !== null ? (
                                                            `${selectedProduct[0].StoragePowerUp.ExtraStorageGiB} GiB`
                                                        ) : (
                                                            "Not activated"
                                                        )}
                                                    </Typography>
                                                    { selectedProduct[0].StoragePowerUp !== null && (
                                                        <Typography variant='caption' >
                                                            {((selectedProduct[0].StoragePowerUp.Price * selectedProduct[0].SelectedPrice.Months) / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                                                            {selectedProduct[0].SelectedPrice.Name === 'Monthly' ? '/mo' : '/yr'}
                                                        </Typography>
                                                    )}
                                                </Box>
                                                <Typography variant='body1' sx={{opacity: '.7'}}>Additional Disk Space</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </Grid>
                                    <Grid xs={2} sm={1} lg={2} >
                                        <ListItem disablePadding >
                                            <ListItemIcon>
                                                <FolderCopyTwoToneIcon />
                                            </ListItemIcon>
                                            <ListItemText disableTypography={true}>
                                                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}} >	
                                                    <Typography variant='body1' color={'secondary'} >
                                                        { selectedProduct[0].BackupsPowerUp ? (
                                                            'Activated'
                                                        ) : (
                                                            "Not activated"
                                                        )}
                                                    </Typography>
                                                    { selectedProduct[0].BackupsPowerUp && (
                                                        <Typography variant='caption' >
                                                            {((selectedProduct[1].BackupPrice * selectedProduct[0].SelectedPrice.Months) / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                                                            {selectedProduct[0].SelectedPrice.Name === 'Monthly' ? '/mo' : '/yr'}
                                                        </Typography>
                                                    )}
                                                </Box>
                                                <Typography variant='body1' sx={{opacity: '.7'}}>Server Backup</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </Grid>
                                    <Grid xs={2} sm={1} lg={2} >
                                        <ListItem disablePadding >
                                            <ListItemIcon>
                                                <SvgIcon sx={{mt: '-2px'}}>{torSVGIcon}</SvgIcon>
                                            </ListItemIcon>
                                            <ListItemText disableTypography={true}>
                                                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}} >
                                                    <Typography variant='body1' color={'secondary'} >
                                                        { selectedProduct[0].TORPowerUp ? (
                                                            'Activated'
                                                        ) : (
                                                            "Not activated"
                                                        )}
                                                    </Typography>
                                                    { selectedProduct[0].TORPowerUp && (
                                                        <Typography variant='caption' >
                                                            {((selectedProduct[1].TORPrice * selectedProduct[0].SelectedPrice.Months) / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                                                            {selectedProduct[0].SelectedPrice.Name === 'Monthly' ? '/mo' : '/yr'}
                                                        </Typography>
                                                    )}
                                                </Box>
                                                <Typography variant='body1' sx={{opacity: '.7'}}>Tor Hidden Service</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={3} sx={{display: 'flex', justifyContent: 'end'}} >
                                <Typography variant='h6' >Total price: {totalProductPrice}</Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{justifyContent: 'space-between'}}>
                        <Button onClick={handleDialogClose} >Cancel</Button>
                        <Box>
                            { moneroActive &&
                                <Button onClick={handleMoneroTransaction} >Pay with Monero</Button>
                            }
                            <Button onClick={purchasePlan} >Pay with card</Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            ) : (
                openDialog === true && <Navigate to='/products' />
            )}
        </div>

    )
}

export default PurchaseServerDialog;