import React, {useCallback, useState} from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box, Card, CardContent, Button, TextField, useTheme, useMediaQuery, ThemeProvider, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, List, ListItem, ListItemText, Tooltip, IconButton, ClickAwayListener } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import debounce from 'lodash.debounce';
import { ToastSnackbar } from '../Components';

import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';

const SignupCard = ( {xs, sm, lg, smOffset, lgOffset, hSize, hMargin, purpose, setLogin, apiUrl, isAuthenticated} ) => {

    const theme = useTheme();
    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
    
    /* #region USERNAME STATES AND FUNCTIONS */

    const [username, setUsername] = useState('');
    const [usernameHelperText, setUsernameHelperText] = useState('');
    const [confirmUsername, setConfirmUsername] = useState('');
    const [confirmUsernameHelperText, setConfirmUsernameHelperText] = useState('');
    const [usernameValid, setUsernameValid] = useState(true);
    const [usernameEqual, setUsernameEqual] = useState(true);
    const [usernameTaken, setUsernameTaken] = useState(false);

    const compareUsername = (field, value) => {
        if (( field === 'confirmUsername' && username !== value) || ( field === 'username' && value !== confirmUsername)) {
            setUsernameEqual(false);
            setUsernameHelperText('This does not match Confirm Username.');
            setConfirmUsernameHelperText('This does not match Username.');
        } else {
            setUsernameEqual(true);
            setUsernameHelperText('');
            setConfirmUsernameHelperText('');
            if (usernameTaken) {
                setUsernameHelperText('This username is taken.');
            } else if (usernameValid === false) {
                setUsernameHelperText('Usernames must begin with A-Z or a-z, can contain an underscore, and can end in 0-9.')
            }
        }
    }

    const checkUsername = async (value) => {
        return fetch(`${apiUrl}/Auth/CheckUserName`, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"UserName": value})
        }).then(data => data.json())
    }

    const informUser = debounce(async (value) => {
        if (value !== '') {
            const re = /^[a-z][a-z]+\d*$|^[a-z]\d\d+$/i;
            if (!re.test(value)) {
                setUsernameValid(false)
                setUsernameHelperText('Usernames must begin with A-Z or a-z, can contain an underscore, and can end in 0-9.')
             } else {
                setUsernameValid(true)
                const taken = await checkUsername(value)
                if (taken.Status === 'Fail') {
                    setUsernameHelperText('This username is taken.');
                    setUsernameTaken(true);
                } else if (taken.Status === 'Winning') {
                   setUsernameHelperText('');
                   setUsernameTaken(false);
                }    
             };
        } else {
            setUsernameValid(true);
            setUsernameTaken(false);
            setUsernameHelperText('');
        }
    }, 2000)

    const debounceUsernameRequest = useCallback(
        (value) => informUser(value),
        []
    )

    const usernameChange = (e) => {
        setUsername(e.target.value);        
        confirmUsername !== '' && compareUsername('username', e.target.value);
        debounceUsernameRequest(e.target.value);
    }

    const confirmUsernameChange = (e) => {
        setConfirmUsername(e.target.value);
        compareUsername('confirmUsername', e.target.value);
    }

    /* #endregion */

    /* #region PASSWORD STATES AND FUNCTIONS */

    const [password, setPassword] = useState('');
    const [passwordHelperText, setPasswordHelperText] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordHelperText, setConfirmPasswordHelperText] = useState('');
    const [passwordEqual, setPasswordEqual] = useState(true);
    const [passwordScore, setPasswordScore] = useState(null);
    const [passwordColor, setPasswordColor] = useState('secondary');

    const comparePassword = (field, value) => {
        if (( field === 'confirmPassword' && password !== value) || ( field === 'password' && value !== confirmPassword)) {
            setPasswordEqual(false);
            setPasswordHelperText('This does not match Confirm Password.');
            setConfirmPasswordHelperText('This does not match Password.');
        } else {
            setPasswordEqual(true);
            setPasswordHelperText('');
            setConfirmPasswordHelperText('');
            [0, 1, 2].indexOf(passwordScore) !== -1 && setPasswordHelperText('Add more of the following (0-9, a-z, A-Z, !@$&*).');
        }
    }

    const checkPassword = async (value) => {
        return fetch(`${apiUrl}/Auth/CheckPassStrength`, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"Password": value})
        }).then(data => data.json())
    }

    const managePasswordField = debounce(async (value) => {
        if (value !== '') {
            const strengthResults = await checkPassword(value);
            if (strengthResults.Status === 'Winning') {
                switch (strengthResults.PasswordStrength.Score) {
                    case 0:
                        setPasswordColor('error');
                        setPasswordHelperText('Very Weak: Add more of the following (0-9, a-z, A-Z, !@$&*).');
                        setPasswordScore(0);
                        break;
                    case 1:
                        setPasswordColor('error');
                        setPasswordHelperText('Weak: Add more of the following (0-9, a-z, A-Z, !@$&*).');
                        setPasswordScore(1);
                        break;
                    case 2:
                        setPasswordColor('warning');
                        setPasswordHelperText('Average: Add more of the following (0-9, a-z, A-Z, !@$&*).');
                        setPasswordScore(2);
                        break;
                    case 3:
                        setPasswordColor('success');
                        setPasswordHelperText('Strong: Very nice password.');
                        setPasswordScore(3);
                        password !== confirmPassword && setPasswordHelperText('This does not match Confirm Password.')
                        break;
                    case 4:
                        setPasswordColor('success');
                        setPasswordHelperText('Very Strong: Excellent password!');
                        setPasswordScore(4);
                        password !== confirmPassword && setPasswordHelperText('This does not match Confirm Password.')
                        break;
                    default:
                        break;
                }
            } else {
                setSignupErr(true);
                setSignupErrMsg(strengthResults.Message);
            }
        } else {
            setPasswordScore(null);
            setPasswordColor('secondary');
            setPasswordHelperText('');
        }
    }, 2000)

    const debouncePasswordRequest = useCallback(
        (value) => managePasswordField(value),
        []
    )

    const passwordChange = (e) => {
        setPassword(e.target.value);
        confirmPassword !== '' && comparePassword('password', e.target.value);
        debouncePasswordRequest(e.target.value);
    }

    const confirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        comparePassword('confirmPassword', e.target.value);
    }

    /* #endregion */

    /* #region EMAIL STATES AND FUNCTIONS */

    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [emailEqual, setEmailEqual] = useState(true);
    const [emailValid, setEmailValid] = useState(true);
    const [emailHelperText, setEmailHelperText] = useState('Email is optional.');
    const [confirmEmailHelperText, setConfirmEmailHelperText] = useState('Email is optional.');
    const [emailInfoTooltip, setEmailInfoTooltip] = useState(false);

    const compareEmail = (field, value) => {
        if (( field === 'confirmEmail' && email !== value) || ( field === 'email' && value !== confirmEmail)) {
            setEmailEqual(false);
            setEmailHelperText('This does not match Confirm Email.');
            setConfirmEmailHelperText('This does not match Email.');
        } else {
            setEmailEqual(true);
            setEmailHelperText('Email is optional.');
            setConfirmEmailHelperText('Email is optional.');
            emailValid === false && setEmailHelperText('This is not a valid email.');
        }
    }

    const checkEmail = async (value) => {
        return fetch(`${apiUrl}/Auth/CheckEmail`, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"Email": value})
        }).then(data => data.json())
    }

    const provideEmailCheck = debounce(async (value) => {
        if (value !== '') {
            const valid = await checkEmail(value);
            if(valid.Status === 'Winning') {
                setEmailValid(true);
                setEmailHelperText('Email is optional.');
            } else if (valid.Status === 'Fail') {
                setEmailValid(false);
                setEmailHelperText('This is not a valid email.');
            } 
        } else {
            setEmailValid(true);
            setEmailHelperText('Email is optional.');
        }
    }, 2000)

    const debounceEmailRequest = useCallback(
        (value) => provideEmailCheck(value),
        []
    )

    const emailChange = (e) => {
        setEmail(e.target.value);
        confirmEmail !== '' && compareEmail('email', e.target.value);
        debounceEmailRequest(e.target.value);
    }

    const confirmEmailChange = (e) => {
        setConfirmEmail(e.target.value);
        compareEmail('confirmEmail', e.target.value);
    }

    /* #endregion */

    /* #region SIGNUP SUBMIT FUNCTIONS */

    const [signupErr, setSignupErr] = useState(false);
    const [signupErrMsg, setSignupErrMsg] = useState('');
  
    const signupUser = async (userDetails) => {
      return fetch(`${apiUrl}/Auth/Register`, {
        method: 'POST',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(userDetails)
      }).then(data => data.json())
    }
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      let token;
        if ((usernameEqual && usernameValid && !usernameTaken) && (passwordEqual && (passwordScore === 3 || passwordScore === 4)) && emailEqual && emailValid) {
            if (email === '') {
                token = await signupUser({"UserName": username, "Password": password});
            } else {
                token = await signupUser({"UserName": username, "Password": password, "Email": email});
            }

            if(token.Status === 'Winning') {
                window.location.pathname === '/checkout' ? (
                    isAuthenticated()
                ) : (
                    window.location.reload(false)
                )
            } else {
                setSignupErr(true);
                setSignupErrMsg(token.Message);
            }

        } else {
            setOpen(true);
        }
    }

    /* #endregion */

    /* #region ALERT DIALOG STATES AND FUNCTIONS */

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    /* #endregion */

    return (
        <ThemeProvider theme={theme}>
            <Grid xs={xs} sm={sm} lg={lg} smOffset={smOffset} lgOffset={lgOffset} display={'flex'} flexDirection={'column'} justifyContent={'center'} >
                <Card sx={{ pb: {lg: '16px'} }} >
                    <CardContent>
                        <Typography variant={hSize} color={'secondary'} margin={{ xs: `${hMargin[0]}rem 0`, lg: `${hMargin[1]}rem 0` }} >SIGNUP</Typography>
                        <Box component={'form'} onSubmit={(e) => handleSubmit(e)} >
                            <Grid container columns={{ xs: 1, lg: 8 }} columnSpacing={{lg: 3}} >
                                <Grid xs={1} lg={3} lgOffset={1} >
                                    <TextField variant='filled' label='Username' value={username} onChange={(e) => usernameChange(e)} error={!usernameEqual || usernameTaken || !usernameValid} helperText={usernameHelperText}  color='secondary' margin='normal' type='text' sx={{ width: '100%' }} autoFocus={true} inputProps={{minlength: '4'}} required />
                                </Grid>
                                <Grid xs={1} lg={3} >
                                    <TextField variant='filled' label='Confirm Username' value={confirmUsername} onChange={(e) => confirmUsernameChange(e)} error={!usernameEqual} helperText={confirmUsernameHelperText} color='secondary' margin='normal' type='text' sx={{ width: '100%' }} required />
                                </Grid>
                                <Grid xs={1} lg={3} lgOffset={1} >
                                    <TextField variant='filled' label='Password' value={password} onChange={(e) => passwordChange(e)} error={!passwordEqual || [0, 1, 2].indexOf(passwordScore) !== -1} helperText={passwordHelperText} color={passwordColor} margin='normal' type='password' sx={{ width: '100%' }} required />
                                </Grid>
                                <Grid xs={1} lg={3} >
                                    <TextField variant='filled' label='Confirm Password' value={confirmPassword} onChange={(e) => confirmPasswordChange(e)} error={!passwordEqual} helperText={confirmPasswordHelperText} color='secondary' margin='normal' type='password' sx={{ width: '100%' }} required />
                                </Grid>
                                <Grid xs={1} lg={3} lgOffset={1} >
                                    <TextField variant='filled' label='Email' InputProps={{
                                        endAdornment: <ClickAwayListener onClickAway={() => setEmailInfoTooltip(false)} >
                                                <div>
                                                    <Tooltip title='Your email will be used for account recovery and notifications. Without an email, we cannot recover your account if you fail to log in.' arrow PopperProps={{disablePortal: true,}} onClose={() => setEmailInfoTooltip(false)} open={emailInfoTooltip} disableFocusListener disableHoverListener disableTouchListener >
                                                        <IconButton tabIndex={-1} onClick={() => setEmailInfoTooltip(!emailInfoTooltip)} >
                                                            <InfoTwoToneIcon color='secondary' />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </ClickAwayListener>
                                    }} value={email} onChange={(e) => emailChange(e)} error={!emailValid || !emailEqual} helperText={emailHelperText} color='secondary' margin='normal' type='text' sx={{ width: '100%' }} />
                                </Grid>
                                {
                                    (email !== '' || isDesktop) && <Grid xs={1} lg={3} ><TextField variant='filled' label='Confirm Email' value={confirmEmail} onChange={(e) => confirmEmailChange(e)} error={!emailEqual} helperText={confirmEmailHelperText} color='secondary' margin='normal' type='text' sx={{ width: '100%', mt: {xs: '0', lg: '16px'} }} /></Grid>
                                }
                                <Grid xs={1} lg={6} lgOffset={1} >
                                    <Button variant='contained' disableElevation={true} fullWidth={true} type='submit' sx={{ marginTop: '0px', maxWidth: 'none', mb: { lg: '16px' } }} >Sign up</Button>
                                </Grid>
                                <Grid xs={1} lg={3} lgOffset={1} display={'flex'} alignItems={'center'} >
                                    <Typography variant='body1' sx={{ margin: {xs: '1rem', lg: 'initial'}, textAlign: {lg: 'left'}, width: '100%' }} >Already have an account?</Typography>
                                </Grid>
                                <Grid xs={1} lg={3} >
                                    { purpose === 'link' ? (
                                        <Button component={Link} to='/login' variant='outlined' fullWidth={true} sx={{ borderWidth: '2px', maxWidth: 'none' }}>Log in</Button>
                                    ) : (
                                        <Button onClick={() => setLogin(true)} variant='outlined' fullWidth={true} sx={{ borderWidth: '2px', maxWidth: 'none' }}>Log in</Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
                <Dialog open={open} onClose={handleClose} >
                    
                    <DialogTitle>
                        Requirements Not Met
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <List>
                                {
                                    !usernameEqual && <ListItem disableGutters ><ListItemText>The Username and Confirm Username fields do not match.</ListItemText></ListItem>
                                }
                                {
                                    !usernameValid && <ListItem disableGutters ><ListItemText>Usernames can only contain A-Z, a-z, 0-9, and underscore. Usernames must be one word.</ListItemText></ListItem>
                                }
                                {
                                    usernameTaken && <ListItem disableGutters ><ListItemText>Your given Username has been taken by an existing user.</ListItemText></ListItem>
                                }
                                {
                                    !passwordEqual && <ListItem disableGutters ><ListItemText>The Password and Confirm Password fields do not match.</ListItemText></ListItem>
                                }
                                {
                                    (passwordScore === 0 || passwordScore === 1 || passwordScore === 2) && <ListItem disableGutters ><ListItemText>Your password is not strong enough. Please add more characters to increase its strength.</ListItemText></ListItem>
                                }
                                {
                                    !emailEqual && <ListItem disableGutters ><ListItemText>The Email and Confirm Email fields do not match.</ListItemText></ListItem>
                                }
                                {
                                    !emailValid && <ListItem disableGutters ><ListItemText>The email given does not match the formatting of a standard email.</ListItemText></ListItem>
                                }
                            </List>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} >Close</Button>
                    </DialogActions>
                </Dialog>
                <ToastSnackbar alertSeverity='error' alertTitle='Sign Up Failed' openSnackbar={signupErr} setOpenSnackbar={setSignupErr} snackbarDescription={signupErrMsg} />
            </Grid>
        </ThemeProvider>
    

    )
}

export default SignupCard;