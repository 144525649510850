import React, { useState, useCallback } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormControl, FilledInput, InputLabel, InputAdornment, Popover, Typography, IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import debounce from 'lodash.debounce';
import { ToastSnackbar } from '../Components';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';

const ProfileInfoDialog = ({ openEditProfile, setStateClose, handleDialogClose, getAuth, setProfileInfoUpdated, apiUrl }) => {

    /* USERNAME STATES AND FUNCTIONS */

    const [username, setUsername] = useState(localStorage.getItem('username'));
    const [usernameValid, setUsernameValid] = useState(true);
    const [usernameHelperText, setUsernameHelperText] = useState('');

    const checkUsername = async (value) => {
        return fetch(`${apiUrl}/Auth/CheckUserName`, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"UserName": value})
        }).then(data => data.json())
    }

    const informUser = debounce(async (value) => {
        if(value.length < 4) {
        setUsernameHelperText('Usernames must be at least four characters long.');
        setUsernameValid(false);
        } else if(value.toLowerCase() !== localStorage.getItem('username')) {
        const taken = await checkUsername(value)
        if (taken.Status === 'Fail') {
            setUsernameHelperText('This username is taken.');
            setUsernameValid(false);
        } else if (taken.Status === 'Winning') {
            setUsernameHelperText('');
            setUsernameValid(true);
        }
        } else {
        setUsernameHelperText('');
        setUsernameValid(true);
        }
    }, 2000)

    const debounceUsernameRequest = useCallback(
        (value) => informUser(value),
        []
    )

    const usernameChange = (e) => {
        setUsername(e.target.value);
        debounceUsernameRequest(e.target.value);
    }

    const submitUsername = async () => {
        return fetch(`${apiUrl}/Auth/UpdateUserName`, {
        method: 'POST',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({"UserName": username})
        }).then(data => data.json())
    }

    /* EMAIL STATES AND FUNCTIONS */

    const [email, setEmail] = useState(localStorage.getItem('email'));
    const [emailValid, setEmailValid] = useState(true);
    const [emailHelperText, setEmailHelperText] = useState('');

    const checkEmail = async (value) => {
        return fetch(`${apiUrl}/Auth/CheckEmail`, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"Email": value})
        }).then(data => data.json())
    }

    const provideEmailCheck = debounce(async (value) => {
        if (value !== '') {
        const valid = await checkEmail(value);
        if(valid.Status === 'Winning') {
            setEmailValid(true);
            setEmailHelperText('');
        } else if (valid.Status === 'Fail') {
            setEmailValid(false);
            setEmailHelperText('This is not a valid email.');
        } 
        } else {
        setEmailValid(true);
        setEmailHelperText('');
        }
    }, 2000)

    const debounceEmailRequest = useCallback(
        (value) => provideEmailCheck(value),
        []
    )

    const emailChange = (e) => {
        setEmail(e.target.value);
        debounceEmailRequest(e.target.value);
    }

    const submitEmail = async () => {
        return fetch(`${apiUrl}/Auth/UpdateEmail`, {
        method: 'POST',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({"Email": email})
        }).then(data => data.json())
    }

    /* POLYNOM IDENTITY STATES AND FUNCTIONS */

    const [polyId, setPolyId] = useState(localStorage.getItem('polyID'));

    const submitPolyId = async () => {
        return fetch(`${apiUrl}/Auth/UpdateIdent`, {
        method: 'POST',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({"Ident": polyId})
        }).then(data => data.json())
    }

    /* POLYNOM IDENTITY POPOVER VAIABLES, STATES, AND FUNCTIONS */

    const [popoverAnchor, setPopoverAnchor] = useState(null);

    const popoverOpen = Boolean(popoverAnchor);
    const popoverId = popoverAnchor ? 'simple-popover' : undefined;


    const handlePopoverOpen = (event) => {
        setPopoverAnchor(event.currentTarget);
    }

    const handlePopoverClose = () => {
        setPopoverAnchor(null);
    }

    /* PASSWORD STATES AND FUNCTIONS */

    const [password, setPassword] = useState('');
    const [passwordScore, setPasswordScore] = useState(null);
    const [passwordColor, setPasswordColor] = useState('secondary');
    const [passwordHelperText, setPasswordHelperText] = useState('');
    const [passwordEqual, setPasswordEqual] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState('');

    const checkPassword = async (value) => {
        return fetch(`${apiUrl}/Auth/CheckPassStrength`, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"Password": value})
        }).then(data => data.json())
    }

    const providePasswordCheck = debounce(async (value) => {
        const strength = await checkPassword(value);
        if (strength.Status === 'Winning') {
        switch (strength.PasswordStrength.Score) {
            case 0:
                setPasswordColor('error');
                setPasswordHelperText('Very Weak: Add more of the following (0-9, a-z, A-Z, !@$&*).');
                setPasswordScore(0);
                break;
            case 1:
                setPasswordColor('error');
                setPasswordHelperText('Weak: Add more of the following (0-9, a-z, A-Z, !@$&*).');
                setPasswordScore(1);
                break;
            case 2:
                setPasswordColor('warning');
                setPasswordHelperText('Average: Add more of the following (0-9, a-z, A-Z, !@$&*).');
                setPasswordScore(2);
                break;
            case 3:
                setPasswordColor('success');
                setPasswordHelperText('Strong: Very nice password.');
                setPasswordScore(3);
                break;
            case 4:
                setPasswordColor('success');
                setPasswordHelperText('Very Strong: Excellent password!');
                setPasswordScore(4);
                break;
            default:
                break;
        }
        }
    }, 2000)

    const debouncePasswordRequest = useCallback(
        (value) => providePasswordCheck(value),
        []
    )

    const passwordChange = (e) => {
        setPassword(e.target.value);
        debouncePasswordRequest(e.target.value);
    }

    const comparePasswords = (e) => {
        if(e.target.value === password) {
        setPasswordEqual(true);
        } else {
        setPasswordEqual(false);
        }
    }

    const submitPassword = async () => {
        return fetch(`${apiUrl}/Auth/UpdatePassword`, {
        method: 'POST',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({"Password": password})
        }).then(data => data.json())
    }

    /* FORM SUBMISSION FUNCTION AND STATES */

    const [updateProfileErr, setUpdateProfileErr] = useState(false);
    const [fullErrMsg, setFullErrMsg] = useState('');

    const editProfileInfo = async () => {
        let i = 0;
        let updateUsernameErrMsg = '';
        let updateEmailErrMsg = '';
        let updatePolyIdErrMsg = '';
        let updatePasswordErrMsg = '';

        if (username.toLowerCase() !== localStorage.getItem('username')) {
            const usernameSubmitted  = await submitUsername();
            if (usernameSubmitted.Status === 'Winning') {
                i++;
            } else {
                i--;
                updateUsernameErrMsg = usernameSubmitted.Message;
            }
        } else {
            i++;
        }

        if (password !== '' && passwordEqual === true && (passwordScore === 3 || passwordScore === 4)) {
            const passwordSubmitted = await submitPassword();
            if (passwordSubmitted.Status === 'Winning') {
                i++;
            } else {
                i--;
                updatePasswordErrMsg = passwordSubmitted.Message;
            }
        } else if (password === '') {
            i++;
        }

        if (emailValid === true && email !== localStorage.getItem('email')) {
            const emailSubmitted = await submitEmail();
            if (emailSubmitted.Status === 'Winning') {
                i++;
            } else {
                i--;
                updateEmailErrMsg = emailSubmitted.Message;
            }
        } else if (email === localStorage.getItem('email')) {
            i++
        }

        if (polyId !== localStorage.getItem('polyID')) {
            const polyIdSubmitted = await submitPolyId();
            if (polyIdSubmitted.Status === 'Winning') {
                i++;
            } else {
                i--;
                updatePolyIdErrMsg = polyIdSubmitted.Message;
            }
        } else {
            i++;
        }

        if (i === 4) {
            setStateClose !== true ? handleDialogClose('profile') : handleDialogClose(false) ;
            getAuth();
            setProfileInfoUpdated(true);
            setPassword('');
            setConfirmPassword('');
        } else {
            setUpdateProfileErr(true);
            setFullErrMsg(`${updateUsernameErrMsg} ${updateEmailErrMsg} ${updatePolyIdErrMsg} ${updatePasswordErrMsg}`);
        }
    }

    return (
        <Dialog open={openEditProfile} onClose={() => setStateClose !== true ? handleDialogClose('profile') : handleDialogClose(false)} >
            <DialogTitle>Edit Profile Information</DialogTitle>
            <DialogContent>
                <Grid container columns={2} spacing={{lg: 3}} >
                    <Grid xs={2} lg={1}>
                    <TextField  variant='filled' label='Username' value={username} onChange={(e) => usernameChange(e)} error={!usernameValid} helperText={usernameHelperText} color='secondary' margin='normal' type='text' sx={{width: '100%'}} required></TextField>
                    </Grid>
                    <Grid xs={2} lg={1}>
                    <TextField  variant='filled' label='Email' value={email === 'null' ? '' : email} onChange={(e) => emailChange(e)} error={!emailValid} helperText={emailHelperText} color='secondary' margin='normal' type='text' sx={{width: '100%'}} required></TextField>
                    </Grid>
                    <Grid xs={2} lg={1}>
                    <FormControl variant='filled' color='secondary' margin='normal' sx={{width: '100%'}} >
                        <InputLabel>Polynom Identity</InputLabel>
                        <FilledInput label='Polynom Identitiy' value={polyId === 'null' ? '' : polyId} onChange={(e) => setPolyId(e.target.value)} type='text' required 
                        endAdornment={
                        <InputAdornment position='end' >
                            <IconButton onClick={handlePopoverOpen} edge='end' >
                                <InfoTwoToneIcon color='secondary' />
                            </IconButton>
                            <Popover id={popoverId} open={popoverOpen} anchorEl={popoverAnchor} onClose={handlePopoverClose} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
                            <Typography sx={{ p: 2 }}>Can be copied from Polynom's manage identity settings.<br/><br/>The Polynom user with this ID can make Zero Eyes account decisions when speaking with Zero Eyes staff on Polynom.</Typography>
                            </Popover>
                        </InputAdornment>
                        } />
                    </FormControl>
                    </Grid>
                    <Grid xs={2} lg={1}>
                    <TextField  variant='filled' label='Password' value={password} onChange={(e) => passwordChange(e)} onBlur={(e) => e.target.value === '' && setConfirmPassword('')} error={passwordScore === 0 || passwordScore === 1 || passwordScore === 2} helperText={passwordHelperText} color={passwordColor} margin='normal' type='password' sx={{width: '100%'}} required></TextField>
                    </Grid>
                    { password !== ''&& (
                    <Grid xs={2} lg={1}>
                        <TextField  variant='filled' label='Confirm Password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} onBlur={e => comparePasswords(e)} error={passwordEqual !== null && !passwordEqual} helperText={passwordEqual === false ? 'Does not match the password.' : ''} color='secondary' margin='normal' type='password' sx={{width: '100%'}} required></TextField>
                    </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setStateClose !== true ? handleDialogClose('profile') : handleDialogClose(false)}>Cancel</Button>
                <Button onClick={() => editProfileInfo()}>Update</Button>
            </DialogActions>
            <ToastSnackbar alertSeverity='error' alertTitle='Update Failed' openSnackbar={updateProfileErr} setOpenSnackbar={setUpdateProfileErr} snackbarDescription={fullErrMsg} />
        </Dialog>
    )
}

export default ProfileInfoDialog