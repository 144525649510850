import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Card, CardContent, Button, TextField, Accordion, AccordionSummary, AccordionDetails, MenuItem, SvgIcon, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import { HouseRulesDialog } from '../Components';
import { countryCodes } from '../utils/constants';

import RadioButtonCheckedTwoToneIcon from '@mui/icons-material/RadioButtonCheckedTwoTone';
import RadioButtonUncheckedTwoToneIcon from '@mui/icons-material/RadioButtonUncheckedTwoTone';
import CreditCardTwoToneIcon from '@mui/icons-material/CreditCardTwoTone';

const PaymentInformationCard = ({ auth, isAuthenticated, selectedProduct, totalProductPrice, agreedToHouseRules, setAgreed, houseRulesList, checked, setChecked, setRulesErr, setTransactionErr, setTransErrMsg, xs, sm, lg, smOffset, lgOffset, squareEnv, squareAppId, squareLocationId, apiUrl, handleMoneroTransaction, moneroActive, expanded, setExpanded, customerType, proCustInfo, setCustomerInfoErr }) => {

	const navigate = useNavigate();

	/* HOUSE RULES DIALOG STATES AND FUNCTIONS */

	const [openDialog, setOpenDialog] = useState(false);
	const [buttonDisable, setButtonDisable] = useState(true);

	const handleHouseRulesClose = () => {
		setOpenDialog(false);
		setButtonDisable(true);
		const tempChecked = {};
		houseRulesList.map(( rule, index) => {
			tempChecked[index] = false;
		})
		tempChecked[houseRulesList.length] = false;
		setChecked(tempChecked);
	}

	/* #region Payment States and Functions */

	const [nameOnCard, setNameOnCard] = useState('');
	const [nameErr, setNameErr] = useState(false);
	const [address1, setAddress1] = useState('');
	const [add1Err, setAdd1Err] = useState(false);
	const [address2, setAddress2] = useState('');
	const [add2Err, setAdd2Err] = useState(false);
	const [city, setCity] = useState('');
	const [cityErr, setCityErr] = useState(false);
	const [st, setSt] = useState('');
	const [stErr, setStErr] = useState(false);
	const [zipCode, setZipCode] = useState('');
	const [zipErr, setZipErr] = useState(false);
	const [country, setCountry] = useState('US');
	const [countryErr, setCountryErr] = useState(false);

	const lengthCheck = (e, field) => {
		switch (field) {
			case 'name':
				e.target.value.length < 5 ? setNameErr(true) : setNameErr(false);
				break;
			case 'add1':
				e.target.value.length < 5 ? setAdd1Err(true) : setAdd1Err(false);
				break;
			case 'add2':
				e.target.value.length === 1 ? setAdd2Err(true) : setAdd2Err(false);
				break;
			case 'city':
				e.target.value.length < 2 ? setCityErr(true) : setCityErr(false);
				break;
			case 'st':
				e.target.value.length < 2 ? setStErr(true) : setStErr(false);
				break;
			case 'zip':
				e.target.value.length < 2 ? setZipErr(true) : setZipErr(false);
				break;
			case 'country':
				e.target.value.length < 2 ? setCountryErr(true) : setCountryErr(false);
				break;
			default:
				break;
		}
	}

	/* ACCORDION STATES AND FUNCTIONS */

	const [paymentType, setPaymentType] = useState(null);

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
		setPaymentType(panel);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
	}

	/* #endregion */
	
	

	return (
		<Grid xs={xs} sm={sm} lg={lg} smOffset={smOffset} lgOffset={lgOffset} >
			<Card sx={{ mb: '32px' }} >
				<CardContent sx={{ p: '16px !important'}} >
					<Grid container columns={{ xs: 1, lg: 8 }} columnSpacing={{ lg: 2 }} alignItems={'center'} >
						<Grid xs={1} lg={8} textAlign={'left'} >
							<Typography variant={'h5'} >Payment Information</Typography>
						</Grid>
						<Grid xs={1} lg={8} textAlign={'left'} >
							{moneroActive ? <Typography variant={'body1'} >Select whether you would like to pay with a card or Monero.</Typography> : <Typography variant={'body1'} >Only card payments are available at this time. Check back later for Monero payments.</Typography> }
						</Grid>
						<Grid xs={1} lg={8} textAlign={'left'} >
							<Accordion expanded={expanded === 'card'} onChange={handleChange('card')} sx={{ background: 'none', pb: '8px' }} elevation={0} disableGutters square >
								<AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{ p: '0', mt: '1rem' }} >
									{ expanded === 'card' ? (
										<RadioButtonCheckedTwoToneIcon color='primary' />
									) : (
										<RadioButtonUncheckedTwoToneIcon />
									)}
									<Typography marginLeft={'16px'} >Card</Typography>
									<CreditCardTwoToneIcon sx={{ marginLeft: 'auto' }} />
								</AccordionSummary>
								<AccordionDetails sx={{ p: '0 0 8px 0' }} >

									<Box component={'form'} onSubmit={(e) => handleSubmit(e)} sx={{ mb: '1rem' }} >
										<Grid container columns={{ xs: 2, lg: 8 }} columnSpacing={{ lg: 3 }} >
											<Grid xs={2} lg={4} >
												<TextField variant='filled' label='Name on card' value={nameOnCard} onChange={(e) => setNameOnCard(e.target.value)} color='secondary' margin='normal' type='text' sx={{ width: '100%' }} onBlur={(e) => lengthCheck(e, 'name')} error={nameErr} helperText={nameErr && 'More than four letters are required.'} required />
											</Grid>
											<Grid xs={2} lg={4} >
												<TextField variant='filled' label='Address line 1' value={address1} onChange={(e) => setAddress1(e.target.value)} color='secondary' margin='normal' type='text' sx={{ width: '100%' }} onBlur={(e) => lengthCheck(e, 'add1')} error={add1Err} helperText={add1Err && 'More than four characters are required.'} required />
											</Grid>
											<Grid xs={2} lg={4} >
												<TextField variant='filled' label='Address line 2 (optional)' value={address2} onChange={(e) => setAddress2(e.target.value)} color='secondary' margin='normal' type='text' sx={{ width: '100%' }} onBlur={(e) => lengthCheck(e, 'add2')} error={add2Err} helperText={add2Err && 'More than one character is required.'} />
											</Grid>
											<Grid xs={1} lg={4} sx={{ pr: { xs: '8px' } }} >
												<TextField variant='filled' label='City' value={city} onChange={(e) => setCity(e.target.value)} color='secondary' margin='normal' type='text' sx={{ width: '100%' }} onBlur={(e) => lengthCheck(e, 'city')} error={cityErr} helperText={cityErr && 'More than one letter is required.'} required />
											</Grid>
											<Grid xs={1} lg={4} sx={{ pl: { xs: '8px' } }} >
												<TextField variant='filled' label='State' value={st} onChange={(e) => setSt(e.target.value)} color='secondary' margin='normal' type='text' sx={{ width: '100%' }} onBlur={(e) => lengthCheck(e, 'st')} error={stErr} helperText={stErr && 'More than one letter is required.'} required />
											</Grid>
											<Grid xs={1} lg={4} sx={{ pr: { xs: '8px' } }} >
												<TextField variant='filled' label='Postal code' value={zipCode} onChange={(e) => setZipCode(e.target.value)} color='secondary' margin='normal' type='text' sx={{ width: '100%' }} onBlur={(e) => lengthCheck(e, 'zip')} error={zipErr} helperText={zipErr && 'More than one character is required.'} required />
											</Grid>
											<Grid xs={1} lg={4} sx={{ pl: { xs: '8px' } }} >
												<TextField variant='filled' label='Country' value={country} onChange={(e) => setCountry(e.target.value)} color='secondary' margin='normal' type='text' sx={{ width: '100%' }} select onBlur={(e) => lengthCheck(e, 'country')} error={countryErr} helperText={countryErr && 'The field is required.'} required>
													{countryCodes.map((option) => (
														<MenuItem key={option.value} value={option.value}>
															{option.label}
														</MenuItem>
													))}
												</TextField>
											</Grid>
											{ squareEnv === 'dev' && (
												<Grid xs={2} lg={8} sx={{textAlign: 'center', mt: '1rem'}} >
													<Typography variant='h4' color='error' >SQUARE IS IN DEV MODE</Typography>
												</Grid>
											)}
										</Grid>
									</Box>

									{agreedToHouseRules === true ? (
										<PaymentForm
											applicationId={squareAppId}
											cardTokenizeResponseReceived={async (token, verifiedBuyer) => {
												const payload = selectedProduct[2].Name === 'Professional' ?  {...selectedProduct[0], PaymentType: "CC", CodeSirenPII: {...proCustInfo}} : {...selectedProduct[0], PaymentType: "CC"}
												const companyValueCheck = Object.values(proCustInfo).find((element, index) => index !== 5 ? element.length < 2 : element.length === 1);
												if (nameOnCard.length > 4 && address1.length > 4 && (address2.length > 1 || address2 === '') && city.length > 1 && st.length > 1 && zipCode.length > 1 && country.length > 1) {
													if ((selectedProduct[2].Name === 'Professional' && (proCustInfo.Name.length < 2 || proCustInfo.Email.length < 2)) || (customerType === 'company' && companyValueCheck !== undefined)) {
														setCustomerInfoErr(true);
													} else {
														const cardCreated = await fetch(`${apiUrl}/Capitalism/UpdateSquareCardInfo`, {
															method: 'POST',
															credentials: 'include',
															headers: { 'Content-Type': 'application/json' },
															body: JSON.stringify({
																"CardToken": token.token,
																"CardName": nameOnCard,
																"CardAddress1": address1,
																"CardAddress2": address2,
																"CardCity": city,
																"CardState": st,
																"CardCountry": country,
																"CardPostalCode": zipCode,
															})
														}).then(data => data.json());
														if (cardCreated.Status === 'Winning') {
															const response = await fetch(`${apiUrl}/Capitalism/AddNewSubscription`, {
																method: 'POST',
																credentials: 'include',
																headers: { 'Content-Type': 'application/json' },
																body: JSON.stringify(payload)
															}).then(data => data.json());
															if (response.Status === 'Winning') {
																navigate('/dashboard', {state: {newPurchase: true}});
															} else {
																setTransactionErr(true);
																setTransErrMsg(response.Message);
															}
														} else {
															setTransactionErr(true);
															setTransErrMsg(cardCreated.Message);
														}
													}
												} else {
													setTransactionErr(true);
													setTransErrMsg('Please fix all errors with the payment information fields.');
												}
											}}
											locationId={squareLocationId}
										>
											<CreditCard
												buttonProps={{
													css: {
														backgroundColor: '#a2d4fa',
														color: '#000000',
														fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
														fontWeight: '500',
														borderRadius: '4px',
														"&:hover": {
															backgroundColor: '#2b587a',
														},
														"&:active": {
															backgroundColor: '#a2d4fa',
														}
													}
												}}
												style={{
													'.input-container': {
														borderRadius: '4px',
														borderWidth: '1px'
													},
													'.input-container.is-error': {
														borderColor: '#FABDBB'
													},
													'.input-container.is-focus': {
														borderColor: '#FAE696'
													},
													'.message-icon': {
														color: 'white'
													},
													'.message-icon.is-error': {
														color: '#FABDBB'
													},
													'.message-text': {
														color: 'white'
													},
													'.message-text.is-error': {
														color: '#FABDBB'
													},
													input: {
														backgroundColor: 'rgba(255, 255, 255, 0.09)',
														color: 'white',
														fontFamily: 'Helvetica Neue'
													},
													'input::placeholder': {
														color: 'rgba(255, 255, 255, .7)'
													},
													'input.is-error': {
														color: '#FABDBB'
													},
													'input.is-error::placeholder': {
														color: 'rgba(255, 255, 255, .7)'
													}
												}} focus='' >Pay {totalProductPrice}{selectedProduct[0].SelectedPrice.Name === 'Monthly' ? '/month' : '/year'}</CreditCard>
										</PaymentForm>
									) : (
										<Grid container columns={{ xs: 2, lg: 8 }}>
											<Grid xs={2} lg={8} >
												{auth ? <Typography variant='subtitle1'>House Rules</Typography> : <Typography variant='subtitle1'>Log In or Sign Up</Typography>}
											</Grid>
											<Grid xs={2} lg={8} >
												{auth ? <Typography variant='body1'>Before you add you card information and complete your purchase, you must agree to the house rules.</Typography> : <Typography variant='body1'>Before continuing, you must log in or sign up using the form above.</Typography>}
											</Grid>
											<Grid xs={2} lg={8} pt='.5rem' >
												{auth && <Button variant='contained' color='primary' fullWidth={true} disableElevation={true} onClick={() => houseRulesList !== 'failed' ? setOpenDialog(true) : setRulesErr(true)} >View house rules</Button>}
											</Grid>
										</Grid>
									)}

								</AccordionDetails>
							</Accordion>
							{expanded === 'monero' && <Divider />}
							{ moneroActive && (	
								<Accordion expanded={expanded === 'monero'} onChange={handleChange('monero')} sx={{ background: 'none' }} elevation={0} disableGutters square >
									<AccordionSummary aria-controls="panel2d-content" id="panel2d-header" sx={{ p: '8px 0 0 0' }} >
										{ expanded === 'monero' ? (
											<RadioButtonCheckedTwoToneIcon color='primary' />
										) : (
											<RadioButtonUncheckedTwoToneIcon />
										)}
										<Typography marginLeft={'16px'} >Monero</Typography>
										<SvgIcon sx={{ marginLeft: 'auto' }} >
											<svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3755.67 3756.49"><g id="c"><path d="m1877.78.25c1037,0,1879.7,840.75,1877.89,1877.76-.36,207.26-33.25,406.63-95.37,593.12h-561.96V891.23l-1220.56,1220.58L657.23,891.23v1579.9H95.34C33.31,2284.64.26,2085.27,0,1878.01-1.31,842.06,840.91,0,1877.81,0l-.02.25Zm-280.66,2392.17l-532.67-532.7v994.14h-407.25l-384.29.07c329.63,540.8,925.35,902.56,1604.91,902.56s1275.31-361.85,1604.97-902.65h-384.45s-364.25.01-364.25.01h-43.03v-994.14l-532.7,532.7-280.61,280.61-280.62-280.61h-.02Z" fill="#fff" strokeWidth="0px" /></g></svg>
										</SvgIcon>
									</AccordionSummary>
									<AccordionDetails sx={{ p: '0 0 16px 0' }} >
										{ auth && agreedToHouseRules ? (
											<Grid container columns={{xs: 2, sm: 4}} rowSpacing={{xs: 2, lg: 3}} >
												<Grid xs={2} sm={4} >
													<Typography variant='body1' >Clicking continue will open a dialog box with instructions for completing your purchase using your Monero wallet.</Typography>
												</Grid>
												<Grid xs={2} sm={4} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}} >
													<Typography variant='h6' >Total Price</Typography>
													<Box>
														<Typography variant='h5' sx={{display: 'inline-block'}} >
															{totalProductPrice}
														</Typography>
														<Typography variant='body1' sx={{display: 'inline-block'}} >{selectedProduct[0].SelectedPrice.Name === 'Monthly' ? '/month' : '/year'}</Typography>
													</Box>
												</Grid>
												<Grid xs={2} sm={4} pt='.5rem' >
													<Button variant='contained' color='primary' onClick={handleMoneroTransaction} fullWidth disableElevation >Continue with Monero</Button>
												</Grid>
											</Grid>
										): (
											!auth ? (
												<Grid container columns={{xs: 2, sm: 4}}>
													<Grid xs={2} sm={4} >
														<Typography variant='subtitle1'>Log In or Sign Up</Typography>
													</Grid>
													<Grid xs={2} sm={4} >
														<Typography variant='body1'>Before continuing, you must log in or sign up using the form above.</Typography>
													</Grid>
												</Grid>
											) : (
												<Grid container columns={{xs: 2, sm: 4}}>
													<Grid xs={2} sm={4}>
														<Typography variant='subtitle1'>House Rules</Typography>
													</Grid>
													<Grid xs={2} sm={4} >
														<Typography variant='body1'>Before continuing with your purchase, you must agree to the house rules.</Typography>
													</Grid>
													<Grid xs={2} sm={4} pt='.5rem' >
														{auth && <Button variant='contained' color='primary' fullWidth={true} disableElevation={true} onClick={() => houseRulesList !== 'failed' ? setOpenDialog(true) : setRulesErr(true)} >View house rules</Button>}
													</Grid>
												</Grid>
											)
										)}
									</AccordionDetails>
								</Accordion>
							)}
						</Grid>
					</Grid>
				</CardContent>
				<HouseRulesDialog open={openDialog} handleHouseRulesClose={handleHouseRulesClose} buttonDisable={buttonDisable} setButtonDisable={setButtonDisable} checked={checked} setChecked={setChecked} isAuthenticated={isAuthenticated} setAgreed={setAgreed} from='checkout' houseRulesList={houseRulesList} apiUrl={apiUrl} />
			</Card>
		</Grid>
	)
}

export default PaymentInformationCard;