import React, { useState } from 'react';
import { Box, Typography, Card, CardHeader, CardMedia, CardContent, CardActions, Button, Collapse, IconButton, Link } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ProfileInfoDialog, ToastSnackbar } from '../Components';

import PolynomConnectHowTo1 from '../assets/Contact-Us-Polynom-Connect-Instructions-1.png';
import PolynomConnectHowTo2 from '../assets/Contact-Us-Polynom-Connect-Instructions-2.png';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';

const ContactUs = ({ getAuth, apiUrl, auth }) => {

	/* PROFILE INFO DIALOG STATES AND FUNCTIONS */

	const [profileInfoDialogOpen, setProfileInfoDialogOpen] = useState(false);
	const [profileInfoUpdated, setProfileInfoUpdated] = useState(false);
	const [expanded, setExpanded] = useState(false);

	return (
		<Box sx={{ flexGrow: 1, padding: { xs: '0px 16px', md: '0px 8.25%' }, width: '100%' }} >
			<Grid container columns={{ xs: 4, sm: 8, lg: 12 }} display={'flex'} alignContent={'flex-start'} sx={{ minHeight: { xs: 'calc(100vh - 56px)', md: 'calc(100vh - 64px)', lg: 'calc(100vh - 112px)' }, marginLeft: '0' }}  columnSpacing={{lg: 3}} >
				<Grid xs={4} sm={6} lg={6} smOffset={1} lgOffset={3} textAlign={'center'} >
					<Typography variant='h1' color={'secondary'} margin={{ xs: '1.5rem 0', sm: '1.5rem 0 2rem 0', lg: '2rem 0 3rem 0' }} >CONTACT US</Typography>
				</Grid>
				<Grid xs={4} sm={6} smOffset={1} lg={8} lgOffset={2}>
					<Card variant='outlined' sx={{textAlign: 'left', p: '16px', mb: {xs: '16px', lg: '24px'}}} >
						<CardHeader title='Questions About Billing or Subscription Plans?'
							action={
								<IconButton
									expand={expanded}
									onClick={() => setExpanded(!expanded)}
									aria-expanded={expanded}
									aria-label="show more"
									>
									{ expanded === false ? <ExpandMoreTwoToneIcon /> : <ExpandLessTwoToneIcon />}
								</IconButton>
							}
						 />
						<Collapse in={expanded} timeout="auto" unmountOnExit>
							<CardContent>
							Before reaching out to us on our community Polynom server to ask us a question about any billing or subscription plan issues you may be having, be sure that you have provided your Polynom identity's hash. This hash can be copied from the 'Manage Identities' section of your Polynom settings and entered in the 'Polynom Identity' field of our 'Edit Profile Information' dialog. Open the 'Edit Profile Information' dialog by logging in and clicking the 'Edit profile information' button below. With this hash, we can verify that you are the owner of the account and can therefore make decisions regarding the account's billing and subscriptions.
							</CardContent>
							<CardActions>
								<Button size='small' disabled={auth ? false : true} onClick={() => setProfileInfoDialogOpen(true)} >Edit profile information</Button>
							</CardActions>
						</Collapse>
					</Card>
				</Grid>
				<Grid xs={4} sm={8} lg={12} sx={{textAlign: 'center', mb: {xs: '16px', lg: '24px'}}} >
					<Typography	variant='h4' color='secondary' >Connect to Our Polynom Server</Typography>
				</Grid>
				<Grid xs={4} sm={6} smOffset={1} lg={4} lgOffset={2} >
					<Card sx={{textAlign: 'left', p: '16px', mb: {xs: '16px', lg: '32px'}}} >
						<CardHeader title="1. Click the 'Join Server' Button" />
						<CardMedia
							sx={{aspectRatio: '3/2', mx: '16px'}}
							image={PolynomConnectHowTo1}
							title='Connecting to our Polynom Server'
						/>
						<CardContent>
							Open your Polynom app, create an identity, and click the 'Join Server' button.
						</CardContent>
					</Card>
				</Grid>
				<Grid xs={4} sm={6} smOffset={1} lg={4} lgOffset={0} >
					<Card sx={{textAlign: 'left', p: '16px', mb: '32px'}} >
						<CardHeader title="2. Enter the Server Address" />
						<CardMedia
							sx={{aspectRatio: '3/2', mx: '16px'}}
							image={PolynomConnectHowTo2}
							title='Connecting to our Polynom Server'
						/>
						<CardContent>
							Enter&nbsp;
							<Link onClick={() => navigator.clipboard.writeText('Community.ZeroEyes.Host')} sx={{cursor: 'pointer', display: 'inline-block'}} underline='hover' >
								Community.ZeroEyes.Host
								<ContentCopyTwoToneIcon fontSize='small' color='primary' sx={{position: 'relative', top: '5px', ml: '4px'}} />
							</Link>
							&nbsp;as the address,&nbsp;
							<Link onClick={() => navigator.clipboard.writeText('1000')} sx={{cursor: 'pointer', display: 'inline-block'}} underline='hover' >
								1000
								<ContentCopyTwoToneIcon fontSize='small' color='primary' sx={{position: 'relative', top: '5px', ml: '4px'}} />
							</Link>
							&nbsp;as the port, and click 'Join Server'.
						</CardContent>
					</Card>
				</Grid>
			</Grid>
			<ProfileInfoDialog openEditProfile={profileInfoDialogOpen} handleDialogClose={setProfileInfoDialogOpen} setStateClose={true}  getAuth={getAuth} setProfileInfoUpdated={setProfileInfoUpdated}  apiUrl={apiUrl} />
			<ToastSnackbar alertSeverity='success' alertTitle='Profile Information Updated Successfully' openSnackbar={profileInfoUpdated} setOpenSnackbar={setProfileInfoUpdated} snackbarButton={false} />
		</Box>
	)
}

export default ContactUs;