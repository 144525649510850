import React from 'react'
import { useTheme, useMediaQuery, ThemeProvider, Box, Typography, Card, CardContent } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

const About = () => {

	const theme = useTheme();
	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));  

	/* , background: { xs: `no-repeat center/100% url(${ZEPatternBG})`, lg: `no-repeat center/122% url(${ZEPatternBGWeb})` } */

	return (
		<ThemeProvider theme={theme}>

			<Box sx={{ flexGrow: 1, padding: { xs: '0px 16px', md: '0px 8.25%' }, width: '100%' }} >
				<Grid container columns={{ xs: 4, sm: 8, lg: 12 }} display={'flex'} alignContent={'flex-start'} sx={{ minHeight: { xs: 'calc(100vh - 56px)', md: 'calc(100vh - 64px)', lg: 'calc(100vh - 112px)' }, marginLeft: '0' }}  >
					<Grid xs={4} sm={6} lg={6} smOffset={1} lgOffset={3} textAlign={'center'}>
						<Typography variant='h1' color={'secondary'} margin={{ xs: '1.5rem 0', sm: '1.5rem 0 2rem 0', lg: '2rem 0 3rem 0' }} >ABOUT US</Typography>
					</Grid>
					<Grid xs={4} sm={6} lg={8} smOffset={1} lgOffset={2} textAlign={'center'}>
						<Card sx={{mb: {xs: '16px', lg: '32px'}}} >
							<CardContent sx={{ textAlign: 'left', p: {lg: '32px'}, textIndent: {xs: '16px', lg: '32px'} }} >
								<Typography variant={isDesktop ? 'h5' : 'body1'} >
									Zero Eyes Hosting was created as the final piece to allow everyone to create a community shielded from the prying eyes of interested parties around the globe; a host that we ourselves would trust for our own needs. We only provide hosting for products we have complete faith in and trust enough to use ourselves.
								</Typography>
								<br/>
								<Typography variant={isDesktop ? 'h5' : 'body1'} >
									In the spirit of the first product we provide hosting for, Code Siren's Polynom, we wanted to create our company with your privacy as a top priority. At a time when people's information is collected and traded at-will among several corporate and state entities, we custom-built our site so that we could ensure that the only information we have of yours is what is absolutely required to provide for your server hosting needs.
								</Typography>
								<br/>
								<Typography variant={isDesktop ? 'h5' : 'body1'} >
									Although we have not achieved complete anonymity and privacy between you and us, we constantly work towards that goal. As of the launch of Zero Eyes Hosting, we are based out of the United States and use Square as our primary payment processor. Our first priority was to provide a cryptocurrency payment option, keeping your bank out of our exchanges, and to provide even more private and robust server hosting options. Our long-term goal is to provide a new corporate entity and server location in Switzerland, where privacy is respected, even in dealings with the "Five Eyes" nation-states.
								</Typography>
								<br/>
								<Typography variant={isDesktop ? 'h5' : 'body1'} >
									We are very excited to have the opportunity to provide everyone with the ability to host a hub for their community without worrying about who might be watching what they are saying. This is only the beginning, for there is much more to come!
								</Typography>
							</CardContent>
						</Card>
					</Grid>
				</Grid>

			</Box>

		</ThemeProvider>
    )
}

export default About