import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box, Container, Button, Stack, useTheme, useMediaQuery, ThemeProvider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Footer } from "../Components";

import HeroImage from '../assets/Zero-Eyes_Marketing-Website-Hero-Image.png';
import PolynomBG from '../assets/Zero-Eyes_Marketing-Website-What-Is-Polynom-Mobile.png';
import PolynomBGWeb from "../assets/Zero-Eyes_Marketing-Website-What-Is-Polynom-Web.png";
import ZEBulletPoint from '../assets/ZEBulletPoint.png';
import PolynomLogo from '../assets/polynom-logo.png';
import YourChoiceBG from '../assets/Zero-Eyes_Marketing-Website-Its-Your-Choice-Mobile.png';
import YourChoiceBGWeb from '../assets/Zero-Eyes_Marketing-Website-Its-Your-Choice-Web.png';

const Home = ({ auth, lowPrice }) => {

	const theme = useTheme();
	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
	const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('xl'));

	return (
		<ThemeProvider theme={theme}>
			<Box sx={{ flexGrow: 1, padding: { xs: '0px 16px', md: '0px 8.25%' }, width: '100%' }}>

				{/* HERO SECTION */}

				<Grid container columns={{xs: 4, sm: 8, lg: 12}} sx={{ minHeight: { xs: 'calc(100dvh - 56px)', md: 'calc(100dvh - 64px)', lg: 'calc(100dvh - 112px)' }, marginLeft: '0' }} >
					<Grid xs={4} sm={6} smOffset={1} lg={5} lgOffset={0} sx={{textAlign: {xs: 'center', lg: 'left'}, display: 'flex', flexDirection: 'column', justifyContent: {lg: 'center'}}} >
						<Typography variant='h1' color={'secondary'} >
							THE CHOICE
						</Typography>
						<Typography variant='h5'>
							Safe, Private, & Secure Hosting
						</Typography>
						<Box sx={{display: {xs: 'block', lg: 'none'}, height: {xs: '48vh', sm: '56vh', md: '48vh'}}} >
							<img src={HeroImage} alt={'Zero Eyes logo hero'} style={{ height: '100%', display: 'block', margin: 'auto' }} />
						</Box>
						<Typography variant='body1' sx={{ textAlign: {xs: 'center', lg: 'left'}, marginTop: '2rem' }}>
							Host a community shielded from the prying eyes of interested parties around the globe. We only provide hosting for products we have complete faith in and trust enough to use ourselves.
						</Typography>
						<Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row-reverse'}, justifyContent: {xs: 'end' ,md: !auth ? 'space-between' : 'center', lg: 'start'}, flexGrow: {xs: '1', md: '0'}, mt: {xs: '16px',md: '24px'}, pb: {xs: '16px', lg: '0'}}} >
						{ !auth && (
							<Button component={Link} to='/signup' variant='contained' disableElevation={true} fullWidth={true} sx={{ margin: {xs: '0 auto', md: '0 0 0 24px'}, maxWidth: '400px' }}>Sign up</Button>
						)}
						<Button component={Link} to='/products/server-plans' variant={auth ? 'contained' : 'outlined'} fullWidth={true} sx={{ margin: {xs: '16px auto 0 auto', md: '0'}, borderWidth: '2px', maxWidth: '400px' }}>Check our server plans</Button>
						</Box>
					</Grid>
					<Grid lg={7} sx={{display: {xs: 'none', lg: 'block'}}} >
						<img src={HeroImage} alt={'Zero Eyes logo hero'} style={{ width: '100%', display: 'block', margin: 'auto' }} />
					</Grid>
				</Grid>

				{/* WHAT IS POLYNOM SECTION */}

				{ isDesktop ? (
					<Grid container columnSpacing={2} columns={12} sx={{ minHeight: '100dvh', marginLeft: '0', background: `no-repeat center/100% url(${PolynomBGWeb})` }} >
						<Grid lg={7} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}} >
							<Grid container columns={2} spacing={6} >
								<Grid lg={2} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
									<img src={PolynomLogo} alt={'Polynom logo'} style={{ width: '42%', display: 'inline-block' }} />
								</Grid>
								<Grid lg={1} sx={{display: 'flex', alignItems: 'center', pr: '8px'}} >
									<img src={ZEBulletPoint} alt="Data bullet point" style={{height: '20px'}}></img>
                  					<Typography variant='subtitle1' display={'inline-block'} marginLeft={'12px'} >QUANTUM RESISTANT COLLABORATION</Typography>
								</Grid>
								<Grid lg={1} sx={{display: 'flex', alignItems: 'center', pl: '8px'}} >
									<img src={ZEBulletPoint} alt="Data bullet point" style={{height: '20px'}}></img>
                  					<Typography variant='subtitle1' display={'inline-block'} marginLeft={'12px'} >FULLY CUSTOMIZABLE ENVIRONMENT</Typography>
								</Grid>
								<Grid lg={1} sx={{display: 'flex', alignItems: 'center', pr: '8px'}} >
									<img src={ZEBulletPoint} alt="Data bullet point" style={{height: '20px'}}></img>
                  					<Typography variant='subtitle1' display={'inline-block'} marginLeft={'12px'} >YOUR OWN MICROVERSE</Typography>
								</Grid>
								<Grid lg={1} sx={{display: 'flex', alignItems: 'center', pl: '8px'}} >
									<img src={ZEBulletPoint} alt="Data bullet point" style={{height: '20px'}}></img>
                  					<Typography variant='subtitle1' display={'inline-block'} marginLeft={'12px'} >ENCRYPTED PORTABLE IDS</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid lg={5} lgOffset={0} sx={{textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'center'}} >
							<Typography variant='h2' color={'secondary'} >
								WHAT IS POLYNOM
							</Typography>
							<Typography variant='h5' sx={{maxWidth: '480px'}} >
								World's First Polynom-Quantum Cryptography Collaboration Suite
							</Typography>
							<Typography variant='body1' sx={{ textAlign: 'left', marginTop: '2rem' }}>
								Polynom is a future-facing collaboration platform that uses the latest post-quantum cryptography (PQC) to protect your data from even the most powerful quantum computers.
							</Typography>
							<Box sx={{display: 'flex', mt: '24px'}} >
								<Button href='https://polynom.app/' target='_blank' rel="noreferrer" variant='outlined' fullWidth={true} sx={{borderWidth: '2px', maxWidth: '400px' }}>Learn more</Button>
								{/* <Button component={Link} to='/products/licenses' variant='contained' fullWidth={true} sx={{borderWidth: '2px', ml: '24px', maxWidth: '400px' }}>Browse {isLargeScreen &&'Polynom'} licenses</Button> */}
							</Box>
						</Grid>
					</Grid>
				) : (
					<Grid container columns={{xs: 4, sm: 8}} rowGap={1}  sx={{ minHeight: '100dvh', marginLeft: '0', textAlign: 'center', background: `no-repeat center/100% url(${PolynomBG})` }} >
						<Grid xs={4} sm={6} smOffset={1} >
							<Typography variant='h2' color={'secondary'} sx={{ mt: '1.5rem' }}>
								WHAT IS POLYNOM
							</Typography>
						</Grid>
						<Grid xs={4} sm={6} smOffset={1} >
							<Typography variant='h5' >
								World's First Polynom-Quantum <br/>Cryptography Collaboration Suite
							</Typography>
						</Grid>
						<Grid xs={4} sm={6} smOffset={1} sx={{height: '22.8vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
							<img src={PolynomLogo} alt={'Polynom logo'} style={{ height: '96%', display: 'inline-block', m: 'auto' }} />
						</Grid>
						<Grid xs={4} sm={6} smOffset={1} >
							<Typography variant='body1'>
								Polynom is a future-facing collaboration platform that uses the latest post-quantum cryptography (PQC) to protect your data from even the most powerful quantum computers.
							</Typography>
						</Grid>
						<Grid xs={4} sm={6} smOffset={1} >
							<Typography variant='subtitle1'>QUANTUM RESISTANT COLLABORATION</Typography>
						</Grid>
						<Grid xs={4} sm={6} smOffset={1} >
							<img src={ZEBulletPoint} alt="Data bullet point" style={{transform: 'rotate(90deg)', height: '20px'}}></img>
						</Grid>
						<Grid xs={4} sm={6} smOffset={1} >
							<Typography variant='subtitle1'>YOUR OWN MICROVERSE</Typography>
						</Grid>
						<Grid xs={4} sm={6} smOffset={1} >
							<img src={ZEBulletPoint} alt="Data bullet point" style={{transform: 'rotate(90deg)', height: '20px'}}></img>
						</Grid>
						<Grid xs={4} sm={6} smOffset={1} >
							<Typography variant='subtitle1'>ENCRYPTED PORTABLE IDS</Typography>
						</Grid>
						<Grid xs={4} sm={6} smOffset={1} >
							<img src={ZEBulletPoint} alt="Data bullet point" style={{transform: 'rotate(90deg)', height: '20px'}}></img>
						</Grid>
						<Grid xs={4} sm={6} smOffset={1} >
							<Typography variant='subtitle1'>FULLY CUSTOMIZABLE ENVIRONMENT</Typography>
						</Grid>
						<Grid  xs={4} sm={6} smOffset={1} sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row-reverse'}, justifyContent: {xs: 'end' ,md: 'center'}, flexGrow: {xs: '1', md: '0'}, mt: {xs: '16px',md: '24px'}, pb: '16px', width: '100%'}} >
							{/* <Button component={Link} to='/products/licenses' variant='contained' fullWidth={true} sx={{ margin: {xs: '0 auto', md: '0 0 0 24px'}, borderWidth: '2px', maxWidth: '400px' }}>Browse Polynom licenses</Button> */}
							<Button href='https://polynom.app/' target='_blank' rel="noreferrer" variant='outlined' fullWidth={true} sx={{m: {xs: '16px auto 0 auto', md: '0'}, borderWidth: '2px', maxWidth: '400px' }}>Learn more</Button>
						</Grid>
					</Grid>
				)}

				{/* IT'S YOUR CHOICE SECTION */}

				<Grid container columns={{xs: 4, sm: 8, lg: 12}} sx={{ minHeight: '74dvh', marginLeft: '0' }} >
					<Grid xs={4} sm={6} smOffset={1} lg={12} lgOffset={0} sx={{textAlign: 'center', background: {xs: `no-repeat center/100% url(${YourChoiceBG})`, lg: `no-repeat center/44% url(${YourChoiceBGWeb})`}, display: 'flex', flexDirection: 'column'}} >
						<Typography variant='h2' color={'secondary'} sx={{ marginTop: '1.5rem' }}>
							IT'S YOUR CHOICE
						</Typography>
						<Typography variant='h5' sx={{mt: '.25rem'}} >
							Secure Hosting for Polynom Servers <br />in a Country of Your Choice
						</Typography>
						{lowPrice !== '' ? (
							<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'end', flexGrow: '1' }}>
								<Typography variant='h5' align='center'>
									Servers starting at
								</Typography>
								<Box sx={{ mt: { xs: '-1rem', md: '-3rem' } }}>
									<Box sx={{ fontFamily: "'sweet-square-pro', 'Chakra Petch', sans-serif", fontSize: { xs: '4rem', md: '8rem' }, color: '#FAE696', display: 'inline' }}>
										{lowPrice}
									</Box>
									<Box sx={{ fontFamily: "'sweet-square-pro', 'Chakra Petch', sans-serif", fontSize: { xs: '2rem', md: '4rem' }, marginLeft: '8px', display: 'inline' }}>
										/MO
									</Box>
								</Box>
							</Box>
						) : (
							<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'end', flex: '1' }}></Box>
						)}
						<Button component={Link} to={'/signup'} variant='contained' fullWidth={true} sx={{ maxWidth: {sm: '400px'}, m: '0 auto 32px auto' }} >Sign up</Button>
					</Grid>
				</Grid>

				<Footer />
			</Box>
		</ThemeProvider>
	)
}

export default Home;
