import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, CircularProgress, Link as MUILink, Divider, Collapse } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';

const MoneroTransactionDialog = ({ isMoneroDialogOpen, setIsMoneroDialogOpen, moneroAddress, moneroPicoAmount, moneroQRCode, moneroQRCodeUrl, moneroTransProgress, moneroFailStatus, setMoneroFailStatus, handleMoneroTransaction, setIsPurchaseCompleteOpen }) => {

	const [transProgressText, setTransProgresstext] = useState('...');
	const [transProgressStep, setTransProgressStep] = useState('0/3');

	const updateProgressText = (percent) => {
		switch (percent) {
			case 0:
				if (moneroFailStatus === 'AgedOut') {
					setTransProgresstext('The transaction took too long. Please retry the purchase.');
				} else if (moneroFailStatus === 'Error') {
					setTransProgresstext('An error occurred during the purchase process. Please retry the purchase.');
				} else {
					setTransProgresstext('Creating transaction record...');
					setTransProgressStep('0/3');
				}
				break;
			case 33:
				setTransProgresstext('Wating for payment...');
				setTransProgressStep('1/3');
				break;
			case 67:
				setTransProgresstext('Waiting for two payment confirmations (approx. 4-6 minutes)...');
				setTransProgressStep('2/3');
				break;
			case 100:
				setTransProgresstext('Payment complete!');
				setTransProgressStep('3/3');
				break;
			default:
				break;
		}
	}

	const handleRetryPurchase = () => {
		setMoneroFailStatus(null);
		handleMoneroTransaction();
	}

	const handleDialogClose = (purpose) => {
		purpose === 'purchase' && setIsPurchaseCompleteOpen(true);
		setMoneroFailStatus(null);
		setIsMoneroDialogOpen(false);
	}

	useEffect(() => {
		updateProgressText(moneroTransProgress);
	}, [moneroTransProgress, moneroFailStatus])

	return (
    
		<Dialog open={isMoneroDialogOpen} fullWidth maxWidth='md' >
			<DialogTitle sx={{textAlign: 'center'}} >
				Complete Your Purchase
			</DialogTitle>
			<DialogContent>
				<Grid container columns={4} rowSpacing={2} >
					<Grid xs={2} xsOffset={1} sx={{display: 'flex', justifyContent: 'center'}} >	
						<Box sx={{ position: 'relative', display: 'inline-flex' }} >
							<CircularProgress variant='determinate' size='104px' value={moneroTransProgress === 0 ? 100 : moneroTransProgress} sx={{ opacity: moneroTransProgress === 0 && '.5', color: moneroTransProgress === 0 && '#fff'}} />
							<Box sx={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
								
								{ (moneroFailStatus === 'AgedOut' || moneroFailStatus === 'Error') ? (
									<ErrorTwoToneIcon color='error' fontSize='large' />
								) : (
									<Typography variant="body1" component="div" >{transProgressStep}</Typography>
								)}	
								
							</Box>
						</Box>
					</Grid>
					<Grid xs={4} sx={{textAlign: 'center'}} >
						<Typography variant='body1' >
							{transProgressText}
						</Typography>
					</Grid>
				</Grid>
				<Collapse in={moneroTransProgress === 33 ? true : false} easing='ease-in-out' >
					<Grid container columns={{xs: 1, sm: 2}} sx={{mt: '1rem'}} >
						<Grid xs={1} sm={2} sx={{mb: '1.5rem'}} ><Divider /></Grid>
						<Grid xs={1} sm={2} sx={{textAlign: 'center', mb: {sm: '1rem'}}} >
								<Typography variant='body1' >Scan or click the QR code to open your preferred Monero wallet app with the payment details auto-filled.</Typography>
						</Grid>
						<Grid xs={1} sm={2} md={1} sx={{display: 'flex', justifyContent: { md: 'center'}, alignItems: {xs: 'center'}, flexDirection: {xs: 'column', md: 'row'}, position: 'relative'}} >
							<MUILink href={moneroQRCodeUrl} sx={{position: 'relative'}}  >	
								<Box dangerouslySetInnerHTML={{__html: moneroQRCode}} className='monero-qr-code' sx={{display: 'flex'}} >

								</Box>
								<svg id="a"  viewBox="0 0 512 512" style={{width: '16%', position: 'absolute', top: '41.25%', left: '42.5%'}} >
									<circle cx="256" cy="256" r="256" fill='#fff' strokeWidth='0px' />
									<circle cx="256" cy="256" r="232" fill='#f0efeb' strokeWidth='0px' />
									<path d="m354.06,380.3h97.86c-41.15,64.74-113.52,107.7-195.91,107.7s-154.76-42.95-195.91-107.7h97.85v-123.1l98.06,98.01,98.05-98.01v123.1Z" fill='#4c4c4c' strokeWidth='0px' />
									<path d="m488,256c0,25.92-4.25,50.84-12.09,74.12h-70.19v-195.07l-149.72,148.19L106.28,135.05v195.07H36.09c-7.84-23.28-12.09-48.2-12.09-74.12C24,127.87,127.87,24,256,24s232,103.87,232,232Z" fill='#f60' strokeWidth='0px' />
								</svg>
							</MUILink>
						</Grid>
						<Grid xs={1} sm={2} md={1} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}} >
							<Box sx={{mt: {xs: '1rem', md: 'none'}}} >
								<Typography variant='subtitle1' >Wallet address:</Typography>
								<MUILink onClick={() => navigator.clipboard.writeText(moneroAddress)} sx={{cursor: 'pointer', wordBreak: 'break-word'}} underline='hover' >
									{moneroAddress}
									<ContentCopyTwoToneIcon fontSize='small' color='primary' sx={{position: 'relative', top: '5px', ml: '4px'}} />
								</MUILink>
							</Box>
							<Box sx={{mt: {xs: '1rem', md: 'none'}}} >
								<Typography variant='subtitle1' >Monero price:</Typography>
								<MUILink onClick={() => navigator.clipboard.writeText(moneroPicoAmount / (10 ** 12))} sx={{cursor: 'pointer'}} underline='hover' >
									{moneroPicoAmount / (10 ** 12)}
									<ContentCopyTwoToneIcon fontSize='small' color='primary' sx={{position: 'relative', top: '5px', ml: '4px'}} />
								</MUILink>
							</Box>
						</Grid>
					</Grid>
				</Collapse>
				<Collapse in={moneroTransProgress === 100 ? true : false} easing='ease-in-out' >
					<Grid container columns={4} sx={{mt: '1rem'}} >
						<Grid xs={4} sx={{mb: '1.5rem'}} ><Divider /></Grid>
						<Grid xs={4} sx={{textAlign: 'center'}} >
							<Typography variant='body1'>Click the follwing button to go to your dashboard and view your new subscription.</Typography>
						</Grid>
						<Grid xs={4} sm={2} smOffset={1} sx={{mt: '1rem'}} >
							{ window.location.pathname === '/dashboard' ? <Button onClick={() => handleDialogClose('purchase')} variant='contained' fullWidth color='primary' >View dashboard</Button> : <Button component={Link} to='/dashboard' state={{newPurchase: true}} variant='contained' fullWidth color='primary' >Go to dashboard</Button> }
						</Grid>
					</Grid>
				</Collapse>
			</DialogContent>
			{ (moneroTransProgress === 0 || moneroTransProgress === 33 || moneroTransProgress === 67) && (
				<DialogActions>
					<Button onClick={() => handleDialogClose()} color='error' >Close</Button>
				</DialogActions>
			)}
			{ (moneroFailStatus === 'AgedOut' || moneroFailStatus === 'Error') && (
				<DialogActions>
					<Button onClick={() => handleDialogClose()} >Cancel</Button>
					<Button onClick={handleRetryPurchase} >Retry purchase</Button>
				</DialogActions>
			)}
		</Dialog>

	)
}

export default MoneroTransactionDialog;