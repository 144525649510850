import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { LoginCard } from '../Components';
import Grid from '@mui/material/Unstable_Grid2';
import ContactSupportTwoToneIcon from '@mui/icons-material/ContactSupportTwoTone';

const Login = ({  apiUrl }) => {

  return (
    <Box sx={{ flexGrow: 1, padding: {xs: '0px 16px', md: '0px 8.25%'}, width: '100%' }}>
      <Grid container columns={{ xs: 4, sm: 8, lg: 12 }} sx={{ minHeight: {xs: 'calc(100vh - 56px)', md: 'calc(100vh - 64px)', lg: 'calc(100vh - 112px)'}, marginLeft: '0' }} >
        <LoginCard xs={4} sm={6} lg={6} smOffset={1} lgOffset={3} hSize={'h1'} hMargin={['1', '3']} purpose={'link'}  apiUrl={apiUrl} />
        <Grid xs={4} sm={6} lg={3} smOffset={1} lgOffset={4.5} display={'flex'} flexDirection={'column'} justifyContent={'end'} >
          <Link to="/contact-us" style={{width: '100%', marginBottom: '16px'}} >
            <Button startIcon={<ContactSupportTwoToneIcon />} variant='text' fullWidth={true} sx={{ padding: '6px 12px' }}>Contact us</Button>
          </Link>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Login