import React, { useState } from 'react';
import { Typography, Box, Card, CardContent, Button, TextField, Accordion, AccordionSummary, AccordionDetails, MenuItem, SvgIcon, Divider, List, ListItem, ListItemAvatar, Avatar, ListItemText, IconButton, Collapse, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Dialog, DialogContent, DialogActions } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import CodeSirenLogo from '../assets/codesiren_logo.png';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';


const ProLicenseCustomerInfo = ({ selectedProduct, xs, sm, lg, smOffset, lgOffset, customerType, setCustomerType, customerTypeErr, setCustomerTypeErr, proName, setProName, proEmail, setProEmail, proPhone, setProPhone, proCompany, setProCompany, proAddress1, setProAddress1, proAddress2, setProAddress2, proWebsite, setProWebsite, displayType, open, setOpen, handleContinue }) => {

    const [codeSirenWarningExpanded, setCodeSirenWarningExpanded] = useState(false);
    const [proNameErr, setProNameErr] = useState(false);
	const [proEmailErr, setProEmailErr] = useState(false);
	const [proPhoneErr, setProPhoneErr] = useState(false);
	const [proCompanyErr, setProCompanyErr] = useState(false);
	const [proAddress1Err, setProAddress1Err] = useState(false);
	const [proAddress2Err, setProAddress2Err] = useState(false);
	const [proWebsiteErr, setProWebsiteErr] = useState(false);

    const customerInfoContent = (
        <Grid container columns={{ xs: 2, lg: 8 }} columnSpacing={{ lg: 3 }} alignItems={'center'} >
            <Grid xs={2} lg={8} textAlign={'left'} >
                <Typography variant={'h5'} >Customer Information</Typography>
            </Grid>
            <Grid xs={2} lg={8} textAlign={'left'} sx={{my: '1rem'}} >
                <List>
                    <ListItem sx={{border: '2px solid #fae696', borderRadius: '4px'}}
                        secondaryAction={
                            <IconButton edge='end' onClick={() => setCodeSirenWarningExpanded(!codeSirenWarningExpanded)} >
                                { codeSirenWarningExpanded ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon /> }
                            </IconButton>
                        }
                    >
                        <ListItemAvatar sx={{display: {xs: 'none', sm: 'initial'}}} >
                            <Avatar sx={{backgroundColor: 'rgba(255, 255, 255, 0)'}} >
                                <InfoTwoToneIcon color='secondary' sx={{fontSize: '40px'}} />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText disableTypography>
                            <Box sx={{display: 'flex', alignItems: 'center', flexDirection: codeSirenWarningExpanded && {sm: 'row-reverse'}, justifyContent: 'start'}} >
                                <Typography variant='subtitle1' sx={{mr: {xs: '0', md: '1rem'}}} >Code Siren, LLC Polynom Professional Edition License Requirement</Typography>
                                <img src={CodeSirenLogo} alt='Polynom logo' style={{height: '40px'}} />
                            </Box>
                            <Collapse in={codeSirenWarningExpanded} >
                                <Typography variant='body1' sx={{mt: '.5rem'}} >Maintaining an accurate email address for your Polynom Professional Edition software is important. Doing so ensures you receive timely and essential updates, empowering you to maintain the most secure and up-to-date Polynom experience. You will be among the first to be notified about exclusive Code Siren, LLC promotions, enabling you to make informed decisions about renewing or upgrading your license. Additionally, you will be kept current on critical security patches, bug fixes, and new feature releases, ensuring you are always operating with the latest advancements.</Typography>
                            </Collapse>
                        </ListItemText>
                    </ListItem>
                </List>
            </Grid>
            <Grid xs={2} lg={8} textAlign={'left'} sx={{mb: '1rem'}} >
                <FormControl error={customerTypeErr} >
                    <FormLabel>Is this a personal or company purchase?</FormLabel>
                    <RadioGroup value={customerType} onChange={(e) => setCustomerType(e.target.value)} onBlur={() => customerType !== '' && setCustomerTypeErr(false)} sx={{flexDirection: {xs: 'column', lg: 'row'}, justifyContent: 'space-between'}} >
                        <FormControlLabel value='personal' control={<Radio />} label='Personal' />
                        <FormControlLabel value='company' control={<Radio />} label='Company' />
                    </RadioGroup>
                </FormControl>
                {customerTypeErr && <Typography variant='body2' color='error' >A selection must be made before you continue.</Typography>}
            </Grid>
            <Grid xs={2} lg={4} >
                <TextField variant='filled' label='Full name' value={proName} onChange={(e) => setProName(e.target.value)} onFocus={() => customerType === '' && setCustomerTypeErr(true)} color='secondary' type='text' sx={{ width: '100%' }} onBlur={(e) => e.target.value.length === 1 ? setProNameErr(true) : setProNameErr(false)} error={proNameErr} helperText={proNameErr ? 'More than one character is required.' : ' '} disabled={customerTypeErr} required />
            </Grid>
            <Grid xs={2} lg={4} >
                <TextField variant='filled' label='Email' value={proEmail} onChange={(e) => setProEmail(e.target.value)} color='secondary' type='email' sx={{ width: '100%' }} onBlur={(e) => e.target.value.length === 1 ? setProEmailErr(true) : setProEmailErr(false)} error={proEmailErr} helperText={proEmailErr ? 'More than one character is required.' : ' '} disabled={customerTypeErr} required />
            </Grid>
            <Grid xs={2} lg={8} textAlign='left' >
                <Collapse in={customerType === 'company'} >
                    <Grid container columns={{ xs: 2, lg: 8 }} columnSpacing={{ lg: 3 }} alignItems={'center'} >    
                        <Grid xs={2} lg={4} >
                            <TextField variant='filled' label='Phone number' value={proPhone} onChange={(e) => setProPhone(e.target.value)} color='secondary' type='tel' sx={{ width: '100%' }} onBlur={(e) => e.target.value.length === 1 ? setProPhoneErr(true) : setProPhoneErr(false)} error={proPhoneErr} helperText={proPhoneErr ? 'More than one character is required.' : ' '} />
                        </Grid>
                        <Grid xs={2} lg={4} >
                            <TextField variant='filled' label='Company name' value={proCompany} onChange={(e) => setProCompany(e.target.value)} color='secondary' type='text' sx={{ width: '100%' }} onBlur={(e) => e.target.value.length === 1 ? setProCompanyErr(true) : setProCompanyErr(false)} error={proCompanyErr} helperText={proCompanyErr ? 'More than one character is required.' : ' '} />
                        </Grid>
                        <Grid xs={2} lg={4} >
                            <TextField variant='filled' label='Company address line 1' value={proAddress1} onChange={(e) => setProAddress1(e.target.value)} color='secondary' type='text' sx={{ width: '100%' }} onBlur={(e) => e.target.value.length === 1 ? setProAddress1Err(true) : setProAddress1Err(false)} error={proAddress1Err} helperText={proAddress1Err ? 'More than one character is required.' : ' '} />
                        </Grid>
                        <Grid xs={2} lg={4} >
                            <TextField variant='filled' label='Company address line 2' value={proAddress2} onChange={(e) => setProAddress2(e.target.value)} color='secondary' type='text' sx={{ width: '100%' }} onBlur={(e) => e.target.value.length === 1 ? setProAddress2Err(true) : setProAddress2Err(false)} error={proAddress2Err} helperText={proAddress2Err ? 'More than one character is required.' : ' '} />
                        </Grid>
                        <Grid xs={2} lg={4} >
                            <TextField variant='filled' label='Company website' value={proWebsite} onChange={(e) => setProWebsite(e.target.value)} color='secondary' type='text' sx={{ width: '100%' }} onBlur={(e) => e.target.value.length === 1 ? setProWebsiteErr(true) : setProWebsiteErr(false)} error={proWebsiteErr} helperText={proWebsiteErr ? 'More than one character is required.' : ' '} />
                        </Grid>
                    </Grid>
                </Collapse>
                <Typography variant='body1' >Once your Polynom Professional license is approved, Zero Eyes will purge this data from our database.</Typography>
            </Grid>
        </Grid>
    )

    return (


        { ...displayType === 'card' ? (
            <Grid xs={xs} sm={sm} lg={lg} smOffset={smOffset} lgOffset={lgOffset} sx={{mb: {xs: '16px', lg: '0'}}} >
                <Card >
                    <CardContent sx={{ p: '16px !important'}} >
                        {customerInfoContent}
                    </CardContent>
                </Card>
            </Grid>
        ) : (
            <Dialog open={open} onClose={() => setOpen(false)} >
                <DialogContent>
                    {customerInfoContent}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} >Cancel</Button>
                    <Button onClick={handleContinue} disabled={customerType === '' ? true : false} >Continue</Button>
                </DialogActions>
            </Dialog>
        )}
    )
}

export default ProLicenseCustomerInfo;