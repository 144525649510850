import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography, useScrollTrigger } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2';
import { ToastSnackbar } from '../Components';
import ZEIconLogoSec from '../assets/zero-eyes-icon-logo-secondary.png';

const DeleteCardDialog = ({ openDeleteCard, handleDialogClose, setCardDeleted, provideCardInfo, setOpenPaymentInfo, card, apiUrl }) => {

    const [deleteErr, setDeleteErr] = useState(false);
    const [deleteErrMsg, setDeleteErrMsg] = useState('');
    
    const requestCardDelete = async () => {
        return fetch(`${apiUrl}/Capitalism/RemoveSquareCardInfo`, {
        method: 'POST',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'}
        }).then(data => data ? data.json() : {})
    }

    const provideDeleteResult = async () => {
        const result = await requestCardDelete();
        if(result.Status === 'Winning') {
            handleDialogClose('delete');
            setCardDeleted(true);
            provideCardInfo();
            setOpenPaymentInfo(true);
        } else {
            setDeleteErr(true);
            setDeleteErrMsg(result.Message)
        }
    }

    return (
    
        <Dialog open={openDeleteCard} onClose={() => handleDialogClose('delete')} >
            <DialogTitle>Delete Card on File</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    After deleting your card on file, you will have the opportunity to add a new card.
                </DialogContentText>
                <Grid container columns={4} sx={{border: '1px solif rgba(255,255,255,.7)'}} >
                    { card !== null && (
                        <Grid xs={4} sx={{border: '1px solid rgba(255,255,255,.7)', borderRadius: '10px', width: {xs: '100%', lg: '64%'}, margin: 'auto', maxWidth: {sm: '64%'} }} >
                            <Grid container columns={4} >
                                <Grid xs={1} xsOffset={3} display='flex' justifyContent='end' >
                                    <img src={ZEIconLogoSec} alt='Zero Eyes Icon' style={{width: '60%', margin: '12% 1rem'}} />
                                </Grid>
                                <Grid xs={4}>
                                    <p style={{height: '2rem', margin: '0'}} ></p>
                                </Grid>
                                <Grid xs={4} textAlign='center'>
                                    <Typography variant='h5' sx={{letterSpacing: '.25rem'}} >**** **** **** {card.Last4}</Typography>
                                </Grid>
                                <Grid xs={4}>
                                    <p style={{height: '1rem', margin: '0'}} ></p>
                                </Grid>
                                <Grid xs={2}>
                                    {card.ExpMonth.toString.length === 1 ? (
                                        <Typography variant='body1' sx={{marginLeft: '1rem'}} >Exp 0{card.ExpMonth}/{card.ExpYear}</Typography>
                                    ) : (
                                        <Typography variant='body1' sx={{marginLeft: '1rem'}} >Exp {card.ExpMonth}/{card.ExpYear}</Typography>
                                    )}
                                </Grid>
                                <Grid xs={2} textAlign='right' >
                                    <Typography variant='body1' sx={{mr: '1rem'}} >{card.Brand}</Typography>
                                </Grid>
                                
                                <Grid xs={4}>
                                    <p style={{height: '1rem', margin: '0'}} ></p>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleDialogClose('delete')} >Cancel</Button>
                <Button color='error' onClick={provideDeleteResult} >Delete</Button>
            </DialogActions>
            <ToastSnackbar alertSeverity='error' alertTitle='Failed to Delete Card' openSnackbar={deleteErr} setOpenSnackbar={setDeleteErr} snackbarDescription={deleteErrMsg} />
        </Dialog>

    )
}

export default DeleteCardDialog