import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormGroup, FormControlLabel, Checkbox, Button, Box } from '@mui/material';
import { ToastSnackbar } from '../Components';

const HouseRulesDialog = ({open, handleHouseRulesClose, isAuthenticated, buttonDisable, setButtonDisable, checked, setChecked, from, handleDialogOpen, setAgreed, houseRulesList, apiUrl }) => {

    const [houseRulesAgreeErr, setHouseRulesAgreeErr] = useState(false);
    const [houseRulesAgreeErrMsg, setHouseRulesAgreeErrMsg] = useState('');

    const handleChange = (event) => {
        setChecked({
            ...checked,
            [event.target.name]: event.target.checked,
        });
        (event.target.checked === true && Object.values(checked).filter(check => check === false).length === 1) && setButtonDisable(false);
        event.target.checked === false && setButtonDisable(true);
    }

    const sendAgreement = async () => {
        return fetch(`${apiUrl}/Auth/AgreeToHouseRules`, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'}
        }).then(data => data.json())
    }

    const receiveAgreement = async () => {
        const agreement = await sendAgreement();
        if (agreement.Status === 'Winning') {
            isAuthenticated();
            setAgreed(true);
            handleHouseRulesClose();
            from === 'nav' && handleDialogOpen();
        } else {
            setHouseRulesAgreeErr(true);
            setHouseRulesAgreeErrMsg(agreement.Message);
        }
    }


    return (

        <Dialog open={open} onClose={handleHouseRulesClose} >
            <DialogTitle>House Rules Agreement</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Click the checkbox next to each rule and click the Agree button before you can add your credit card inforamtion.
                </DialogContentText>
                <FormGroup>
                    {houseRulesList.map((rule, index) => (
                        <FormControlLabel key={index} control={<Checkbox checked={checked.index} onChange={handleChange} name={`${index}`} sx={{ mt: '-7px' }} />} label={rule} sx={{alignItems: 'start', mt: '16px'}} />
                    ))}
                    <FormControlLabel key={houseRulesList.length} control={<Checkbox checked={checked[houseRulesList.length]} onChange={handleChange} name={`${houseRulesList.length}`} sx={{ mt: '-7px' }} />} label={(
                        <Box sx={{p: '0', m: '0'}} >
                            <span>You must read and agree to Polynom's </span>
                            <Button component={Link} variant='text' to={'https://polynom.app/docs/privacy_policy.pdf'} target='_blank' rel='noreferrer' sx={{p: '0', height: '1rem'}} >Privacy Policy</Button>
                            <span> and </span>
                            <Button component={Link} variant='text' to={'https://polynom.app/docs/terms_of_use.pdf'} target='_blank' rel='noreferrer' sx={{p: '0', height: '1rem'}} >Terms of Use</Button>
                            <span>.</span>
                        </Box>
                    )} sx={{alignItems: 'start', mt: '16px'}}
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleHouseRulesClose} >Cancel</Button>
                <Button onClick={receiveAgreement} disabled={buttonDisable} >Agree</Button>
            </DialogActions>
            <ToastSnackbar alertSeverity='error' alertTitle='House Rules Agreement Failed' openSnackbar={houseRulesAgreeErr} setOpenSnackbar={setHouseRulesAgreeErr} snackbarDescription={houseRulesAgreeErrMsg} />
        </Dialog>

    )
}

export default HouseRulesDialog