import React from 'react'
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({children, auth, path}) => {

    return (
        auth ? children : <Navigate  to={`/${path}`} />
    )
}

export default PrivateRoute;