import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box, Card, CardContent, Button, Tooltip, Stack, SvgIcon, List, ListItem, ListItemIcon, ListItemText, Divider, useTheme, useMediaQuery, ThemeProvider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useLocation } from 'react-router-dom';
import { LoginCard, SignupCard, ToastSnackbar, PaymentInformationCard, MoneroTransactionDialog, ProLicenseCustomerInfo } from '../Components';

import StorageTwoToneIcon from '@mui/icons-material/StorageTwoTone';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import RecordVoiceOverTwoToneIcon from '@mui/icons-material/RecordVoiceOverTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import SpeedTwoToneIcon from '@mui/icons-material/SpeedTwoTone';
import FolderCopyTwoToneIcon from '@mui/icons-material/FolderCopyTwoTone';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import ZEPowerUpsWordmark from '../assets/ZE-Power-Ups-Wordmark.png';
import DevicesTwoToneIcon from '@mui/icons-material/DevicesTwoTone';

const Checkout = ({ auth, logoutUser, isAuthenticated, houseRulesList, setRulesErr, agreedToHouseRules, checked, setChecked, squareEnv, squareAppId, squareLocationId, apiUrl }) => {

	const theme = useTheme();
	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
	const { state } = useLocation();
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [login, setLogin] = useState(false);
	const [transactionErr, setTransactionErr] = useState(false);
	const [transErrMsg, setTransErrMsg] = useState('');
	const [agreed, setAgreed] = useState(false);
	const [totalProductPrice, setTotalProductPrice] = useState('$0');
	const [serverPlanPrice, setServerPlanPrice] = useState(null);
	const [licensePrice, setLicensePrice] = useState(null);
	const [torPUPrice, setTorPUPrice] = useState(null);
	const [backupPUPrice, setBackupPUPrice] = useState(null);
	const [addDiskSpacePUPrice, setAddDiskSpacePUPrice] = useState(null);



	/* #region TOR PROJECT ICON SVG CODE */

	const torSVGIcon = <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	viewBox="0 0 150.5 90.7" enableBackground="new 0 0 150.5 90.7">
	<g>
	<g>
		<g>
			<path fill="#FFFFFF" d="M85.7,71.3c0.1-1.8-0.7-4.6-1.5-7.3c-0.5-2-2.4-4-2.9-5.5c-0.9-2.5-0.5-4.3-0.6-7.8
				c0.4,3.2,0.4,6,1.2,7.3c0.7,1.3,1.9,3.1,2.6,4.9c1.3,3.3,2.1,6.9,2.2,8.6c0.1,2.9-0.3,7.5-1.3,11c-0.4,1.3-0.9,2.5-1.5,3.6
				c1.3-1.9,2.2-4,2.6-5.7c1.3-6.1,2.4-12.6,1.5-16.4c-0.2-0.9-0.5-3.8-1.7-7.1c-1.8-4.6-4.2-8.5-4.5-9.4c-0.9-2.6-1.2-9.1-1.3-12.6
				c0.1,4.2,0.9,10.3,1.5,11.7c0.3,0.9,3,5.2,4.9,9.7c1.3,3,1.6,5.7,1.8,6.5c1,8.4,0.1,11.9-2,19.4c-0.3,1.1-0.8,2.1-1.3,3.1
				c1.5-1.8,2.6-3.7,3-5.3c2.2-9.4,2.9-21.4-0.1-30.5c-1.5-4.8-5.2-9-6.1-11.9c-1-3.4-0.3-8.9-0.3-9l-8.1-3.6
				c2.1,5.4,2.5,9.6,0.3,11.4c-8.5,7-22.7,14.9-22.7,26.7c0,12.5,7.4,26,26.5,27c-2.3-0.6-4.5-1.3-6.4-2.1c-1.9-0.8-3.6-1.9-4.9-3.4
				c0-0.1-0.5-0.5-0.5-0.5c-2.8-3.2-6.3-8.6-7.5-13.7c-0.5-2-0.9-3.6-0.3-5.7c2.3-8.2,7.3-11.5,12.3-14.9c1.1-0.7,2.5-1.4,3.6-2.3
				c2.1-1.5,3.2-6.3,4.4-10.1c-0.6,3.1-1.3,8.7-4,10.8c-1.1,0.8-2.3,1.6-3.5,2.4c-4.8,3.3-9.7,6.4-11.9,14.3
				c-0.5,1.7-0.1,3.5,0.3,5.2c1.2,4.9,4.6,10.1,7.3,13.2c0,0.1,0.5,0.5,0.5,0.6c1.8,2.1,2.6,3,7.3,4.3c-1.1-0.6-2-1.3-2.8-1.8
				c-4.8-3.3-7.2-7.4-7.6-13.5c-0.3-4.7,2.6-9.7,8.3-12.7c3.9-2,5-5.2,6.3-9c-0.9,4.3-1.8,7-5.9,9.4c-4.8,2.8-8.2,8.2-7.9,11.9
				c0.4,4.5,3.6,9.7,7.1,12.8c1.5,1.3,3.8,2.1,6,2.7c-0.6-0.5-1.2-1.1-1.8-1.9c-0.7-1-1.2-2.4-1.7-3.7c-0.5-1.3-1-2.6-1-3.8
				c0-2.9,0.3-6.5,2.4-8.9c1.9-2.2,2.5-2.5,3.3-5.1c-0.6,3-1,3.2-2.8,5.7c-2.3,3.2-2.4,6.4-2.1,8.4c0.2,1.3,0.7,2.5,1.1,3.5
				c0.5,1.3,1.1,2.5,1.9,3.6c0.7,0.8,1,1.4,2.1,1.8c1.1-2,1.8-6.2,1.9-8.6c0.1-2,0.2-4.3-0.1-6.6c-0.4-3.1-1.4-6.1-1.4-8.5
				c0.5,2.2,1.6,5.2,2.3,8.3c0.5,2.4,0.6,4.8,0.4,6.5c-0.2,2.1-0.3,3.8-0.6,5.6c-0.2,1.2-0.8,2.4-1.7,3.5c2.2-1.7,3.6-4.3,4.2-7.4
				C85.6,77,85.7,73.3,85.7,71.3z"/>
		</g>
		<g>
			<path fill="#FFFFFF" d="M106,53.4c-3.1-5.7-11-11.3-19.3-16.5c-1.9-1.1-2.3-5.3-2-7.1l-1.9-0.9c0,0-0.7,5,0.6,9
				c0.9,2.8,3.9,6,5.7,10.5c1.3,3,2.6,10.2,2.7,11c1,8.4,0.5,16.3-2,23c-1.1,3.1-3.5,5.9-5.4,7.2c-0.3,0.2-0.6,0.4-0.9,0.6
				C99.3,90.7,116.7,72.8,106,53.4z"/>
		</g>
	</g>
	<g>
		<path fill="#FFFFFF" d="M102.7,4.3C96.2,6.5,90.4,9.9,86.1,16c2.6-5.3,6.7-10.6,11.3-16c-6.3,4.5-11.7,9.6-15.2,16.4l2.4-9.6
			c-3.7,6.6-6.2,13.4-7,20.1l4.2,1.8C87.2,20.4,94.1,12.1,102.7,4.3z"/>
	</g>
	<g>
		<polygon fill="#FFFFFF" points="80.1,64.5 80.1,64.5 80.1,64.5 		"/>
	</g>
	<g>
		<path fill="#FFFFFF" d="M61.6,28.6v-16c0-2.1-1.8-3.8-3.8-3.8H3.8C1.7,8.8,0,10.5,0,12.6v16c0,2.1,1.7,3.8,3.8,3.8H16
			c1.1,0,2.6,0.9,2.6,2.6v52.7c0,1.8,1.3,3.1,3,3.1h18.4c1.7,0,3.1-1.3,3.1-3.1V35.4c0-1.6,1.1-3,3.6-3h11.1
			C59.9,32.3,61.6,30.6,61.6,28.6z"/>
	</g>
	<g>
		<path fill="#FFFFFF" d="M150.5,50.2V33.1c0-1.7-1.4-3.1-3.2-3.1h-4.8c-0.6,0-2.1,0.1-2.5,0.1c-12.1,1.2-21.7,11-22.6,23.2
			c-0.1,0.5-0.1,1.3-0.1,1.9v32.9c0,1.3,1.3,2.5,2.8,2.5h17.2c1.5,0,2.8-1.1,2.8-2.5V59.2c0-3.1,2.4-5.9,7.7-5.9
			C150.6,53.3,150.5,52.4,150.5,50.2z"/>
	</g>
	</g>
	</svg>;

	/* #endregion */

	/* PROFESSIONAL LICENSE STATES */

	const [customerType, setCustomerType] = useState('');
	const [customerTypeErr, setCustomerTypeErr] = useState(false);
	const [proName, setProName] = useState('');
	const [proEmail, setProEmail] = useState('');
	const [proPhone, setProPhone] = useState('');
	const [proCompany, setProCompany] = useState('');
	const [proAddress1, setProAddress1] = useState('');
	const [proAddress2, setProAddress2] = useState('');
	const [proWebsite, setProWebsite] = useState('');
	const [customerInfoErr, setCustomerInfoErr] = useState(false);
	
	/* #region MONERO PAYMENT STATES AND FUNCTIONS */

	const [isMoneroDialogOpen, setIsMoneroDialogOpen] = useState(false);
	const [moneroTransProgress, setMoneroTransProgress] = useState(0);
	const [moneroFailStatus, setMoneroFailStatus] = useState(null);
	const [moneroAddress, setMoneroAddress] = useState('')
	const [moneroPicoAmount, setMoneroPicoAmount] = useState('');
	const [moneroQRCode, setMoneroQRCode] = useState(null);
	const [moneroQRCodeUrl, setMoneroQRCodeUrl] = useState('');

	/* CHECK TO SEE IF MONERO IS BEING ACCEPTED */

	const [paymentInfoExpand, setPaymentInfoExpand] = useState(false);
	const [moneroActive, setMoneroActive] = useState(false);
	const [isMoneroOff, setIsMoneroOff] = useState(false);

	const getMoneroInfo = async () => {
		return fetch(`${apiUrl}/Capitalism/GetXMRInfo`, {
			method: 'GET',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' }
		}).then(data => data.json())
	}

	const isMoneroActive = async () => {
		const moneroInfo = await getMoneroInfo();
		if (moneroInfo.Status === 'Winning') {
			const milDiff = ((Date.now() - (new Date(moneroInfo.XMRInfo.UpdatedOn).getTime())) / 60000);
			if (moneroInfo.XMRInfo.Status === 'OK' && milDiff < 30 ) {
				setMoneroActive(true);
			} else {
				setMoneroActive(false);
				setPaymentInfoExpand('card');
			}
		}
	}

	const sendMoneroTransDetails = async () => {
		const payload = selectedProduct[2].Name === 'Professional' ? {...selectedProduct[0], PaymentType: "XMR", CodeSirenPII: {Name: proName, Email: proEmail, Phone: proPhone, CompanyName: proCompany, AddressLine1: proAddress1, AddressLine2: proAddress2, Website: proWebsite}} : {...selectedProduct[0], PaymentType: "XMR"}
		return fetch(`${apiUrl}/Capitalism/AddNewSubscription`, {
			method: 'POST',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(payload)
		}).then(data => data.json())
	}

	const getMoneroTransStatus = async (id) => {
		return fetch(`${apiUrl}/Capitalism/GetXMRTransStatus`, {
			method: 'POST',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(id)
		}).then(data => data.json())
	}

	const handleMoneroTransaction = async () => {
		const proCustInfo = {Name: proName, Email: proEmail, Phone: proPhone, CompanyName: proCompany, AddressLine1: proAddress1, AddressLine2: proAddress2, Website: proWebsite};
		const companyValueCheck = Object.values(proCustInfo).find((element, index) => index !== 5 ? element.length < 2 : element.length === 1);
		if ((selectedProduct[2].Name === 'Professional' && (proCustInfo.Name.length < 2 || proCustInfo.Email.length < 2)) || (customerType === 'company' && companyValueCheck !== undefined)) {
			setCustomerInfoErr(true);
		} else {
			let transStatusCall;
			let transStatus = 'New';
			const transId = {TransactionID: null};
			/* CHECK TO SEE IF MONERO IS STILL ACCEPTED */
			const moneroInfo = await getMoneroInfo();
			if (moneroInfo.Status === 'Winning') {
				const milDiff = ((Date.now() - (new Date(moneroInfo.XMRInfo.UpdatedOn).getTime())) / 60000);
				if (moneroInfo.XMRInfo.Status !== 'OK' || milDiff >= 30 ) {
					setMoneroActive(false);
					setIsMoneroOff(true);
					setPaymentInfoExpand('card');
					return;
				}
			}
			/* GET TRANSACTION ID */
			const transDetails = await sendMoneroTransDetails();
			if (transDetails.Status === 'Winning') {
				transId.TransactionID = transDetails.TransactionID;
				transStatusCall = await getMoneroTransStatus(transId);
				if (transStatusCall.Status === 'Winning') {
					transStatus = transStatusCall.TransactionInfo.Status;
				} else {
					return;
				}
				setIsMoneroDialogOpen(true);
				/* GET TRANSACTION STATUS UPDATE UNTIL THE STATUS IS PENDING PAYMENT */
				let paymentStatusIntervalId = setInterval(async () => {
					if (transStatus === 'PendingPayment') {
						setMoneroTransProgress(33);
						setMoneroAddress(transStatusCall.TransactionInfo.XMRAddress);
						setMoneroPicoAmount(transStatusCall.TransactionInfo.XMRPicoAmount);
						setMoneroQRCode(transStatusCall.TransactionInfo.XMRQRCode);
						setMoneroQRCodeUrl(transStatusCall.TransactionInfo.XMRURL);
						clearInterval(paymentStatusIntervalId);
					} else if (transStatus === 'AgedOut') {
						setMoneroTransProgress(0);
						setMoneroFailStatus('AgedOut');
						clearInterval(paymentStatusIntervalId);
					} else if (transStatus === 'Error') {
						setMoneroTransProgress(0);
						setMoneroFailStatus('Error');
						clearInterval(paymentStatusIntervalId);
					} else {
						transStatusCall = await getMoneroTransStatus(transId);
						if (transStatusCall.Status === 'Winning') {
							transStatus = transStatusCall.TransactionInfo.Status;
						} else {
							clearInterval(paymentStatusIntervalId);
						}
					}
				}, 2000)
				/* GET TRANSACTION STATUS UPDATE UNTIL THE STATUS IS PENDING CONFIRMATIONS */
				let confirmationStatusIntervalId = setInterval(async () => {
					if (transStatus === 'PendingConfirmations' || transStatus === 'Paid') {
						setMoneroTransProgress(67);
						clearInterval(confirmationStatusIntervalId);
					} else if (transStatus === 'AgedOut') {
						setMoneroTransProgress(0);
						setMoneroFailStatus('AgedOut');
						clearInterval(confirmationStatusIntervalId);
					} else if (transStatus === 'Error') {
						setMoneroTransProgress(0);
						setMoneroFailStatus('Error');
						clearInterval(confirmationStatusIntervalId);
					} else {
						transStatusCall = await getMoneroTransStatus(transId);
						if (transStatusCall.Status === 'Winning') {
							transStatus = transStatusCall.TransactionInfo.Status;
						} else {
							clearInterval(confirmationStatusIntervalId);
						}
					}
				}, 2000)
				/* GET TRANSACTION STATUS UPDATE UNTIL THE STATUS IS PAID */
				let paidStatusIntervalId = setInterval(async () => {
					if (transStatus === 'Paid') {
						setMoneroTransProgress(100);
						clearInterval(paidStatusIntervalId);
					} else if (transStatus === 'AgedOut') {
						setMoneroTransProgress(0);
						setMoneroFailStatus('AgedOut');
						clearInterval(paidStatusIntervalId);
					} else if (transStatus === 'Error') {
						setMoneroTransProgress(0);
						setMoneroFailStatus('Error');
						clearInterval(paidStatusIntervalId);
					} else {
						transStatusCall = await getMoneroTransStatus(transId);
						if (transStatusCall.Status === 'Winning') {
							transStatus = transStatusCall.TransactionInfo.Status;
						} else {
							clearInterval(paidStatusIntervalId);
						}
					}
				}, 2000)
			}
		}
	}

	/* #endregion */

	useEffect(() => {
		console.log(state);
		isMoneroActive();
		setSelectedProduct(state);
		setTotalProductPrice(
			((
				(state[0].SelectedPrice !== null && state[0].SelectedPrice.Price) + state[0].SelectedLicensePrice.Price + (((state[0].BackupsPowerUp && state[1].BackupPrice) + (state[0].TORPowerUp && state[1].TORPrice) + (state[0].StoragePowerUp !== null && state[0].StoragePowerUp.Price)) * state[0].SelectedPrice.Months)
			) / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}));
		state[0].SelectedPlan !== null && setServerPlanPrice((state[0].SelectedPrice.Price / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}));
		setLicensePrice((state[0].SelectedLicensePrice.Price / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}));
		state[0].TORPowerUp && setTorPUPrice(((state[1].TORPrice * state[0].SelectedPrice.Months) / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}));
		state[0].BackupsPowerUp && setBackupPUPrice(((state[1].BackupPrice * state[0].SelectedPrice.Months) / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}));
		state[0].StoragePowerUp !== null && setAddDiskSpacePUPrice(((state[0].StoragePowerUp.Price * state[0].SelectedPrice.Months) / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}))
	}, [])

	return (
		<ThemeProvider theme={theme}>
			<Box sx={{ flexGrow: 1, padding: { xs: '0px 16px', md: '0px 8.25%' }, width: '100%' }}>
				<Grid container columns={{ xs: 4, sm: 8, lg: 12 }} sx={{ height: '100%', marginLeft: '0' }} gap={{ lg: 3 }} >
					<Grid xs={4} sm={6} lg={6} smOffset={1} lgOffset={3} textAlign={'center'}>
						<Typography variant='h1' color={'secondary'} margin={{ xs: '1.5rem 0', lg: '2rem 0 3rem 0' }} >CHECKOUT</Typography>
					</Grid>
					<Grid container columns={{ xs: 4, sm: 8, lg: 12 }} xs={4} sm={8} lg={6} lgOffset={1} gap={{ lg: 3 }} sx={{display: 'flex', flexDirection: 'column'}} >
						{(() => {
							if (!auth && !login) {
								return (
									<SignupCard xs={4} sm={6} lg={12} smOffset={1} lgOffset={0} hSize={'h2'} hMargin={['1', '1']} purpose={'render'} setLogin={setLogin} apiUrl={apiUrl} isAuthenticated={isAuthenticated} />
								);
							} else if (!auth && login) {
								return (
									<LoginCard xs={4} sm={6} lg={12} smOffset={1} lgOffset={0} hSize={'h2'} hMargin={['1', '1']} purpose={'render'} setLogin={setLogin}  apiUrl={apiUrl} isAuthenticated={isAuthenticated} />
								)
							} else {
								return (
									<Grid xs={4} sm={6} lg={12} smOffset={1} lgOffset={0} >
										<Card sx={{ pb: { lg: '16px' } }} >
											<CardContent>
												<Grid container columns={{ xs: 2, lg: 8 }} columnSpacing={{ lg: 3 }} alignItems={'center'} >
													<Grid xs={2} lg={8} lgOffset={0} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} >
														<Typography variant={'h5'} >Account</Typography>
														<Button onClick={() => logoutUser()} variant='text' color='primary' size='small' >Log out</Button>
													</Grid>
												</Grid>
												<Grid container columns={{ xs: 2, lg: 8 }} columnSpacing={{ xs: 2, lg: 3 }} rowSpacing={2} marginTop={'8px'} >
													<Grid xs={1} lg={4} lgOffset={2} >
														<Tooltip title='Username' arrow followCursor >
															<Stack direction={{ xs: 'row', lg: 'column' }} alignItems={'center'} spacing={1.5} >
																<AccountCircleTwoToneIcon sx={{ fontSize: { lg: '28px' } }} color={'secondary'} />
																<Typography variant='body1' >{localStorage.getItem('username')}</Typography>
															</Stack>
														</Tooltip>
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									</Grid>
								)
							}
						})()}
						
						{ (isDesktop && state[2].Name === 'Professional') && (
							<ProLicenseCustomerInfo displayType='card' selectedProduct={state} lg={12} customerType={customerType} setCustomerType={setCustomerType} customerTypeErr={customerTypeErr} setCustomerTypeErr={setCustomerTypeErr} proName={proName} setProName={setProName} proEmail={proEmail} setProEmail={setProEmail} proPhone={proPhone} setProPhone={setProPhone} proCompany={proCompany} setProCompany={setProCompany} proAddress1={proAddress1} setProAddress1={setProAddress1} proAddress2={proAddress2} setProAddress2={setProAddress2} proWebsite={proWebsite} setProWebsite={setProWebsite} />
						)}

						{ isDesktop && (
							<PaymentInformationCard auth={auth} isAuthenticated={isAuthenticated} selectedProduct={state} totalProductPrice={totalProductPrice} agreedToHouseRules={agreedToHouseRules} setAgreed={setAgreed} houseRulesList={houseRulesList} checked={checked} setChecked={setChecked} setRulesErr={setRulesErr} setTransactionErr={setTransactionErr} setTransErrMsg={setTransErrMsg} squareEnv={squareEnv} squareAppId={squareAppId} squareLocationId={squareLocationId} lg={12} apiUrl={apiUrl} handleMoneroTransaction={handleMoneroTransaction} moneroActive={moneroActive} expanded={paymentInfoExpand} setExpanded={setPaymentInfoExpand} customerType={customerType} proCustInfo={{Name: proName, Email: proEmail, Phone: proPhone, CompanyName: proCompany, AddressLine1: proAddress1, AddressLine2: proAddress2, Website: proWebsite}} setCustomerInfoErr={setCustomerInfoErr} />
						)}

					</Grid>

					<Grid xs={4} sm={6} lg={4} smOffset={1} lgOffset={0} >
						<Card sx={{ margin: { xs: '16px 0', lg: '0' }, pb: { lg: '16px' } }} >
							<CardContent>
								<Grid container columns={{ xs: 2, lg: 8 }} alignItems={'center'} >
									<Grid xs={2} lg={8} sx={{mb: '.8rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} >
										<Typography variant={'h5'}  >Selected Product</Typography>
										<Button component={Link} to={state[0].SelectedPlan !== null ? '/products/server-plans' : '/products/licenses'} state={selectedProduct} variant='text' color='primary' size='small' >Change product</Button>
									</Grid>
									<Grid xs={2} lg={8} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}} >
										<Typography variant={'h6'} sx={{mb: '.5rem'}} >Polynom License</Typography>
										<Typography variant={'subtitle1'} color='secondary' sx={{mb: '.5rem'}} >{state[2].Name}</Typography>
									</Grid>
									<Grid xs={2} lg={8} >
										<List disablePadding >
											<Grid container columns={{ xs: 2, lg: 8 }} spacing={{xs: 2, lg: 3}} >
												<Grid xs={2} lg={8} xl={4}>
													<ListItem disablePadding >
														<ListItemIcon>
															<DevicesTwoToneIcon color={'secondary'} />
														</ListItemIcon>
														<ListItemText disableTypography={true}>
															<Typography variant='body1' >{state[0].SelectedLicenseLevel}</Typography>
															<Typography variant='body1' sx={{opacity: '.7'}}>Concurrent devices</Typography>
														</ListItemText>
													</ListItem>
												</Grid>
												<Grid xs={2} lg={8} xl={4}>
													<ListItem disablePadding >
														<ListItemIcon>
															<MonetizationOnTwoToneIcon color={'secondary'} />
														</ListItemIcon>
														<ListItemText disableTypography={true}>
															<Typography variant='body1' >{licensePrice}{state[0].SelectedLicensePrice.Name === 'Annual' ? '/year' : '/month'}</Typography>
															<Typography variant='body1' sx={{opacity: '.7'}}>License price</Typography>
														</ListItemText>
													</ListItem>
												</Grid>
											</Grid>
										</List>
									</Grid>
									<Grid xs={2} lg={8} my={3} >
										<Divider />
									</Grid>
									{ state[0].SelectedPlan !== null && (
										<Grid xs={2} lg={8} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}} >
											<Typography variant={'h6'} sx={{mb: '.5rem'}} >Server Plan</Typography>
											<Typography variant={'subtitle1'} color='secondary' sx={{mb: '.5rem'}} >{state[1].Name}</Typography>
										</Grid>
									)}
									{ state[0].SelectedPlan !== null && (
										<Grid xs={2} lg={8} >
											<List disablePadding >
												<Grid container columns={{ xs: 2, lg: 8 }} spacing={{xs: 2, lg: 3}} >
													<Grid xs={2} lg={8} xl={4}>
														<ListItem disablePadding >
															<ListItemIcon>
																<GroupTwoToneIcon color={'secondary'} />
															</ListItemIcon>
															<ListItemText disableTypography={true}>
																<Typography variant='body1' >{state[1].EstUsers}</Typography>
																<Typography variant='body1' sx={{opacity: '.7'}}>Community size</Typography>
															</ListItemText>
														</ListItem>
													</Grid>
													<Grid xs={2} lg={8} xl={4}>
														<ListItem disablePadding >
															<ListItemIcon>
																<RecordVoiceOverTwoToneIcon color={'secondary'} />
															</ListItemIcon>
															<ListItemText disableTypography={true}>
																<Typography variant='body1' >{state[1].EstVoiceUsers}</Typography>
																<Typography variant='body1' sx={{opacity: '.7'}}>Users in voice chat</Typography>
															</ListItemText>
														</ListItem>
													</Grid>
													<Grid xs={2} lg={8} xl={4}>
														<ListItem disablePadding >
															<ListItemIcon>
																<SpeedTwoToneIcon color={'secondary'} />
															</ListItemIcon>
															<ListItemText disableTypography={true}>
																<Typography variant='body1' >{state[1].TransferLimitGiB} GiB/mo</Typography>
																<Typography variant='body1' sx={{opacity: '.7'}}>Data transfer limit</Typography>
															</ListItemText>
														</ListItem>
													</Grid>
													<Grid xs={2} lg={8} xl={4}>
														<ListItem disablePadding >
															<ListItemIcon>
																<StorageTwoToneIcon color={'secondary'} />
															</ListItemIcon>
															<ListItemText disableTypography={true}>
																<Typography variant='body1' >{state[1].BaseDiskSpaceGiB} GiB</Typography>
																<Typography variant='body1' sx={{opacity: '.7'}}>Server disk space</Typography>
															</ListItemText>
														</ListItem>
													</Grid>
													<Grid xs={2} lg={8} xl={4}>
														<ListItem disablePadding >
															<ListItemIcon>
																<MonetizationOnTwoToneIcon color={'secondary'} />
															</ListItemIcon>
															<ListItemText disableTypography={true}>
																<Typography variant='body1' >{serverPlanPrice}{state[0].SelectedPrice.Name === 'Annual' ? '/year' : '/month'}</Typography>
																<Typography variant='body1' sx={{opacity: '.7'}}>Server plan price</Typography>
															</ListItemText>
														</ListItem>
													</Grid>
												</Grid>
											</List>
										</Grid>
									)}
									{ state[0].SelectedPlan !== null && (
										<Grid xs={2} lg={8} my={3} >
											<Divider />
										</Grid>
									)}
									<Grid xs={2} lg={8} textAlign={'left'} >
										<img src={ZEPowerUpsWordmark} alt='Zero Eyes Power-ups Wordmark' className='power-ups-wm' style={{height: '1.5rem', width: 'auto', marginBottom: '1rem'}} ></img>
									</Grid>
									<Grid xs={2} lg={8} >
										<List disablePadding >
											<Grid container columns={{ xs: 2, lg: 8 }} spacing={{xs: 2, lg: 3}} >
												<Grid xs={2} lg={8} xl={4}>
													<ListItem disablePadding >
														<ListItemIcon>
															<AddBoxTwoToneIcon />
														</ListItemIcon>
														<ListItemText disableTypography={true}>
															<Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}} >
																<Typography variant='body1' color={'secondary'} >
																	{ state[0].StoragePowerUp !== null ? (
																		`${state[0].StoragePowerUp.ExtraStorageGiB} GiB`
																	) : (
																		"Not activated"
																	)}
																</Typography>
																{ state[0].StoragePowerUp !== null && (
																	<Typography variant='caption' >
																		{addDiskSpacePUPrice}
																		{state[0].SelectedPrice.Name === 'Monthly' ? '/mo' : '/yr'}
																	</Typography>
																)}
															</Box>
															<Typography variant='body1' sx={{opacity: '.7'}}>Additional Disk Space</Typography>
														</ListItemText>
													</ListItem>
												</Grid>
												<Grid xs={2} lg={8} xl={4}>
													<ListItem disablePadding >
														<ListItemIcon>
															<FolderCopyTwoToneIcon />
														</ListItemIcon>
														<ListItemText disableTypography={true}>
															<Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}} >	
																<Typography variant='body1' color={'secondary'} >
																	{ state[0].BackupsPowerUp ? (
																		'Activated'
																	) : (
																		"Not activated"
																	)}
																</Typography>
																{ state[0].BackupsPowerUp && (
																	<Typography variant='caption' >
																		{backupPUPrice}
																		{state[0].SelectedPrice.Name === 'Monthly' ? '/mo' : '/yr'}
																	</Typography>
																)}
															</Box>
															<Typography variant='body1' sx={{opacity: '.7'}}>Server Backup</Typography>
														</ListItemText>
													</ListItem>
												</Grid>
												<Grid xs={2} lg={8} xl={4}>
													<ListItem disablePadding >
														<ListItemIcon>
															<SvgIcon sx={{mt: '-2px'}}>{torSVGIcon}</SvgIcon>
														</ListItemIcon>
														<ListItemText disableTypography={true}>
															<Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}} >
																<Typography variant='body1' color={'secondary'} >
																	{ state[0].TORPowerUp ? (
																		'Activated'
																	) : (
																		"Not activated"
																	)}
																</Typography>
																{ state[0].TORPowerUp && (
																	<Typography variant='caption' >
																		{torPUPrice}
																		{state[0].SelectedPrice.Name === 'Monthly' ? '/mo' : '/yr'}
																	</Typography>
																)}
															</Box>
															<Typography variant='body1' sx={{opacity: '.7'}}>Tor Hidden Service</Typography>
														</ListItemText>
													</ListItem>
												</Grid>
											</Grid>
										</List>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>

					{ (!isDesktop && state[2].Name === 'Professional') && (
						<ProLicenseCustomerInfo displayType='card' selectedProduct={state} xs={4} sm={6} smOffset={1} customerType={customerType} setCustomerType={setCustomerType} customerTypeErr={customerTypeErr} setCustomerTypeErr={setCustomerTypeErr} proName={proName} setProName={setProName} proEmail={proEmail} setProEmail={setProEmail} proPhone={proPhone} setProPhone={setProPhone} proCompany={proCompany} setProCompany={setProCompany} proAddress1={proAddress1} setProAddress1={setProAddress1} proAddress2={proAddress2} setProAddress2={setProAddress2} proWebsite={proWebsite} setProWebsite={setProWebsite} />
					)}
					
					{ !isDesktop && (
						<PaymentInformationCard auth={auth} isAuthenticated={isAuthenticated} selectedProduct={state} totalProductPrice={totalProductPrice} setAgreed={setAgreed} agreedToHouseRules={agreedToHouseRules} houseRulesList={houseRulesList} checked={checked} setChecked={setChecked} setRulesErr={setRulesErr} setTransactionErr={setTransactionErr} setTransErrMsg={setTransErrMsg} squareEnv={squareEnv} squareAppId={squareAppId} squareLocationId={squareLocationId} xs={4} sm={6} smOffset={1} apiUrl={apiUrl} handleMoneroTransaction={handleMoneroTransaction} moneroActive={moneroActive} expanded={paymentInfoExpand} setExpanded={setPaymentInfoExpand} customerType={customerType} proCustInfo={{Name: proName, Email: proEmail, Phone: proPhone, CompanyName: proCompany, AddressLine1: proAddress1, AddressLine2: proAddress2, Website: proWebsite}} setCustomerInfoErr={setCustomerInfoErr} />
					)}

					<MoneroTransactionDialog isMoneroDialogOpen={isMoneroDialogOpen} setIsMoneroDialogOpen={setIsMoneroDialogOpen} moneroAddress={moneroAddress} moneroPicoAmount={moneroPicoAmount} moneroQRCode={moneroQRCode} moneroQRCodeUrl={moneroQRCodeUrl} moneroTransProgress={moneroTransProgress} moneroFailStatus={moneroFailStatus} setMoneroFailStatus={setMoneroFailStatus} handleMoneroTransaction={handleMoneroTransaction} />
					
					<ToastSnackbar alertSeverity='error' alertTitle='Customer Info Is Required for Pro Licenses' openSnackbar={customerInfoErr} setOpenSnackbar={setCustomerInfoErr} snackbarDescription='Each field requires more than one character.' />

					<ToastSnackbar alertSeverity='error' alertTitle='Monero payments are currently unavailable.' openSnackbar={isMoneroOff} setOpenSnackbar={setIsMoneroOff} />
					
					<ToastSnackbar alertSeverity='success' alertTitle='House Rules Agreed To' openSnackbar={agreed} setOpenSnackbar={setAgreed} snackbarButton={false} />
					<ToastSnackbar alertSeverity='error' alertTitle='Checkout Failed' openSnackbar={transactionErr} setOpenSnackbar={setTransactionErr} snackbarDescription={transErrMsg} />
				</Grid>
			</Box>
		</ThemeProvider>
	)
}

export default Checkout