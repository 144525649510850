import React from 'react'
import { Box, Card, CardContent, Typography, Button, List, ListItem, ListItemIcon, ListItemText, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';

const LicenseCards = ({  licenses, isLicenseActive, paymentFreqSelection, deviceAmtSelection, handleLicenseCardClick, page, creatorCode, setCreatorCode, creatorCodeErr, handleCreatorCodeCheck, validCreatorCode, state }) => {

    const provideLicenseText = (license) => {
        switch (license) {
            case 'Community':
                return ['What\'s Included in Community:', 'free download', 'Download Community now', 'Community selected'];
            case 'Professional':
                return ['Everything in Community, plus:', `yearly payment for ${deviceAmtSelection} devices`, 'Select Professional', 'Professional selected'];
            case 'Enterprise':
                return ['Everything in Professional, plus:', 'custom pricing', 'Contact us'];
            default:
                break;
        }
    }

    const provideDeviceIndex = amount => {
        switch (amount) {
            case '10':
                return 0;
            case '25':
                return 1;
            case '50':
                return 2;
            case '100':
                return 3;
            case '500':
                return 4;
            default:
                break;
        }
    }

  return (
    <Grid container columns={{xs: 4, sm: 8, lg: 12}} spacing={{xs: 2, lg: 3}} justifyContent={page === 'licenses' && {sm: 'center'}} >
            { licenses.map((license, index) => (   
                license.Name === 'Creator' ? (
                    <Grid xs={4} lg={4} key={license.id.id.String} order={licenses.length} >
                        <Card className={isLicenseActive[index] ? 'plan-card active' : 'plan-card'} onClick={() => handleCreatorCodeCheck(license, index)} >
                        <CardContent sx={{p: '32px 32px 16px 32px'}} >
                            <Grid container columns={{ xs: 2, lg: 8 }} rowSpacing={2} >
                                <Grid xs={2} lg={8} textAlign='left' sx={{height: {sm: '176px'}}} >
                                    <Typography variant='h4' sx={{mb: '.5rem'}} >{license.Name}</Typography>
                                    <Typography variant='subtitle1' >{license.Description}</Typography>
                                </Grid>
                                <Grid xs={2} lg={8}  >
                                    <List disablePadding >
                                        <Grid container columns={{ xs: 2, lg: 8 }} columnSpacing={{xs: 2, lg: 3}} rowSpacing={1} sx={{minHeight: {xs: '96px', md: '378px'}, display: 'flex', flexDirection: 'column', justifyContent: 'start'}} >
                                            <Grid xs={2} lg={8} >
                                                <ListItem disablePadding >
                                                    <ListItemText disableTypography={true}>
                                                        <Typography variant='subtitle1' >Everything in Professional, plus:</Typography>
                                                    </ListItemText>
                                                </ListItem>
                                            </Grid>
                                            <Grid xs={2} lg={8} >
                                                <ListItem disablePadding >
                                                    <ListItemIcon sx={{minWidth: '32px'}} >
                                                        <CheckCircleTwoToneIcon fontSize='small' color='secondary' />
                                                    </ListItemIcon>
                                                    <ListItemText disableTypography={true}>
                                                        <Typography variant='body1' >Unlimited concurrent devices</Typography>
                                                    </ListItemText>
                                                </ListItem>
                                            </Grid>
                                        </Grid>
                                    </List>
                                </Grid>
                                <Grid xs={2} lg={8} sx={{textAlign: 'left', minHeight: {md: '50px'}}} >
                                    <Typography variant='body1' >Enter your Polynom creator code in order to activate this license for your server plan.</Typography>
                                </Grid>
                                <Grid xs={2} lg={8} sx={{minHeight: {md: '100px'}, display: 'flex', flexDirection: 'column', justifyContent: 'start'}} >
                                    <TextField variant='filled' label='Enter creator code' value={creatorCode} onChange={(e) => setCreatorCode(e.target.value)} onKeyUp={(e) => e.key === 'Enter' && handleCreatorCodeCheck(license, index)} color='secondary' type='text' error={creatorCodeErr} helperText={creatorCodeErr && 'Invalid creator code'} sx={{ width: '100%', }} />
                                </Grid>
                                <Grid xs={2} lg={8}>
                                    <Button variant='outlined' color={isLicenseActive[index] ? 'secondary' : 'primary'} fullWidth={true} sx={{ borderWidth: '2px', maxWidth: 'none' }}>{isLicenseActive[index] ? 'Creator Selected' : 'Select Creator'}</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                        </Card>
                    </Grid>
                ) : (
                    <Grid xs={4} lg={4} key={license.id.id.String} >
                        <Card className={isLicenseActive[index] ? 'plan-card active' : 'plan-card'} onClick={() => handleLicenseCardClick(license, index)} >
                        <CardContent sx={{p: '32px 32px 16px 32px'}} >
                            <Grid container columns={{ xs: 2, lg: 8 }} rowSpacing={2} >
                                <Grid xs={2} lg={8} textAlign='left' sx={{height: {sm: '176px'}}} >
                                    <Typography variant='h4' sx={{mb: '.5rem'}} >{license.Name}</Typography>
                                    <Typography variant='subtitle1' >{license.Description}</Typography>
                                </Grid>
                                <Grid xs={2} lg={8}  >
                                    <List disablePadding >
                                        <Grid container columns={{ xs: 2, lg: 8 }} columnSpacing={{xs: 2, lg: 3}} rowSpacing={1} >
                                            <Grid xs={2} lg={8} >
                                                <ListItem disablePadding >
                                                    <ListItemText disableTypography={true}>
                                                        <Typography variant='subtitle1' >{provideLicenseText(license.Name)[0]}</Typography>
                                                    </ListItemText>
                                                </ListItem>
                                            </Grid>
                                            { license.Features.map((feature, index) => (
                                                <Grid xs={2} lg={8} key={index} >
                                                    <ListItem disablePadding >
                                                        <ListItemIcon sx={{minWidth: '32px'}} >
                                                            <CheckCircleTwoToneIcon fontSize='small' color='secondary' />
                                                        </ListItemIcon>
                                                        <ListItemText disableTypography={true}>
                                                            <Typography variant='body1' >{feature}</Typography>
                                                        </ListItemText>
                                                    </ListItem>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </List>
                                </Grid>
                                <Grid xs={2} lg={8}>
                                    { paymentFreqSelection === 'monthly' ? (
                                        <Grid xs={2} lg={8} textAlign={'left'} >
                                            <Box sx={{ fontFamily: "'sweet-square-pro', 'Chakra Petch', sans-serif", fontSize: '3rem', color: '#FAE696', display: 'inline-block', lineHeight: '48px', mb: '.25rem'}}>
                                                { license.Name === 'Community' ? (
                                                    (license.LicenseLevels[0].PriceOptions[0].Price / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})
                                                ) : (
                                                    (license.LicenseLevels[provideDeviceIndex(deviceAmtSelection)].PriceOptions[0].Price / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})
                                                )}
                                            </Box>
                                            <Typography variant='subtitle1' >
                                                {provideLicenseText(license.Name)[1]}
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <Grid xs={2} lg={8} textAlign={'left'} >
                                            <Box sx={{ fontFamily: "'sweet-square-pro', 'Chakra Petch', sans-serif", fontSize: '3rem', color: '#FAE696', display: 'block', lineHeight: '48px', mb: '.25rem'}}>
                                            { license.Name === 'Community' ? (
                                                    (license.LicenseLevels[0].PriceOptions[0].Price / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})
                                                ) : (
                                                    (license.LicenseLevels[provideDeviceIndex(deviceAmtSelection)].PriceOptions[1].Price / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})
                                                )}
                                            </Box>
                                            <Typography variant='subtitle1' >
                                                {provideLicenseText(license.Name)[1]}
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid xs={2} lg={8}>
                                    <Button variant='outlined' color={isLicenseActive[index] ? 'secondary' : 'primary'} fullWidth={true} onClick={() => license.Name === 'Community' && window.open('https://polynom.app/#download-polynom', '_blank', 'noreferrer')} sx={{ borderWidth: '2px', maxWidth: 'none' }}>{provideLicenseText(license.Name)[isLicenseActive[index] ? 3 : 2]}</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                        </Card>
                    </Grid>
                )
            ))}
    </Grid>
  )
}

export default LicenseCards;