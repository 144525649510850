import React from 'react'
import { Box, Divider, Stack, Typography, Button, SvgIcon } from "@mui/material";
import ZEVertWM from '../assets/zero-eyes-vertical-wordmark.png';
import { Link } from 'react-router-dom';

const Footer = () => {

  return (
    <Box sx={{ minHeight: '26vh', borderTop: '1px solid #FAE696' }}>
          <Stack flexDirection={{xs: 'column', lg: 'row'}} justifyContent={'space-between'} alignItems={'center'} sx={{ p: '0px 8.25%' }}>
            <Button component={Link} to={'/products/server-plans'} variant='text' sx={{color: 'white', padding: '6px 12px', width: '12%', display:{xs: 'none', lg: 'flex'} }}>Server plans</Button>
            <Button component={Link} to={'/house-rules'} variant='text' sx={{color: 'white', padding: '6px 12px', width: '12%', display:{xs: 'none', lg: 'flex'} }}>House rules</Button>
            <Stack direction={'column'} justifyContent={'space-between'} alignItems={'center'} spacing={2} sx={{mb: '8px'}} >
              <Box sx={{ height: '15vh', padding: 0, mt: '32px' }}>
                <img src={ZEVertWM} alt={'Zero Eyes logo hero'} style={{ height: '100%', display: 'block', margin: 'auto' }} />
              </Box>
              <Button href='https://twitter.com/ZeroEyesHosting' target='_blank' rel="noreferrer" startIcon={
                <SvgIcon>
                  <svg width="1200" height="1227" viewBox="0 0 1200 1227" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="white"/>
                  </svg>
                </SvgIcon>
              } variant='text' sx={{color: 'white', mt: '12px', padding: '6px 12px' }}>Follow us on X</Button>
            </Stack>
            <Button component={Link} to={'/about-us'} variant='text' sx={{color: 'white', padding: '6px 12px', width: '12%', display:{xs: 'none', lg: 'flex'} }}>About us</Button>
            <Button component={Link} to={'/contact-us'} variant='text' sx={{color: 'white', padding: '6px 12px', width: '12%', display:{xs: 'none', lg: 'flex'} }}>Contact us</Button>
          </Stack>
                
    </Box>
  )
}

export default Footer