import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { Box, CssBaseline, ThemeProvider, LinearProgress } from '@mui/material';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { themeOptions } from './Components/CustomThemeOptions';

import { Navbar, Home, Plans, About, Rules, Login, Signup, ContactUs, Checkout, ScrollToTop, Dashboard, PrivateRoute, Licenses, ToastSnackbar } from './Components';

const App = () => {

	let theme = createTheme(themeOptions);
	theme = responsiveFontSizes(theme);

	const [isLoading, setIsLoading] = useState(true);

	/* US CENT TO DOLLAR CONVERSION: (CENTS / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}) */

	const [authenticated, setAuthenticated] = useState(false);
	const [serverPlans, setServerPlans] = useState([]);
	const [plansErr, setPlansErr] = useState(false);
	const [plansErrMsg, setPlansErrMsg] = useState('');
	const [isPlanActive, setIsPlanActive] = useState({});
	const [lowPrice, setLowPrice] = useState('');
	const [licenses, setLicenses] = useState([]);
	const [licenseErr, setLicenseErr] = useState(false);
	const [licenseErrMsg, setLicenseErrMsg] = useState('');
	const [isLicenseActive, setIsLicenseActive] = useState({});
	const [subscriptions, setSubscriptions] = useState({});
	const [subsErr, setSubsErr] = useState(false);
	const [subsErrMsg, setSubsErrMsg] = useState('');
	const [cardInfo, setCardInfo] = useState(null);
	const [cardErr, setCardErr] = useState(false);
	const [cardErrMsg, setCardErrMsg] = useState('');
	const [houseRulesList, setHouseRulesList] = useState([]);
	const [rulesErr, setRulesErr] = useState(false);
	const [rulesErrMsg, setRulesErrMsg] = useState('');
	const [checked, setChecked] = useState({});
	const [agreedToHouseRules, setAgreedToHouseRules] = useState(false);

	const getAuthStatus = async () => {
		return fetch(`${apiUrl}/Auth/WhoAmI`, {
			method: 'GET',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' }
		}).then(data => data.json())
	}

	const isAuthenticated = async () => {
		const authStatus = await getAuthStatus();
		if (authStatus.Status === 'Winning') {
			setAuthenticated(true);
			localStorage.setItem('username', authStatus.UserInfo.UserName)
			localStorage.setItem('email', authStatus.UserInfo.Email)
			localStorage.setItem('polyID', authStatus.UserInfo.Ident)
			setAgreedToHouseRules(authStatus.UserInfo.AgreedToHouseRules);
		}
	}

	const getServerPlans = async () => {
		return fetch(`${apiUrl}/Plans/ListPlans`, {
			method: 'GET',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' }
		}).then(data => data.json())
	}

	const receivePlans = async () => {
		const plans = await getServerPlans();
		if (plans.Status === 'Winning') {
			setServerPlans(plans.PlanList);
			setLowPrice((plans.PlanList[0].PriceOptions[0].Price / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}));
			const tempSelected = {};
			plans.PlanList.map((plan, index) => {
				tempSelected[index] = false;
			})
			setIsPlanActive(tempSelected);
		} else {
			setServerPlans('unavailable');
			setPlansErr(true);
			setPlansErrMsg(plans.Message);
		}
	}

	const getLicenses = async () => {
		return fetch(`${apiUrl}/Licenses/ListLicenses`, {
			method: 'GET',
			credentials: 'include',
			headers: {'Content-Type': 'application/json'}
		}).then(data => data.json())
	}

	const receiveLicenses = async () => {
		const licenses = await getLicenses();
		if (licenses.Status === 'Winning') {
			setLicenses(licenses.LicenseList);
			const tempSelected = {};
			licenses.LicenseList.map((license, index) => {
				tempSelected[index] = false;
			})
			/* tempSelected[licenses.LicenseList.length] = false; */
			setIsLicenseActive(tempSelected);
		} else {
			setLicenses('unavailable');
			setLicenseErr(true);
			setLicenseErrMsg(licenses.Message);
		}
	}

	const logoutUser = async () => {
		await fetch(`${apiUrl}/Auth/Logout`, {
			method: 'POST',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' }
		})
		setAuthenticated(false);
		localStorage.clear();
	}

	const getSubscriptions = async () => {
		return fetch(`${apiUrl}/Subscriptions/ListUsersSubscriptions`, {
			method: 'GET',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' }
		}).then(data => data.json())
	}

	const provideSubscriptions = async () => {
		const subs = await getSubscriptions();
		if (subs.Status === 'Winning') {
			setSubscriptions(subs.SubscriptionList);
		} else {
			setSubscriptions('unavailable');
			setSubsErr(true);
			setSubsErrMsg(subs.Message);
		}
	}

	const getCardInfo = async () => {
		return fetch(`${apiUrl}/Capitalism/GetSquareCardInfo`, {
			method: 'GET',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' }
		}).then(data => data.json())
	}

	const provideCardInfo = async () => {
		const card = await getCardInfo();
		if (card.Status === 'Winning') {
			setCardInfo(card["Square Card Info"]);
		} else if (card.Status === 'Fail' && card.Message !== 'No user logged in.') {
			setCardInfo('failed');
			setCardErr(true);
			setCardErrMsg(card.Message);
		}
	}

	const getHouseRules = async () => {
		return fetch(`${apiUrl}/Capitalism/ListHouseRules`, {
			method: 'GET',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' }
		}).then(data => data.json())
	}

	const storeHouseRules = async () => {
		const rules = await getHouseRules();
		const tempRules = [];
		const tempChecked = {};
		if (rules.Status === 'Winning') {
			rules.HouseRuleList.map((rule, index) => {
				tempRules.push(rule.Rule);
				tempChecked[index] = false;
			})
			tempChecked[rules.HouseRuleList.length] = false;
			setHouseRulesList(tempRules);
			setChecked(tempChecked);
		} else {
			setHouseRulesList('failed');
			setRulesErr(true);
			setRulesErrMsg(rules.Message);
		}
	}

	/* ENVIRONMENT VARIABLE HANDLING */

	const [squareEnv, setSquareEnv] = useState('');
	const [squareAppId, setSquareAppId] = useState(null);
	const [squareLocationId, setSquareLocationId] = useState(null);
	const [apiUrl, setApiUrl] = useState(null);

	const setEnvCondVar = () => {
		if (window.location.origin === 'https://zeroeyes.host') {
			setApiUrl('https://zeroeyes.host/API');
			setSquareEnv('prod');
			setSquareAppId('sq0idp-XjHw41e8wcSaFCI-OPCG3g');
			setSquareLocationId('LX4F6BG54SSWB');
		} else if (window.location.origin === 'http://zeroeyesio66vvbyyso2btk5pdkkguudisibitu4liqpaiaeadzzgqqd.onion') {
			setApiUrl('http://zeroeyesio66vvbyyso2btk5pdkkguudisibitu4liqpaiaeadzzgqqd.onion/API');
			setSquareEnv('prod');
			setSquareAppId('sq0idp-XjHw41e8wcSaFCI-OPCG3g');
			setSquareLocationId('LX4F6BG54SSWB');
		} else if (window.location.origin === 'http://zerodevagz5q4gslsg33b7bx5vqx2r3d4z7nnn3p3c3gc7kkoniyeaid.onion') {
			setApiUrl('http://zerodevagz5q4gslsg33b7bx5vqx2r3d4z7nnn3p3c3gc7kkoniyeaid.onion:6969/API');
			setSquareEnv('dev');
			setSquareAppId('sandbox-sq0idb-_AU-B8lAg2iTTUzlqe7BXg');
			setSquareLocationId('LSDXDNJ7XFM2G');
		} else {
			setApiUrl('http://localhost:6969/API');
			setSquareEnv('dev');
			setSquareAppId('sandbox-sq0idb-_AU-B8lAg2iTTUzlqe7BXg');
			setSquareLocationId('LSDXDNJ7XFM2G');
		}
	}

	const getSiteInfo = () => {
		setEnvCondVar();
		if (apiUrl !== null) {
			receiveLicenses();
			receivePlans();
			isAuthenticated();
			storeHouseRules();
			if (authenticated) {
				provideSubscriptions();
				provideCardInfo();
			}
			setIsLoading(false);
		}
	}

	useEffect(() => {
		getSiteInfo();
	}, [apiUrl, authenticated]);


	return (
		<BrowserRouter>
			<ScrollToTop />
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Box sx={{ minHeight: '100vh', maxWidth: '1920px', m: 'auto' }}>
					<Navbar auth={authenticated} getAuth={isAuthenticated} provideCardInfo={provideCardInfo} card={cardInfo} setCardErr={setCardErr} logoutUser={logoutUser} houseRulesList={houseRulesList} setRulesErr={setRulesErr} checked={checked} setChecked={setChecked} agreedToHouseRules={agreedToHouseRules} squareEnv={squareEnv} squareAppId={squareAppId} squareLocationId={squareLocationId} apiUrl={apiUrl} />
					{ isLoading ? (
						<LinearProgress />
					) : (
					<Routes>
						<Route path="/" exact element={<Home lowPrice={lowPrice} auth={authenticated} />} />
						<Route path="/products/server-plans" element={<Plans plans={serverPlans} isPlanActive={isPlanActive} setIsPlanActive={setIsPlanActive} licenses={licenses} isLicenseActive={isLicenseActive} setIsLicenseActive={setIsLicenseActive} card={cardInfo} auth={authenticated} />} />
						{/* <Route path="/products/licenses" element={<Licenses licenses={licenses} isLicenseActive={isLicenseActive} setIsLicenseActive={setIsLicenseActive} card={cardInfo} auth={authenticated} />} /> */}
						<Route path="/about-us" element={<About />} />
						<Route path="/house-rules" element={<Rules houseRulesList={houseRulesList} />} />
						<Route path="/login" element={<PrivateRoute auth={!authenticated} path={'dashboard'} ><Login  apiUrl={apiUrl} /></PrivateRoute>} />
						<Route path="/signup" element={<PrivateRoute auth={!authenticated} path={'dashboard'} ><Signup apiUrl={apiUrl} /></PrivateRoute>} />
						<Route path="/contact-us" element={<ContactUs auth={authenticated} getAuth={isAuthenticated} apiUrl={apiUrl} />} />
						<Route path="/checkout" element={<Checkout auth={authenticated} isAuthenticated={isAuthenticated} logoutUser={logoutUser} houseRulesList={houseRulesList} setRulesErr={setRulesErr} checked={checked} setChecked={setChecked} agreedToHouseRules={agreedToHouseRules} squareEnv={squareEnv} squareAppId={squareAppId} squareLocationId={squareLocationId} apiUrl={apiUrl} />} />
						<Route path="/dashboard" element={<PrivateRoute auth={authenticated} path={'login'}><Dashboard subs={subscriptions} getSubs={provideSubscriptions} card={cardInfo} setCardErr={setCardErr} apiUrl={apiUrl} provideSubscriptions={provideSubscriptions} provideCardInfo={provideCardInfo} /></PrivateRoute>} />
					</Routes>
					)
					}
					<ToastSnackbar alertSeverity='error' alertTitle='Failed To Retrieve Payment Card Informaton' openSnackbar={cardErr} setOpenSnackbar={setCardErr} snackbarDescription={cardErrMsg} />
					<ToastSnackbar alertSeverity='error' alertTitle='Failed To Retrieve House Rules' openSnackbar={rulesErr} setOpenSnackbar={setRulesErr} snackbarDescription={rulesErrMsg} />
					<ToastSnackbar alertSeverity='error' alertTitle='Failed To Retrieve Server Plans' openSnackbar={plansErr} setOpenSnackbar={setPlansErr} snackbarDescription={plansErrMsg} />
					<ToastSnackbar alertSeverity='error' alertTitle='Failed To Retrieve Polynom Licenses' openSnackbar={licenseErr} setOpenSnackbar={setLicenseErr} snackbarDescription={licenseErrMsg} />
					<ToastSnackbar alertSeverity='error' alertTitle='Failed To Retrieve Subscriptions' openSnackbar={subsErr} setOpenSnackbar={setSubsErr} snackbarDescription={subsErrMsg} />
				</Box>
			</ThemeProvider>
		</BrowserRouter>
	);
};

export default App;