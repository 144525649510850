import React from 'react'
import { Box, Stack, Typography, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ZEBulletPoint from '../assets/ZEBulletPoint.png';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';

const Rules = ({ houseRulesList }) => {

	const borderImageValue = {xs: 'linear-gradient(150deg, rgba(162,212,250,.6) 0%, rgba(18,18,18,1) 30%, rgba(18,18,18,1) 70%, rgba(162,212,250,.6) 100%)',
	lg: 'linear-gradient(156deg, rgba(162,212,250,.6) 0%, rgba(18,18,18,1) 30%, rgba(18,18,18,1) 70%, rgba(162,212,250,.6) 100%)'}

	return (
		<Box sx={{ flexGrow: 1, padding: { xs: '0px 16px', md: '0px 8.25%' }, width: '100%' }} >
			<Grid container columns={{ xs: 4, sm: 8, lg: 12 }} display={'flex'} alignContent={'flex-start'} sx={{ minHeight: { xs: 'calc(100vh - 56px)', md: 'calc(100vh - 64px)', lg: 'calc(100vh - 112px)' }, marginLeft: '0' }}  >
				<Grid xs={4} sm={6} lg={6} smOffset={1} lgOffset={3} textAlign={'center'}>
					<Typography variant='h1' color={'secondary'} margin={{ xs: '1.5rem 0', sm: '1.5rem 0 2rem 0', lg: '2rem 0 3rem 0' }} >HOUSE RULES</Typography>
				</Grid>
				{
					houseRulesList !== 'failed' ? (
						<Grid container columns={{ xs: 4, sm: 8, lg: 12 }} sx={{  marginLeft: '0' }} >
							{ houseRulesList.map((rule, index) => (
								<Grid xs={4} smOffset={2} lgOffset={4} key={index}  >
									<Box sx={{ position: 'relative', textAlign: 'center', p: '5%', border: '2px solid transparent', borderImage: borderImageValue, borderImageSlice: '1 !important', mb: '8px' }} >
										<Typography variant='body1' >{rule}</Typography>
									</Box>
									{houseRulesList.indexOf(rule) !== houseRulesList.length - 1 && (
										<img src={ZEBulletPoint} alt='bullet point' style={{ transform: 'rotate(90deg)', margin: '0 auto', display: 'block', width: '4%' }} />
									)}
								</Grid>
							))}
							<Grid xs={4} smOffset={2} lgOffset={4} sx={{ my: '16px' }} >
								<Divider />
							</Grid>
							<Grid xs={4} smOffset={2} lgOffset={4} sx={{ mb: '32px', textAlign: 'center' }} >
								<Typography variant='body2' sx={{ opacity: '.7' }} >You may report violations of these rules to support@zeroeyes.host. We cannot see the contents of the servers hosted for our customers, therefore you will need to provide proof of the violation for us to take action.</Typography>
							</Grid>
						</Grid>
					) : (
						<Stack direction='column' gap={{xs: 2, lg: 3}} justifyContent={'center'} alignItems={'center'} sx={{mt: '8rem', mx: 'auto', textAlign: 'center'}} >
								<ErrorTwoToneIcon color='error' sx={{ fontSize: '10rem', opacity: '.28' }} />
								<Typography variant='h5' >Failed to retrieve the house rules. Check back soon.</Typography>
						</Stack>
					)
				}
			</Grid>
		</Box>
	)
}

export default Rules