import React from 'react'
import { Dialog, DialogContent, DialogTitle, Box, IconButton, Typography } from '@mui/material';

import ZEVertWM from '../assets/zero-eyes-vertical-wordmark.png';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

const PurchaseCompletedDialog = ({ isPurchaseCompleteOpen, setIsPurchaseCompleteOpen }) => {
  return (
    <Dialog className='purchase-completed-dialog' open={isPurchaseCompleteOpen} onClose={() => setIsPurchaseCompleteOpen(false)} fullWidth >
        <DialogTitle sx={{display: 'flex', justifyContent: 'end'}} >
            <IconButton onClick={() => setIsPurchaseCompleteOpen(false)} >
                <CloseTwoToneIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent sx={{textAlign: 'center'}} >
            <Box sx={{width: '100%', height: '20vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
                <img src={ZEVertWM} alt='Zero Eyes logo' style={{height: '100%'}} />
            </Box>
            <Typography variant='h4' sx={{mt: '1.5rem'}} >Congratulations!</Typography>
            <Typography variant='body1' sx={{mt: '.5rem'}} >Thank you for being a Zero Eyes customer during our beta release! We will create your server and have it ready for you within 24 hours. If you purchased a Polynom Professional license we will submit your information to Code Siren and notify you if there are any issues.</Typography>
        </DialogContent>
    </Dialog>
  )
}

export default PurchaseCompletedDialog;