import React, { useState } from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { SignupCard } from '../Components';
import { useNavigate } from 'react-router-dom';

const Signup = ({ auth, apiUrl}) => {

  const navigate = useNavigate();

  return (
    
      <Box sx={{ flexGrow: 1, padding: {xs: '0px 16px', md: '0px 8.25%'}, width: '100%' }}>
        {auth ? navigate('/') : (
          <Grid container columns={{ xs: 4, sm: 8, lg: 12 }} sx={{ minHeight: {xs: 'calc(100vh - 56px)', md: 'calc(100vh - 64px)', lg: 'calc(100vh - 112px)'}, height: '100%', marginLeft: '0' }} >
            <SignupCard xs={4} sm={6} lg={6} smOffset={1} lgOffset={3} hSize={'h1'} hMargin={['1', '3']} purpose={'link'} apiUrl={apiUrl} />
          </Grid>
        )}
      </Box>
  )


}

export default Signup